import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalExpandedContactsComponent } from '../modal-expanded-contacts/modal-expanded-contacts.component';
import { ModalConversationComponent } from '../modal-conversation/modal-conversation.component';
import { lookup } from 'dns';

@Component({
  selector: 'app-modal-card-contact',
  templateUrl: './modal-card-contact.component.html',
  styleUrls: ['./modal-card-contact.component.css']
})
export class ModalCardContactComponent implements OnInit {

  isEditing = false;

  // Objeto editable con todos los datos
  editableContact: any;

  modeContact = ''

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userData: UserDataService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.editableContact = { ...this.data.contact };
    console.log('Editable Contact:', this.editableContact);


    if (this.editableContact.hasOwnProperty('name') && this.editableContact.name != null) {
      this.modeContact = 'user';
    } else if (this.editableContact.hasOwnProperty('nombre_de_empresa') && this.editableContact.nombre_de_empresa != null) {
      this.modeContact = 'company';
    }

    console.log(this.modeContact)


  this.subsInfoDecode(this.editableContact.active_subscriptions)



if(this.modeContact == 'company'){
    let ultimaValoracion: any = this.editableContact.possibility.reduce((max: any, current: any) => {
      return current.id > max.id ? current : max;
  }, this.editableContact.possibility[0]); // Usa el primer elemento como valor inicial
  // Establece el valor del percentage más reciente
  this.value = ultimaValoracion.percentage;
  this.getCompanyProducts();
}



  }

allSubcats = []
  subsInfoDecode(stripe_subs) {

      this.userData.getAllSubcategories().subscribe((data: any) => {
        this.allSubcats = data;
        console.log(this.allSubcats)




    for(let sub of stripe_subs){
      let lookup_key = sub.items.data[0].price.lookup_key

      lookup_key = lookup_key.split('_')
      sub.type = lookup_key[0]

      switch(sub.type){
        case 'mng':
          sub.type = 'Management';
          break;
        case 'jin':
          sub.type = 'Job Interview';
          break;
        case 'pro':
          sub.type = 'Professional';
          break;
      }



      sub.plan_p = lookup_key[1]
      sub.subcat_id = lookup_key[2]

      sub.subcat = this.allSubcats.find(subcat => subcat.id === sub.subcat_id);





      console.log(sub)

    }



  })
  }

  toggleEditMode() {
    this.isEditing = !this.isEditing;

    if (!this.isEditing) {
      this.saveChanges();
    }
  }

  saveChanges() {
    // Crear un FormData con todos los valores editados
    const formData = new FormData();
    Object.entries(this.editableContact).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    console.log('FormData:', formData);
    // Aquí puedes enviar el FormData al backend si lo necesitas

    for (const [key, value] of (formData as any).entries()) {
      console.log(`${key}: ${value}`);
    }


    this.userData.updateContact(formData).subscribe(
      data => {
        console.log(data);
      }
    )


  }

  // deleteProduct(product){
  //   this.


  // }


  openModalPeople(){
    this.dialog.open(ModalExpandedContactsComponent, {
      width: '80%',
      data: {
        contact: this.editableContact
      }
    });
  }

  getMails(mail): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userData.getMails(mail).subscribe(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      );
    });
  }

  async openModalConversation(contact: any) {
    try {
      const mails = await this.getMails(contact.email);
      this.dialog.open(ModalConversationComponent, {
        width: '600px',
        height: '600px',
        data: {
          contacts: contact,
          mails: mails,
        },
      });

    } catch (error) {
      console.error('Error fetching mails:', error);
    }
  }

  subcategories = []
  products = [];
  getCompanyProducts(){
    this.userData.getCompanyProducts(this.editableContact.id).subscribe((data : any) => {
      this.products = data[0];
      this.subcategories = data[1]

      console.log(data)

      this.products.forEach(product => {
        let parts = product.product_name.split(" "); // Divide el string por espacio

        let subcategoryId = parseInt(parts[0]); // Extrae el primer elemento y lo convierte en número

        let subcategory = this.subcategories.find(sub => sub.id == subcategoryId);


        if (subcategory) {
          product.product_name = product.product_name.replace(subcategoryId, subcategory.name);
          product.subcategory = subcategory.name

        }


        let lookup_type = product.lookup_key.split('_')[0]
        product.plan = product.lookup_key.split('_')[1]


        if(lookup_type == 'pro'){
          product.type = 'Professional'
        }
        if(lookup_type == 'jin'){
          product.type = 'Job Interview'
        }
        if(lookup_type == 'mng'){
          product.type = 'Management'
        }
        console.log(product)
      })


    }
    )
  }

  formData = {
    subcategory: '',
    type: '',
    typeDate: '',
    numDate: 0,
    price: 0,
    productName: '',
    lookupKey: '',
    company_id: '',
  };

  saveData(){
    let lookup_type = ''
    if(this.formData.type == 'Professional'){
      lookup_type = 'pro'
    }
    if(this.formData.type == 'Job Interview'){
      lookup_type = 'jin'
    }
    if(this.formData.type == 'Management'){
      lookup_type = 'mng'
    }




    this.formData.productName = `${this.formData.subcategory} ${this.formData.type} ${this.formData.typeDate}`
    this.formData.lookupKey = `${lookup_type}_${this.formData.typeDate}_${this.formData.subcategory}`
    this.formData.company_id = this.editableContact.id



    this.userData.addProductsToCompany(this.formData).subscribe((data: any) => {
      console.log(data)
    }


  )
  }






  options: number[] = [90, 80, 70, 60, 50, 40, 30, 20, 10, 0]; // Opciones del 90% al 0%

  value = 0;
    updateValue(): void {
      console.log(`Valor seleccionado: ${this.value}%`);
      this.userData.createNewProbability(this.value, this.editableContact.id).subscribe(data => {
        this.data.contact.possibility = data;
       })

  }


}
