<div class="h-100 w-100 position-relative" (dblclick)="handleClose()">
  <div class="saveMessageArrayPos" (clickOutside)="removeMessage()" *ngIf="messageArray != undefined && messageArray.length != 0">
    <div *ngFor="let message of messageArray; let i = index">
        <div class="saveMessageArray d-flex" style="flex-direction: column;"  [innerHTML]="getFormattedText(message)">
        </div>
    </div>
</div>


<div *ngIf="charging" id="spinner-overlay" class="spinner-overlay">
  <div class="spinner"></div>
</div>

    <!-- <div class="position-relative py-2 px-3 row no-gutters d-flex" style="border: none !important; background-color: #F2F2F2;  height: 9%;" [appTooltip]="900"> -->
        <!-- <div class="d-flex" style="width: 93%;" [appTooltip]="901">
            <div *ngIf="stax['option_name'] != undefined" class="mx-2 my-auto  row no-gutters" style="width: auto; font-size: 1.5vh; height: 75%;">
                <i class="fa-solid fa-filter my-auto" style="cursor:pointer" [appTooltip]="915" (click)="getStaxContent()"></i>
            </div>
        </div> -->
        <!-- <div *ngIf="currentStaxType != undefined && currentStaxType == 'WL'" style="margin: 0 0.25%;" class="saveButton my-auto text-center" (click)="checkWordStax();">Check</div> -->
        <!-- <div class="saveButton my-auto text-center" (click)="setStaxLab();">
            <span [appTooltip]="920"> Save</span>
        </div> -->
    <!-- </div> -->

    <div style="width: 100%; margin: auto; height: 95%; color: #6e6e6e" class="d-flex">
        <div style="width: 27%; padding: 1.2%; font-size: 1.5vh;">
            <div style="height: 3%; margin: 3%; margin-bottom: 50px; flex-flow: wrap;" class="d-flex">
                <div *ngFor="let stax of stacks; let k = index" class="indexStax text-truncate" [ngClass]="{'indexStaxSelected': currentStaxType == stax.option_name}">
                    <span (click)="selectCurrentStax(stax)">
                        {{stax.name}}
                    </span>
                </div>
            </div>
                <div class="searchBox d-flex" (clickOutside)="dropDownSearch = false" style="height: 4%;">
                    <i  class="fa-solid fa-magnifying-glass my-auto"></i>
                    <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="subcatWord" (keyup)="searchSubcat($event.target.value)" (click)="dropDownSearch = true">
                    <div style="height: max-content; flex-direction: column" class="d-flex dropDownSearch" *ngIf="dropDownSearch == true"  [appTooltip]="924">
                        <div  *ngFor="let subcat of searchedSubcats; let i = index" style="cursor: pointer; color: grey;" class="dropDownOptions d-flex">
                            <input type="checkbox" (click)="setSearchedSubcats(subcat, $event, cat)" [checked]="subcat.checked">
                            <span style="padding: 0 2%;">
                                {{subcat.subcat_name}} || {{subcat.cat }}
                            </span>
                            <div *ngIf="currentStaxType != undefined" class="d-flex" style="margin-left: auto;">
                                <span> {{subcat.num_subcat}} </span>
                                -
                                <span> {{subcat.num_cat}} </span>
                                -
                                <span style="color:#959090"> {{subcat.num_publisher}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container px-0 pt-2" style="height: 93%; font-size:1.8vh; overflow-y: scroll;">
                    <div  *ngFor="let campus of campusTypes; let i = index"  class="w-100 d-flex" style="flex-direction: column;">
                    <div style=" margin: 0;" class="course d-flex w-100" [ngClass]="{'courseActive':  campusName == campus.campus_name, 'hasContent': campus.has_content}">
                      <input type="checkbox" (click)="selectAllCats(campus, $event)" [checked]="campus.checked" class="mr-1">
                      <a [ngClass]="{'subcategorySelected': subcategory != undefined && campus.campus_name == subcategory.campus_name}" (click)="getStacksLevel(campus)"> {{campus.campus_name}}</a>
                        <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="getCampusLevels(campus)"></i>
                    </div>
                    <div class="h-100 d-flex" style="width: 100%;" *ngIf="levels.length > 0 && campusName == campus.campus_name && showLevels">
                        <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 2%; padding-right: 0" *ngIf="campus.campus_id == 1">
                            <div *ngFor="let level of levels; let j = index"  class="d-flex" style="flex-direction: column;" >
                                <div style=" margin: 0;" class="course d-flex w-100" (click)="getStacksLevel(level)">
                                    <a [ngClass]="{'subcategorySelected': levelName == level.name}">{{ level.name }}</a>
                                    <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;"></i>
                                </div>
                                <div  class="h-100 d-flex w-100" style="width: 100%;" *ngIf="level.stacks.length > 0 && levelName == level.name && showStacks">
                                    <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 1%; padding-right: 0">
                                        <div *ngFor="let stack of level.stacks; let k = index"  class="d-flex" style="flex-direction: column; color: #6e6e6e; cursor: pointer;">
                                            <div class="w-100 d-flex" [ngClass]="{'hasContent': (stack.num_subcat > 0 || stack.num_cat > 0 || stack.num_publisher), 'subcategorySelected assignCategory': currentStaxType == stack.stax_type}"  (click)="selectStax(stack);">
                                                <div *ngIf="stack.stax_type != 'WL'">
                                                    {{stack.stax_type}}
                                                </div>
                                                <div *ngIf="stack.stax_type == 'WL'">
                                                    WordStax
                                                </div>
                                                <div class="d-flex" (click)="selectStax(stack, true)">
                                                    <span> {{stack.num_subcat}} </span>
                                                    -
                                                    <span> {{stack.num_cat}} </span>
                                                    -
                                                    <span style="color:#959090"> {{stack.num_publisher}} </span>
                                                </div>
                                            </div>
                                            <div style="margin-left: 3%" class="hasContent d-flex" *ngFor="let import of stack.imports; let l = index" (click)="selectStax(stack);">
                                                Import {{k+1}}
                                                <!-- {{import.import_date | date}} -->
                                                <span style="margin-left: auto;">
                                                    {{import.num_words}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 6%; padding-right: 0" *ngIf="campus.campus_id != 1">
                            <div *ngFor="let course of levels;let j = index"  class="w-100 " style="display: flex; flex-direction: column;"  >
                                <!-- <input type="checkbox" (click)="setSearchedSubcats(subcat, $event)" [checked]="subcat.checked"> -->
                                <div style="margin: 0;" class="course d-flex w-100">
                                  <input
                                    type="checkbox"
                                    (click)="setSearchedCats(course, $event)"
                                    [checked]="course.checked"
                                    [disabled]="isCheckboxDisabled(course)"
                                    class="mr-1"
                                  >
                                  <a
                                    [ngClass]="{'subcategorySelected': catName === course.cat_name, 'hasContent': course.has_content}"
                                    style="text-transform: uppercase;"
                                    (click)="selectCat(course)"
                                  >
                                    {{ course.cat_name }}
                                    <span *ngIf="countSubcatsChecked(course.subcats) > 0" style="background-color: #198cf0; color: white; padding: 3px; font-size: 1.5 !important;">
                                      {{ countSubcatsChecked(course.subcats) }}
                                    </span>
                                  </a>
                                  <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="getCatTreeCategories(course, course.subcats)"></i>
                                </div>
                                <div class="h-100 d-flex" style="width: 100%;  " *ngIf="subcategories.length > 0 && catName == course.cat_name && showCats">
                                    <div style="width: 100%; display: flex; flex-direction: column; padding: 1.5% 6%; padding-right: 0" >
                                        <div *ngFor="let subcat of course.subcats let k = index"  class="categories d-flex w-100"  style="flex-direction: column; ">
                                            <div style=" margin: 0;" class="course d-flex w-100" >
                                                <input type="checkbox" (click)="setSearchedSubcats(subcat, $event, course)" [disabled]="checkCatSelected(course.cat_name)" [checked]="subcat.checked" class="mr-1">
                                                <a [ngClass]="{'subcategorySelected': subcat.subcat_name == subcatName, 'hasContent': subcat.has_content}">{{ subcat.subcat_name }}</a>
                                                <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="selectSubcat(subcat)"></i>
                                            </div>
                                            <div  class="h-100 d-flex w-100" style="width: 100%;" *ngIf="subcat.stacks.length > 0 && subcatName == subcat.subcat_name && showStacks">
                                                <div style="width: 100%; display: flex; flex-direction: column; padding: 1.5% 6%; padding-right: 0">
                                                    <div *ngFor="let stack of subcat.stacks; let k = index"  class="d-flex" style="flex-direction: column; color: #6e6e6e; cursor: pointer;">
                                                        <div class="w-100 d-flex" [ngClass]="{'hasContent': (stack.num_subcat > 0 || stack.num_cat > 0 || stack.num_publisher), 'subcategorySelected assignCategory': currentStaxType == stack.stax_type}"  (click)="selectStax(stack);">
                                                            <div *ngIf="stack.stax_type != 'WL'">
                                                                {{stack.stax_type}}
                                                            </div>
                                                            <div *ngIf="stack.stax_type == 'WL'">
                                                                WordStax
                                                            </div>
                                                            <div class="d-flex" style="margin-left: auto;" (click)="selectStax(stack, true)">
                                                                <span> {{stack.num_subcat}} </span>
                                                                -
                                                                <span> {{stack.num_cat}} </span>
                                                                -
                                                                <span style="color:#959090"> {{stack.num_publisher}} </span>
                                                            </div>
                                                        </div>
                                                        <div style="margin-left: 3%" class="hasContent d-flex" *ngFor="let import of stack.imports; let l = index" (click)="selectStax(stack);">
                                                            Import {{k+1}}
                                                            <!-- {{import.import_date | date}} -->
                                                            <span style="margin-left: auto;">
                                                                {{import.num_words}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="h-100" style="padding: 1.5%; width: 73%;">
          <div class="position-relative py-2 px-3 row no-gutters" style="border: none !important; background-color: #A8143E;">
            <div class="px-1 d-flex w-100" style="color: white;" >
                <img style="height: 35px;" src="../../../assets/img/logo-white.png" alt="Geo Logo" (click)="viewText = !viewText" [appTooltip]="937">
                <div class="w-100 d-flex my-auto">
                    <span *ngIf="catName != undefined && checkedCats.length <= 1" style="margin: 0 1.5%;  text-transform: uppercase;" [appTooltip]="938"> {{checkedCats[0] ? checkedCats[0].subcat_name : ''}}  </span>
                    <span *ngIf="catImporter != undefined && checkedCats.length <= 1" style="margin: 0 1.5%; text-transform: uppercase;" [appTooltip]="938"> {{checkedCats[0] ? checkedCats[0].subcat_name : ''}}  </span>
                    <span *ngIf="subcategory != undefined && subcategory.campus_name == undefined && checkedSubcats.length <= 1" style="margin: 0 1.5%;" [appTooltip]="938"> {{subcategory.name}}  </span>
                    <span *ngIf="subcategory != undefined && subcategory.campus_name != undefined && checkedSubcats.length <= 1" style="margin: 0 1.5%;" [appTooltip]="938"> {{subcategory.campus_name}}  </span>
                    <span *ngIf="subcatName != undefined && checkedSubcats.length <= 1" style="margin: 0 1.5%;" [appTooltip]="938"> {{subcatName}}  </span>
                    <span *ngIf="currentStaxType  != undefined && currentStaxType != 'WL'"  style="margin: 0 1.5%;" [appTooltip]="939"> {{currentStaxType}} </span>
                    <span *ngIf="currentStaxType  != undefined && currentStaxType == 'WL'"  style="margin: 0 1.5%;" [appTooltip]="939"> WordStax </span>
                </div>

                <div style="margin-right: 12px; display: flex;">
                  <label>
                    trad
                    <input type="checkbox" [(ngModel)]="checkboxes.trad" (change)="onCheckbox('trad')">
                  </label>
                  <label>
                    def
                    <input type="checkbox" [(ngModel)]="checkboxes.def" (change)="onCheckbox('def')">
                  </label>

                  <label>
                    ex
                    <input type="checkbox" [(ngModel)]="checkboxes.ex" (change)="onCheckbox('ex')">
                  </label>

                  <label>
                    pos
                    <input type="checkbox" [(ngModel)]="checkboxes.pos" (change)="onCheckbox('pos')">
                  </label>
                </div>



                <div (dblclick)="handleClose()" class="tooltip-container" #tooltipContainer>
                  <i [ngStyle]="checkedCats.length > 1 || checkedSubcats.length > 1 ? {'color': 'green'} : {'color': 'white'}"
                     style="margin-top: 8px; margin-right: 30px; cursor: pointer;"
                     (click)="toggleTooltip()"
                     class="fa-solid fa-plus"></i>

                  <div class="multiple-tooltip" style="overflow-x: overlay;" [ngClass]="{'actived': showTooltip}" *ngIf="showTooltip">
                    <button *ngIf="currentStaxType" style="color: white;" class="staxToolTipButton">{{ currentStaxType }}</button>
                    <div class="content">
                      <div class="clusters">
                        <h1 *ngIf="ClusterSubcats.length > 0" style="color: white;">Clusters: </h1>
                        <ul>
                          <li style="padding-left: 30px;" *ngFor="let checkCluster of ClusterSubcats">
                            <a (click)="UncheckFromCluster(checkCluster)">
                              <i style="color: #A8143E; cursor: pointer;" class="fa-solid fa-xmark"></i>
                            </a>
                            {{ checkCluster.subcat_name }}
                          </li>
                        </ul>
                      </div>

                      <div class="cats">
                        <h1 style="color: white;" *ngIf="checkedCats.length > 0">Cat: </h1>
                        <ul>
                          <li style="text-transform: uppercase;" *ngFor="let checkCat of checkedCats">{{ checkCat.cat_name }}</li>
                        </ul>
                      </div>

                      <div class="subcats">
                        <h1 *ngIf="checkedSubcats.length > 0" style="color: white;">Subcat: </h1>
                        <ul>
                          <li style="text-transform: capitalize;" [ngClass]="{'disable': checkSubcat.cluster == true}" *ngFor="let checkSubcat of checkedSubcats">
                            ({{ checkSubcat.cat }}) - {{ checkSubcat.subcat_name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>

                <select id="keySelect" #keySelect style="margin-right: 3%;" (change)="onChangeKey($event)">
                  <option value="" disabled selected hidden>Key</option>
                  <option value=":">:</option>
                  <option value=",">,</option>
                  <option value="$$">$$</option>
                  <option value="&&">&&</option>
                  <option value="&&&">&&&</option>
                  <option value="$$$">$$$</option>
                </select>

                <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 1%;" [ngClass]="{'green': this.green}" (click)="explodeChunk();" [appTooltip]="945">
                  Line
                </div>
                <!-- <div *ngIf="checkedSubcats.length > 0" class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="cloneStax();">
                    Share
                </div> -->
                <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 1%;" (click)="checkStax();" [appTooltip]="945">
                    Check
                </div>
                <!-- <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="callHistorial();" [appTooltip]="945">
                  Historial
              </div> -->
                <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 1%;" (click)="checkTransStax();" [appTooltip]="945">
                  Dictionary
                </div>
                <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 1%;" (click)="cleanTextEditor();" [appTooltip]="945">
                    Clear
                </div>
                <div
                class="d-flex my-auto"
                style="padding: 0 1.2%; background-color: white; border-radius: 3px; color: #a8143e; cursor: pointer;"
                (click)="setStaxLab()"
                [ngClass]="{'disabled': isDisabled}"
                [attr.disabled]="isDisabled ? true : null">
                  <span [appTooltip]="920">{{  isDisabled == true ? "Guardando" : "Save" }}</span>
              </div>
            </div>
          </div>
          <ckeditor *ngIf="showEditor" #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)"  (change)="onChange($event);" [(ngModel)]="EditorText" [appTooltip]="947" ></ckeditor>
      </div>
    </div>
  </div>
