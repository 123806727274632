import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import Editor from "src/assets/js/ckeditor.js";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { AdminService } from 'src/app/services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalGapComponent } from '../modals/modal-gap/modal-gap.component';
declare const CKEDITOR




interface Stax {
  title: string;
  type: string;
  content: string;
  topic?: string;
  subtopic?: string;
}


@Component({
  selector: 'app-gapper-importer',
  templateUrl: './gapper-importer.component.html',
  styleUrls: ['./gapper-importer.component.css']
})
export class GapperImporterComponent {


      constructor(public admin : AdminService, public dialog: MatDialog) { }

    public Config = {
      removePlugins: ['Title'],
    };

    @ViewChild("myEditor") myEditor: any;
    public Editor = Editor;
    public EditorText: string = "Title: ";




    onReady(editor) {
      console.log(this.myEditor);
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement(),
        );
    }


    readingTypes = []
    selectedType = " "

    ngOnInit() {
      this.admin.getTypeReading().subscribe((data: any) => {

        for (let i = 0; i < data.length; i++) {
          if(data[i].type == "Description") {
            data[i].type = "Chart"
            this.readingTypes.push(data[i])

          }
          if(data[i].type == "article" || data[i].type == "Article") {
            data[i].type = "Article"
            this.readingTypes.push(data[i])
          }
          if(data[i].type == "Job Interview" || data[i].type == "job interview") {
            data[i].type = "Job Interview"
            this.readingTypes.push(data[i])
          }
        }


    })

    }

    selectedNgap


    openGapModal() {
      if(this.wordsToColor)
      this.dialog.open(ModalGapComponent, {
        width: '60%',
        height: '90%',
        data: {gap: this.wordsToColor, text: this.groupTexts}

      })
    }




    finalize = false;
    onChange({ editor }: ChangeEvent) {
      this.EditorText = editor.getData();
      // if (this.finalize == false ) {
      //   setTimeout(() => {
      //   this.checkReadingContent()
      //   }, 300);
      // }
    }
    wordsToColor
    audioList
    saveButton = false

    groupTexts

    checkText(){
      this.groupTexts = this.separateText(this.EditorText, this.selectedType);
      let serializedTexts = JSON.stringify(this.groupTexts);

      this.admin.gapByFreq(serializedTexts, this.selectedPlan, this.selectedType, this.selectedNgap, 1).subscribe((data: any) => {
        console.log("1",data);
        this.wordsToColor = data;

        if (this.wordsToColor) {
            this.admin.gapByFreq(serializedTexts, this.selectedPlan, this.selectedType, this.selectedNgap, 2).subscribe((data2: any) => {
                this.audioList = data2;
                console.log("2",data2);
                this.saveButton = true;
            });
        }
    });
    }

    checkMicroText(){
    }




    modeMicroGap = false
    changeMode(){
      this.modeMicroGap = !this.modeMicroGap
    }



    saveText() {
    let texts = this.separateText(this.EditorText, this.selectedType);
    let serializedTexts = JSON.stringify(texts);

    for (let i = 0; i < texts.length; i++) {
      this.admin.createGappedText(texts[i].title,
        texts[i].content, this.selectedType, this.wordsToColor[2][i], this.wordsToColor[2][i].length, this.selectedPlan, texts[i].topic, texts[i].subtopic, this.audioList[2][i]).subscribe((data: any) => {
        // this.splitText(this.EditorText, this.selectedNgap)
        this.saveButton = false

      console.log(data)
   })



    }






  }


  selectedPlan

  stripHTML(input) {
    return input.replace(/<[^>]*>/g, '').trim();
  }


    // Función para separar el texto en objetos
    separateText(text, type) {
      const staxList = [];
      let regex
      if(type =="Job Interview") {
        regex = /(?:topic:\s*(.*?)\s*)?(?:subtopic:\s*(.*?)\s*)?(?:QQQ:\s*(.*?)\s*)(ANSR):\s*([\s\S]*?)(?=subtopic:|topic:|title:|QQQ|$)/gs;
      }
      if(type == "Article") {
        regex = /(?:topic:\s*(.*?)\s*)?(?:subtopic:\s*(.*?)\s*)?(?:title:\s*(.*?)\s*)(article):\s*([\s\S]*?)(?=subtopic:|topic:|title:|article|$)/gs;
      }
      if(type == "Chart") {
        regex = /(?:topic:\s*(.*?)\s*)?(?:subtopic:\s*(.*?)\s*)?(?:title:\s*(.*?)\s*)(Description|Presentation Monologue):\s*([\s\S]*?)(?=subtopic:|topic:|title:|Description|Presentation Monologue|$)/gs;
      }
      if(type == "Expertise"){
        regex = /(?:topic:\s*(.*?)\s*)?(?:subtopic:\s*(.*?)\s*)?(?:title:\s*(.*?)\s*)(Expertise):\s*([\s\S]*?)(?=subtopic:|topic:|title:|Expertise|$)/gs;
      }
  let match;
  let currentTopic = null;
  let currentSubtopic = null;

  while ((match = regex.exec(text)) !== null) {
    console.log("match", match)
    const topic = match[1] ? this.stripHTML(match[1]) : currentTopic;
    const subtopic = match[2] ? this.stripHTML(match[2]) : currentSubtopic;
    const title = this.stripHTML(match[3]);
    const type = this.stripHTML(match[4]);
      let content = match[5]
      .trim()
      .replace(/<(?!br\s*\/?)[^>]+>/gi, '') // elimina todo excepto <br> y <br/>
      .replace(/&nbsp;/g, '');
      // Eliminar guiones adicionales
      content = content.replace(/---+/g, '');

      const stax: Stax = {
          title: title,
          type: type,
          content: content
      };

      if (topic) {
          stax.topic = topic;
          currentTopic = topic;
      }


      if (subtopic) {
          stax.subtopic = subtopic;
          currentSubtopic = subtopic;
      }


      staxList.push(stax);
  }
  console.log(staxList);
  return staxList;


    }





}
