import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { CryptoService } from 'src/app/services/crypto-js.service';
import { environment } from 'src/environments/environment';
import { UserDataService } from 'src/app/services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { WordsLabComponent } from '../modals/words-lab/words-lab.component';
import { NoClusterModalComponent } from '../modals/no-cluster-modal/no-cluster-modal.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-editorial-importer',
  templateUrl: './editorial-importer.component.html',
  styleUrls: ['./editorial-importer.component.css']
})
export class EditorialImporterComponent implements OnInit {

  @ViewChild('editSubCatName') subCatNameInput: ElementRef;

  coursesCatTree = new Array();
  categoriesCatTree = new Array();
  courseName = '';
  assignCourse;
  selectedMacro = "STAX"

  courses;
  subcategory;

  confirmRelation: boolean = false;
  showSubcats: boolean = false;

  successfullySave: boolean = false;
  message = '¡GUARDADO!';

  showCourseRelated;
  showSubCatsRelated;

  constructor(private cdRef: ChangeDetectorRef, private admin: AdminService, private userData: UserDataService, private cryptoService: CryptoService, private dialog: MatDialog) { }

  ngOnInit() {
    this.buildNewCatTree();
    this.userData.notifyObservable$.subscribe(res => {
      if(res.hasOwnProperty('option') && res.option == 'importer_target'){
        this.checkTarget(res.value)
      }
      else{
        this.setPusherData(res);

      }


    });




  }


  handleClick(event: MouseEvent){

    if(event.shiftKey){
      return true
    }
    if(event.ctrlKey){
      return false
    }
  }

  openModal(data, info){
    const dialogRef =  this.dialog.open(WordsLabComponent, {width: '80%', height: '80%', data: [data, info]})

    dialogRef.afterClosed().subscribe(result => {
      console.log("RESULT", result)
      this.setPusherData(result)
    });
  }



  targetedSubcats = [];
  targetedCat = [];
  checkTarget(value){
    this.targetedCat.push(value.subcat.subcat_cat_id)
    this.targetedSubcats.push(value.subcat.subcat_id)
  }



  getWordsBySubcat(subcat, type){
    console.log(subcat)
    this.admin.getWordsByLab(subcat.subcat_id, type, null).subscribe((data: any) => {
      this.openModal(data, {subcat: subcat, type: type})
    });
  }
  getWordsByHandImported(subcat, type){
    console.log(subcat)
    this.admin.getWordsByLab(subcat.subcat_id, type, 'subcat').subscribe((data: any) => {
      this.openModal(data, {subcat: subcat, type: type})
    });
  }
  getWordsByPlus(subcat, type){
    console.log(subcat)
    this.admin.getWordsByLab(subcat.subcat_id, type, 'plus').subscribe((data: any) => {
      this.openModal(data, {subcat: subcat, type: type})
    });
  }

  getWordsByRed(subcat, type) {
    this.admin.getWordsByRed(subcat.subcat_id, type).subscribe((data: any) => {
      console.log("PALABRAS ROJAS", data);
      this.palabrasRojas = data;

      // Llamar a copyWordsToClipboard solo después de que palabrasRojas se haya inicializado
      this.copyWordsToClipboard();
    });
  }

  palabrasRojas: string[] = [];

  copyToClipboard(text: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Texto copiado al portapapeles');
        resolve(true);
      }).catch((error) => {
        console.error('Error al copiar al portapapeles', error);
        reject(false);
      });
    });
  }
  copyWordsToClipboard() {
    const wordsText = this.palabrasRojas.join('\n'); // Unir las palabras en un solo texto separado por saltos de línea
    let wordsTextOrded = wordsText.split('\n').sort().join('\n');
    let n_words = wordsText.split('\n').length;
    const copied = this.copyToClipboard(wordsTextOrded);



    if (copied) {
      alert(`${n_words} palabras copiadas al portapapeles`);
    } else {
      alert('No se pudo copiar al portapapeles. Intenta nuevamente.');
    }
  }





  getWordsByCat(cat, type){
    console.log(cat, type)
    this.admin.getWordsByCat(cat.cat_id, type).subscribe((data: any) => {
      this.openModal(data, {subcat: cat, type: type})
    });
  }


  getWordsByPublisher(subcat, type){
    this.admin.getWordsPublisher(subcat.course_id, type).subscribe((data: any) => {
      this.openModal(data, {subcat: subcat, type: type})
    });
  }


  getWordsByGlobal(type){
    this.admin.getWordsGlobal(type).subscribe((data: any) => {
      this.openModal(data, {subcat: null ,type: type})
    });
  }


selectMode(mode){
if(mode == 'cluster'){
  this.clusterMode = true; this.coursesMode = false
}

if(mode == 'courses'){
  this.clusterMode = false; this.coursesMode = true
}

this.checkedSubcats = []
this.assignCourse = undefined;
this.confirmRelation = false;
}



clusters
  getClusters(){
    this.admin.getClusters().subscribe((data: any) => {
      this.clusters = data;
      console.log(this.clusters)
    });
  }



  dropSubcatOnCat(event: CdkDragDrop<any[]>) {
    const subcat = event.item.data; // Información de la subcategoría arrastrada
    const targetCourse = event.container.data; // Categoría de destino (a donde se ha soltado)
    const previousCourse = event.previousContainer.data; // Categoría de origen (de donde se ha arrastrado)

    console.log(`Subcategoría arrastrada:`, subcat);
    console.log(`Categoría destino:`, targetCourse);
    console.log(`Categoría origen:`, previousCourse);
  }

  dropLists: string[] = [];
  allTree: any
  buildNewCatTree() {
    this.admin.getNewCatTree().subscribe((data: any) => {
      data = JSON.parse(this.cryptoService.CryptoJSAesDecrypt(environment.jsonPassphrase, data));
      console.log("CallGetNewCatTree", data)
      this.coursesCatTree = data.tree;

      this.courses = data.courses;
      this.totalCourses = data.courses;
      this.courses.forEach(course => {
        course.subcatsCount = 0;
        course.subcats = [];
        for (let i = 0; i < this.coursesCatTree.length; i++) {
          for (let j = 0; j < this.coursesCatTree[i].subcats.length; j++) {
            if (this.coursesCatTree[i].subcats[j].course_id == course.course_id) {
              course.subcats.push(this.coursesCatTree[i].subcats[j]);
              course.subcatsCount += 1;
            }
          }
        }
      })
      console.log(this.courses)

    });


    this.coursesCatTree.forEach((_, index) => {
      this.dropLists.push(`course-list-${index}`);
    });
    this.getClusters();

  }

  getCatTreeCategories(event:MouseEvent, course, subcat) {
    event.stopPropagation();
    if (this.categoriesCatTree != subcat) {
      this.showSubcats = true;
    } else {
      this.showSubcats = !this.showSubcats;
    }
    console.log("subcat",subcat)
    this.categoriesCatTree = subcat;
    console.log(this.categoriesCatTree)
    this.courseName = course.cat_name;
    this.subcategory = undefined;
  }

moveToStax = false
  goToImport(resource, typeCatSub, subcat) {
    console.log("goToImpport",subcat)
    this.admin.getParamStax(resource, typeCatSub, subcat)
    this.moveToStax = true
  }


  selectCourse(course) {
    for(let subcat of this.checkedSubcats){
      subcat.checked = false;
    }
    this.checkedSubcats = []
    this.assignCourse = course;
    for(let subcat of course.subcats){
      console.log(subcat)
      subcat.checked = true;
      this.checkedSubcats.push(subcat);
    }
    if(this.checkedSubcats.length > 0){
      this.confirmRelation = true;
    }

    if (this.subcategory != undefined) {
      this.confirmRelation = true;
    }
  }


  assignCluster
  selectCluster(cluster){
    for(let subcat of this.checkedSubcats){
      subcat.checked = false;
    }
    this.checkedSubcats = []
    this.assignCluster = cluster;

    for(let subcat of cluster.subcategories){
      console.log(subcat)
      subcat.checked = true;
      this.checkedSubcats.push(subcat);
    }
    if(this.checkedSubcats.length > 0){
      this.confirmRelation = true;
    }

    if (this.subcategory != undefined) {
      this.confirmRelation = true;
    }


    console.log(this.checkedSubcats)

  }




  learningPathInfo;
  selectSubcat(subcat) {
    this.subcategory = subcat;
    if (this.assignCourse != undefined) {
      this.confirmRelation = true;
    }
    this.learningPathInfo = subcat.info;
  }


  gridStyle(column) {
    return {
      'grid-column-start': column + 1,
      'grid-column-end': column + 2,
    }
  }

  isTargetedCat(cat_id){
    if(this.targetedCat.includes(cat_id)){
      return true
    }
    else{
      return false
    }
  }

  isTargetedSubcat(subcat_id){
    if(this.targetedSubcats.includes(subcat_id)){
      return true
    }
    else{
      return false
    }
  }




coincidencia: string = '';
  compareSubcats(value) {
    if(value.length >= 3){
    for (let subcat of this.categoriesCatTree){
      if(subcat.subcat_name.toLowerCase().includes(value.toLowerCase())){
      subcat.subcat_name.indexOf
      subcat.coincidido = true;

      }
      else{
        subcat.coincidido = false;
      }
  }
}
else{
  for (let subcat of this.categoriesCatTree){
    subcat.coincidido = false;
  }
}
  }
  newCatName = '';
  newSubcatName = '';
  newCatSubcat(type, value, cat?) {
    if (type == 'cat') {
      this.admin.createCategory(value).subscribe((data: any) => {
        this.message = data[0];
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
        if (data[1] != null) {
          let newCat = data[1];
          newCat.subcats = new Array();
          this.coursesCatTree.push(newCat);
        }
        this.newCatName = '';
      });
    } else if (type == 'subcat') {
      this.admin.createSubcategory(value, cat.cat_id).subscribe((data: any) => {
        this.message = data[0];
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
        if (data[1] != null) {
          cat.subcats.push(data[1])
        }
        this.newSubcatName = '';
      });
    }
    else if (type == 'cluster') {
      this.admin.createCluster(value).subscribe((data: any) => {
        this.message = data[0];
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
        if (data[1] != null) {
          this.getClusters();
        }
        this.newSubcatName = '';
      });
    }

  }

  top6Subcats: any
  top3Clusters: any
  nearestToLive(){

    const subcatsWithStax = [];

    // Recorrer el árbol de categorías y subcategorías
    console.log("nearest",this.coursesCatTree)
    this.coursesCatTree.forEach(cat => {
      cat.subcats.forEach(subcat => {
        const wordStax = subcat.info.resources.find(item => item.name === 'Word Stax');
        const termStax = subcat.info.resources.find(item => item.name === 'Term Stax');

        // Verificar que ambas métricas existan y que estén debajo de 1000
        if (wordStax && termStax) {
          const wordStaxSum = wordStax.num_subcat + wordStax.num_cat;
          const termStaxSum = termStax.num_subcat + termStax.num_cat;

          if (wordStaxSum < 900 || termStaxSum < 900) {
            // Guardar la subcategoría con la suma de Word Stax y Term Stax en el arreglo



            let clusterInfo = null;
            this.clusters.forEach(cluster => {
              cluster.subcategories.forEach(subcatCluster => {
              if (subcatCluster.subcat_id == subcat.subcat_id) {
                clusterInfo = cluster;
              }
              });
            });

            subcatsWithStax.push({
              cat,
              subcat,
              cluster: clusterInfo,
              wordStaxSum,
              termStaxSum,
              activationProximity: 1800 - (wordStaxSum + termStaxSum) // Entre más bajo, más cercano a activarse
            });





          }
        }
      });
    });

    // Ordenar las subcategorías según la proximidad a activarse
    subcatsWithStax.sort((a, b) => a.activationProximity - b.activationProximity);

    // Seleccionar las 5 subcategorías más cercanas a activarse
    this.top6Subcats = subcatsWithStax.slice(0, 6);

    // Imprimir los resultados


    this.top6Subcats.forEach(subcat => {
      if(subcat.cluster == null){
        subcat.cluster = {cluster_id: 0, cluster_name: 'Sin cluster', subcategories: []}
      }

    });


    // this.top6Subcats.forEach(({cat, cluster, subcat, wordStaxSum, termStaxSum }) => {
    //   console.log('Cat:', cat.cat_name, 'Cluster:', cluster.cluster_name ,`Subcat: ${subcat.subcat_name}`, `Word Stax Sum: ${wordStaxSum}, Term Stax Sum: ${termStaxSum}`, 'Proximidad de activación:', 2000 - (wordStaxSum + termStaxSum));
    // });

    const clusterCount = this.top6Subcats.reduce((acc, subcat) => {
      const clusterName = subcat.cluster.cluster_name;
      if(clusterName == undefined || clusterName == null){
        return acc;
      }

      if (!acc[clusterName]) {
      acc[clusterName] = 0;
      }
      acc[clusterName]++;
      return acc;
    }, {});

    const sortedClusters = Object.keys(clusterCount).sort((a, b) => clusterCount[b] - clusterCount[a]);
    console.log(sortedClusters);

    this.top3Clusters = sortedClusters.slice(0, 3).map(clusterName => ({
      cluster_name: clusterName,
      count: clusterCount[clusterName]
    }));

    console.log(this.top3Clusters);





    console.log(this.top6Subcats)
  }

  isHoveredTooltipC = false;
  isHoveredTooltip = false;

  isSubcatTop6(subcat){
    let is = false
    this.top6Subcats.forEach(subcatTop => {
      if(subcatTop.subcat.subcat_id == subcat.subcat_id){
        is = true
      }
    })

    return is

  }

  isCatTop6(cat){
    let is = false
    this.top6Subcats.forEach(subcatTop => {

      if(subcatTop.cat.cat_id == cat.cat_id){
        is = true
      }
    })

    return is

  }

  quizzesReorder(){



    this.coursesCatTree.forEach(cat => {
      cat.subcats.forEach(subcat => {
        // console.log(subcat.info.skills)
        let skills = Object.values(subcat.info.skills);
        console.log(skills)
        subcat.info.skillStax = [
          {name: 'TERM' ,cicles : 0, type: 'QUIZ'},
          {name: 'GRAM' ,cicles : 0, type: 'QUIZ'},
          {name: 'IDIOM' ,cicles : 0, type: 'QUIZ'},
          {name: 'JOB' ,cicles : 0, type: 'QUIZ'},

        ]
        Object.values(subcat.info.skills).forEach(skill => {
          const skillData = skill as { name: string; num_subcat: number; num_cat: number };


          if(skillData.num_subcat == undefined || skillData.num_cat == undefined || skillData.num_subcat == null || skillData.num_cat == null){
            skillData.num_subcat = 0;
            skillData.num_cat = 0;
          }

          switch (skillData.name) {
            case 'Tech Quiz CT':
            // case 'Tech Quiz CD':
            // case 'Audio Quiz CT':
            // case 'Audio Quiz CD':
            subcat.info.skillStax[0].cicles = skillData.num_subcat + skillData.num_cat
            break
            case 'Gram Quiz CT':
            subcat.info.skillStax[1].cicles = skillData.num_subcat + skillData.num_cat;
            break
            case 'Idiom Quiz CT':
            subcat.info.skillStax[2].cicles = skillData.num_subcat + skillData.num_cat;
            break
            case 'Job Quiz CT':
            subcat.info.skillStax[3].cicles = skillData.num_subcat + skillData.num_cat;
            break

          }
        })
    })
  })


    console.log(this.coursesCatTree)

  }


  orderMacro() {
    for (let subcat of this.macroCat.subcats) {
      Object.values(subcat.info.skills).forEach(element => {
        const elementData = element as { name: string; type: any; from_resource: any };
        if (['Wise Reader', 'Paper Pilot', 'Audio Gap TESLA'].includes(elementData.name)) {

          elementData.type = 'WR';
            } else {
              elementData.from_resource = true;
              elementData.type = 'QUIZ';
            }
        })
        for (let element of subcat.info.resources) {
            element.type = 'STAX';
        }
    }

}



  selectedCatMacro
  openMacroPanel: boolean = false;
  macroCat: any;
  openCatMacro(course: any) {
    this.macroCat = course;

    console.log(this.macroCat)
    this.nearestToLive();
    this.quizzesReorder()
    this.selectedCatMacro = course.cat_id;
    console.log("c",course);
    console.log("this",this.selectedCatMacro)
    this.orderMacro();
    // this.cleanSort();
    this.openMacroPanel = true;
}



getSkills(skills, type) {
    return skills.filter(skill => skill.type == type);
}

getSkillsName(skills, type) {
  const filteredSkills = skills.filter(skill => skill.type === type);
  return filteredSkills;
}


selectMacro(macro) {
    this.selectedMacro = macro;
}
  closeCatMacro() {
    this.openMacroPanel = false;
    this.macroCat = undefined;
  }

  sortMacro(element: any) {
    let area: string = '';
    let index: number;

    if (this.macroCat.desc === undefined) this.macroCat.desc = true;
    if (this.macroCat.sortArea) {
      if (this.macroCat.sortArea == element.name) {
        this.macroCat.desc = !this.macroCat.desc;
      } else {
        this.macroCat.desc = true;
        this.macroCat.sortArea = element.name;
      }
    } else this.macroCat.sortArea = element.name;

    this.macroCat.subcats[0].info.resources.forEach((res, ind) => {
      if (res.name == element.name) {
        area = 'resources';
        index = ind;
      }
    });
    this.macroCat.subcats[0].info.skills.forEach((skill, ind) => {
      if (skill.name == element.name) {
        area = 'skills';
        index = ind;
      }
    });

    this.macroCat.subcats.sort((a, b) => {
      if (a.info[area][index]['num_subcat'] < b.info[area][index]['num_subcat']) {
        return this.macroCat.desc ? 1 : -1;
      } else if (a.info[area][index]['num_subcat'] > b.info[area][index]['num_subcat']) {
        return this.macroCat.desc ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  calcColumnGrid(type, skills, k){
    let total = 0;

    skills.forEach(skill => {
      if (skill.type == type) {
        total++;
      }
    });

    return total + k + 2;
  }

  oldData = {}
  setPusherData(data) {
    this.targetedCat = [];
    this.targetedSubcats = [];

    console.log("setPusher Data",data)
    data = data.value;

    if (typeof data['stack'] === 'string') {
      try {
          data['stack'] = JSON.parse(data['stack']);
      } catch (e) {
          console.warn("Stack is not valid JSON, using as is:", e);
      }
  }

    if (data.id == 0) {
      this.coursesCatTree.forEach(cat => {
        cat.subcats.forEach(subcat => {
          if (data['stack'] != undefined) {
            subcat.info['resources'].forEach(resource => {
              console.log(resource)
              if (resource.type == data['stack'].stax_type) {
                if (data['stack'].num_global != undefined) resource.num_global = data['stack'].num_global;
              }
            });
            subcat.websocketCoord = `${subcat.subcat_id}-resource-${data['stack'].stax_type}`;
          }
        });
      });

    } else {
      let area: string;
      this.coursesCatTree.forEach(cat => {
        cat.subcats.forEach(subcat => {
          if (data.id == subcat.subcat_id) {
            if (data['stack'] != undefined) {
              subcat.info['resources'].forEach(resource => {
                if (this.changeName(resource.name) == data['stack'].stax_type) {
                  this.oldData = JSON.parse(JSON.stringify(resource));
                  let diff = Number(resource.num_subcat) - Number(resource.origin);
                  resource.origin = data['stack'].num_origin;
                  resource.num_subcat = Number(data['stack'].num_subcat) + diff;
                  resource.num_cat = data['stack'].num_cat;
                  resource.num_publisher = data['stack'].num_publisher;
                  resource.lowTrans = data['stack'].num_lowTrans;
                  resource.plus_origin = data['stack'].num_plus;
                  area = 'resource';
                  subcat.websocketCoord = `${subcat.subcat_id}-${area}-${data['stack'].stax_type}`;
                }
                subcat.info['skills'].forEach(skill => {
                  if (skill.type == data['stack'].stax_type) {
                    skill.num_subcat = data['stack'].num_subcat;
                    skill.num_cat = data['stack'].num_cat;
                    skill.num_publisher = data['stack'].num_publisher;
                    skill.lowTrans = data['stack'].num_lowTrans;
                    skill.plus_origin = data['stack'].num_plus;
                    area = 'skill';
                    subcat.websocketCoord = `${subcat.subcat_id}-${area}-${data['stack'].stax_type}`;
                  }
                });
              });
            }
          }
          else if (data.id == cat.cat_id) {
            if (data['stack'] != undefined) {
              for(let subcat of cat.subcats){
              subcat.info['resources'].forEach(resource => {
                if (this.changeName(resource.name) == data['stack'].stax_type) {
                  this.oldData = JSON.parse(JSON.stringify(resource));
                  let diff = Number(resource.num_subcat) - Number(resource.origin);
                  resource.origin = resource.origin;
                  resource.num_subcat = resource.num_subcat;
                  resource.num_cat = data['stack'].num_cat;
                  resource.num_publisher = resource.num_publisher;
                  resource.lowTrans = data['stack'].num_lowTrans;
                  resource.plus_origin = data['stack'].num_plus;
                  area = 'resource';
                  cat.websocketCoord = `${data.id}-${area}-${data['stack'].stax_type}`;
                }
                subcat.info['skills'].forEach(skill => {
                  if (skill.type == data['stack'].stax_type) {
                    skill.num_subcat = data['stack'].num_subcat;
                    skill.num_cat = data['stack'].num_cat;
                    skill.num_publisher = data['stack'].num_publisher;
                    skill.lowTrans = data['stack'].num_lowTrans;
                    skill.plus_origin = data['stack'].num_plus;
                    area = 'skill';
                    cat.websocketCoord = `${data.id}-${area}-${data['stack'].stax_type}`;
                  }
                });
              });
            }
            }
          }
        });
      });
    }
  }


  changeName(name) {
if(name == 'Word Stax'){
  return 'WL'
}
if(name == 'Term Stax'){
  return 'TERM'
}
if(name == 'Gram Stax'){
  return 'GRAM'
}
if(name == 'Idiom Stax'){
  return 'IDIOM'
}
if(name == 'Academic Stax'){
  return 'ACADEMIC'
}
if(name == 'Job Stax'){
  return 'JOB'
}



else{
  return name
}


  }

  // cleanSort() {
  //   this.macroCat.subcats.sort((a, b) => {
  //     // If both items have a course_id or both do not, sort alphabetically by name
  //     if ((a.course_id && b.course_id) || (!a.course_id && !b.course_id)) {
  //       return a.subcat_name.localeCompare(b.subcat_name);
  //     }

  //     // If only a has a course_id, a should come first (so return -1)
  //     if (a.course_id && !b.course_id) {
  //       return -1;
  //     }

  //     // If only b has a course_id, b should come first (so return 1)
  //     if (!a.course_id && b.course_id) {
  //       return 1;
  //     }
  //   });
  // }

  highlightModification(subcat_id: string, area: string, name: string): boolean {
    if (!this.macroCat) return false;
    var coordinates = String(this.macroCat.subcats.find(subcat => subcat.subcat_id == subcat_id).websocketCoord).split('-');
    if (coordinates[0] == subcat_id && coordinates[1] == area && coordinates[2] == name) {
      return true;
    }

    return false;
  }

  coursesMode = true;
  clusterMode = false;



  isLive = true;
  isBeta = false;
  isArchived = false;
  handleStateClick(type){


    if(type == 'Live'){
      this.isLive = !this.isLive;
    }
    if(type == 'Beta'){
      this.isBeta = !this.isBeta;
    }
    if(type == 'Archived'){
      this.isArchived = !this.isArchived;
    }

    console.log(this.isLive, this.isBeta, this.isArchived)
  }



  applyFilters(state){

    if(state == 'Live' && this.isLive == true){
      return true
    }
    if(state == 'Beta' && this.isBeta == true){
      return true
    }
    if(state == 'Archived' && this.isArchived == true){
      return true
    }
    else{
      return false
    }
  }


  editSubcat: boolean = false;
  editCatSubcat(type, course) {
      this.updateCat = course;
    if (type == 'cat') {
      if(this.editCat == true){
        this.editCat = false;
      }
      else{this.editCat = true;}
    } else if (type == 'subcat') {
      if(this.editSubcat == true){
        this.editSubcat = false;
      }
      else{
      this.editSubcat = true;
      this.cdRef.detectChanges();
      this.subCatNameInput.nativeElement.focus();
      }
    }


  }

  editCat: boolean = false;
  updateCat;
  updateCatSubcat(type, new_value) {
    if (type == 'cat') {
      this.admin.udpateCategory(this.updateCat.cat_id, new_value).subscribe((data: any) => {
        this.editCat = false;
        this.updateCat.cat_name = new_value;
        this.updateCat = undefined;
        this.message = data;
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    } else if (type == 'subcat') {
      this.admin.updateSubcategory(this.updateCat.subcat_id, new_value).subscribe((data: any) => {
        this.editSubcat = false;
        this.updateCat.subcat_name = new_value;
        this.updateCat = undefined;
        this.message = data;
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    }
  }


  deleteCats: boolean = false;
  deleteCatSubcat(type, course, index) {
    if (type == 'cat') {
      this.admin.deleteCategory(course.cat_id).subscribe((data: any) => {
        this.message = data;
        this.coursesCatTree.splice(index, 1)
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    } else if (type == 'subcat') {
      this.admin.deleteSubcategory(course.subcat_id).subscribe((data: any) => {
        this.message = data;
        this.categoriesCatTree.splice(index, 1)
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    }

  }

  changeOrder: boolean = false;
  // drop(event: CdkDragDrop<string[]> ) {
  //   if (this.changeOrder == true){
  //     moveItemInArray(this.categoriesCatTree, event.previousIndex, event.currentIndex);
  //   }
  // }

  searchedValue
  sync = true;


  searchingSubcat: boolean = false;
  dropDownSearch: boolean = false;
  searchedSubcats = new Array();
  searchSubcat(search_value) {

    this.searchingSubcat = true;
    search_value = search_value.toLowerCase();
    let coursesAux = new Array();
    if (search_value.length > 2) {
      this.searchedSubcats = []
      this.coursesCatTree.forEach(cat => {
        cat.subcats.forEach(subcat => {
          if (subcat.subcat_name != undefined) {

            if (subcat.subcat_name.toLowerCase().indexOf(search_value) != -1) {
              this.searchedSubcats.push(subcat);
              this.dropDownSearch = true;
            }
          }
        })
      });
    }
  }

ordedBy = ''
  orderCourses(orderBy){
    if(orderBy == 'high'){
      this.courses.sort((a, b) => b.subcatsCount - a.subcatsCount);
      this.ordedBy = 'high'
    }
    else if(orderBy == 'low'){
      this.courses.sort((a, b) => a.subcatsCount - b.subcatsCount);
      this.ordedBy = 'low'
    }
  }



  searchGroup(search_value) {
    if(this.sync == true){
      this.searchSubcat(search_value)
    }
    this.searchingStudent = true;
    search_value = search_value.toLowerCase();
    let coursesAux = new Array();

    if (search_value.length > 2) {
      coursesAux = []
      this.courses.forEach(course => {
        if (course.course_name != undefined) {
          if (course.course_name.toLowerCase().indexOf(search_value) != -1) {
            coursesAux.push(course);
          }
        }
      });
      this.courses = coursesAux;
    } else {
      this.courses = this.totalCourses;
      if (search_value == '') {
        this.searchingStudent = false;
      }
    }
  }
  checkedSubcats = new Array();
  setSearchedSubcats(subcat, event) {
    if (event.target.checked == true) {
      subcat.checked = true;
      this.checkedSubcats.push(subcat);
      this.confirmRelation = true;
    } else {
      let index = this.checkedSubcats.indexOf(subcat);
      subcat.checked = false;
      this.checkedSubcats.splice(index, 1);
    }
  }

  searchingStudent = false;
  totalCourses = new Array()

  messageArray = new Array();


 disableSubcat(subcat) {
  console.log(subcat)
    if (!window.confirm('¿Seguro que quieres deshabilitar la subcat?')) {
      return;
    }

  this.admin.disableSubcategory(subcat.subcat_id).subscribe((data: any) => {
    console.log(data)
  });



}





  assignCourseToSubcategory() {

    console.log("SUBCATEGORYRYRYRYRYRYRY",this.subcategory)
    if (this.subcategory.course_id != null) {
      if (!window.confirm('¿Seguro que quieres sobreescribir el contenido?')) {
        return;
      }
    }



    if (this.checkedSubcats.length == 0) {
      this.admin.assignCourseToSubcategory(this.assignCourse.course_id, this.subcategory.subcat_id).subscribe((data: any) => {
        this.message = data['message'];
        if (data['updated']) {
          this.subcategory.course_id = this.assignCourse.course_id;
          this.subcategory.course_name = this.assignCourse.course_name;
          this.assignCourse.subcatsCount++;
          this.subcategory = undefined;
        }
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    } else {
      for (let i = 0; i < this.checkedSubcats.length; i++) {
        let subcat_id = this.checkedSubcats[i].subcat_id;

        this.admin.assignCourseToSubcategory(this.assignCourse.course_id, subcat_id).subscribe((data: any) => {
          this.messageArray.push(this.message = this.checkedSubcats[i].subcat_name + ':' + data);
          this.checkedSubcats[i].course_id = this.assignCourse.course_id;
          this.checkedSubcats[i].course_name = this.assignCourse.course_name;
          this.assignCourse.subcatsCount++;
          this.successfullySave = true;
          setTimeout(() => {
            this.successfullySave = false;
          }, 4500);
        });
      }
    }
  }

  assignClusterToSubcategory(){
      if (!window.confirm('¿Seguro que quieres sobreescribir el contenido?')) {
        return;
      }

    this.admin.assignClusterToSubcategory(this.assignCluster.cluster_id, this.checkedSubcats).subscribe((data: any) => {
      console.log(data)
      this.message = data['message'];
      this.getClusters();
      this.checkedSubcats = []
      this.assignCluster = undefined;
      this.confirmRelation = false;
    });






  }


  openNoClusterModal(){
    this.dialog.open(NoClusterModalComponent, {
      width: '80%',
      height: '80%',
      data: this.coursesCatTree
    }
  )


  }




}
