<div class="chat-container" style="position: absolute; transition-duration: 600ms;
transition-timing-function: ease-in-out; width: 85%; padding: 1%;"
 [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">

  <!-- Panel de contactos -->
  <div class="contacts-panel" [ngClass]="{'mobile-hidden': isMobileView && isChatOpen}">
    <div class="contacts-header">
      Contacts
    </div>

    <div class="contact" *ngFor="let chat of allChats"
         (click)="selectChat(chat)"
         [class.selected]="chat === selectedChat"
         [class.has-new-message]="chat.hasNewMessage">
      <div [ngClass]="{'sc-type': chat.type === 'SC', 'st-type': chat.type === 'ST', 'sp-type': chat.type === 'SP'}"
           style="width: 12px; height: 100%; margin-right: 12px;">
      </div>
      <span style="width: 40%;">{{chat.name}} {{chat.last_name}}</span>
      <div style="width: 10%;">
      <div style="background-color: cyan; height: 12px; width: 12px; border-radius: 50%;" *ngIf="chat.hasNewMessage"></div>
      <div style="background-color: limegreen; height: 12px; width: 12px; border-radius: 50%;" *ngIf="chat.answered == false"></div>
      <div style="background-color: red; height: 12px; width: 12px; border-radius: 50%;" *ngIf="chat.answered == true"></div>
      </div>
      <span style='font-size: 0.6rem; color: grey;'>{{chat.created_at | date: 'EEE HH:mm'}}</span>
      <span *ngIf="chat.hasNewMessage" class="new-message-indicator">•</span>
      <select name="tipoAssist" id="tipoAssist" [(ngModel)]="chat.type"
              (change)="changeTipoAssist(chat.id, chat.type)">
        <option value="SC">SC</option>
        <option value="ST">ST</option>
        <option value="SP">SP</option>
      </select>
    </div>
  </div>

  <!-- Panel de chat -->
  <div class="chat-panel" [ngClass]="{'mobile-hidden': isMobileView && !isChatOpen}">
    <div class="chat-header" *ngIf="chatSelected.id != 0">
      <!-- Botón para volver en móviles -->
      <span *ngIf="isMobileView" class="back-button" (click)="goBackToContacts()">⬅</span>
      <span>{{chatSelected.name}} {{chatSelected.last_name}}</span>
      <span (click)="closeTicket(chatSelected.id)"
            style="background-color: red; color: white; padding:0 6px; border-radius: 6px; cursor: pointer;">
        CERRAR TICKET
      </span>
    </div>

    <div #chatMessagesContainer class="chat-messages" *ngIf="chatSelected.id != 0">
      <div class="message" [ngClass]="{'sent': msg.role == 18 || msg.role == 9, 'received': msg.role != 18 && msg.role != 9}"
           *ngFor="let msg of chatMessages">
        <strong>{{ msg.user_name }}</strong>
        <p>{{ msg.message }}</p>
        <small>{{ msg.created_at | date: 'short' }}</small>
      </div>
    </div>

    <div class="chat-input" *ngIf="chatSelected.id != 0">
      <input type="text" [(ngModel)]="newMessage" placeholder="Type a message...">
      <button (click)="sendMessage(newMessage)">Send</button>
    </div>
  </div>

</div>
