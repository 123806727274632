import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Routes
import { APP_ROUTING } from './app.routes';

// Components
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ClickOutsideModule } from 'ng-click-outside';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

// Services
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/guard.service';


// QuillEditor
import { EditorComponent } from './components/editor/editor.component';
import { ListCreatorComponent } from './components/list-creator/list-creator.component';
import { LabCreatorComponent } from './components/lab-creator/lab-creator.component';
import { FlyerEditorComponent } from './components/flyer-editor/flyer-editor.component';
import { LoginformComponent } from './components/loginform/loginform.component';
import { RegformComponent } from './components/regform/regform.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './auth-interceptor';
import { ImgcropperComponent } from './components/imgcropper/imgcropper.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { GroupsComponentComponent } from './components/groups-component/groups-component.component';
import { AppGlobalErrorhandler } from './app.global.errorhandler';
// import { ServerErrorInterceptor } from './http-interceptor';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
// import {MatSliderModule} from '@angular/material/slider';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SafeDefPipe } from 'src/app/pipes/safe-def.pipe';
import { DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CrewAdminComponent } from './components/crew-admin/crew-admin.component';
import { StudentsAdminComponent } from './components/students-admin/students-admin.component';
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';
import { GroupAdminComponent } from './components/menu-admin/group-admin/group-admin.component';
import { NewGroupComponent } from './components/menu-admin/new-group/new-group.component';
import { RoleAdminComponent } from './components/menu-admin/role-admin/role-admin.component';
import { PlannerComponent } from './components/planner/planner.component';
import { DayPilotModule } from "@daypilot/daypilot-lite-angular";
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { PlacementReportComponent } from './components/placement-report/placement-report.component';
import { PlacementResultsComponent } from './components/placement-results/placement-results.component';
import { TestConfigComponent } from './components/menu-admin/test-config/test-config.component';
import { ImporterComponent } from './components/menu-admin/importer/importer.component';
import { NewOrganizationComponent } from './components/menu-admin/new-organization/new-organization.component';
import { CandidateInfoComponent } from './components/menu-admin/candidate-info/candidate-info.component';
import { PhraseImporterComponent } from './components/menu-admin/importer/phrase-importer/phrase-importer.component';
import { GrammarImporterComponent } from './components/menu-admin/grammar-importer/grammar-importer.component';
import { QuizzesImporterComponent } from './components/menu-admin/importer/quizzes-importer/quizzes-importer.component';
import { StaxImporterComponent } from './components/menu-admin/importer/stax-importer/stax-importer.component';
import { GoalsComponent } from './components/menu-admin/goals/goals.component';
import { FileUploaderComponent } from './components/menu-admin/file-uploader/file-uploader.component';
import { DndDirective } from './directives/dnd.directive';
import { SurveysComponent } from './components/menu-admin/surveys/surveys.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ImagesImporterComponent } from './components/menu-admin/importer/images-importer/images-importer.component';
import { DictionaryImporterComponent } from './components/menu-admin/importer/dictionary-importer/dictionary-importer.component';
import { EditorialImporterComponent } from './components/menu-admin/importer/editorial-importer/editorial-importer.component';
import { WisereaderImporterComponent } from './components/menu-admin/importer/wisereader-importer/wisereader-importer.component';
import { ActivityProfileComponent } from './components/menu-admin/importer/activity-profile/activity-profile.component';
import { FilterStaxPipe } from './pipes/filter-stax.pipe';
import { ReportedIssuesComponent } from './components/menu-admin/importer/reported-issues/reported-issues.component';
import { AudioLabComponent } from './components/menu-admin/importer/audio-lab/audio-lab.component';
import { GapperImporterComponent } from './components/menu-admin/importer/gapper-importer/gapper-importer.component';
import { ModalGapComponent } from './components/menu-admin/importer/modals/modal-gap/modal-gap.component';
import { WordsLabComponent } from './components/menu-admin/importer/modals/words-lab/words-lab.component';
import { NoClusterModalComponent } from './components/menu-admin/importer/modals/no-cluster-modal/no-cluster-modal.component';
import { StaxTransModalComponent } from './components/menu-admin/importer/modals/stax-trans-modal/stax-trans-modal.component';
import { AssistantComponent } from './components/menu-admin/assistant/assistant.component';
import { ContactsComponent } from './components/menu-admin/contacts/contacts.component';
import { ModalImportDataComponent } from './components/menu-admin/contacts/modal-import-data/modal-import-data.component';
import { ModalSendEmailComponent } from './components/menu-admin/contacts/modal-send-email/modal-send-email.component';
import { NgxEditorModule } from 'ngx-editor';
import { ModalConversationComponent } from './components/menu-admin/contacts/modal-conversation/modal-conversation.component';
import { ModalAddContactComponent } from './components/menu-admin/contacts/modal-add-contact/modal-add-contact.component';
import { ModalCardContactComponent } from './components/menu-admin/contacts/modal-card-contact/modal-card-contact.component';
import { ModalExpandedContactsComponent } from './components/menu-admin/contacts/modal-expanded-contacts/modal-expanded-contacts.component';

import { EmailEditorModule } from 'angular-email-editor';


@NgModule({
  declarations: [
    GapperImporterComponent,
    AppComponent,
    LoginComponent,
    LandingComponent,
    NavbarComponent,
    // ListeditorComponent,
    // LabeditorComponent,
    EditorComponent,
    ListCreatorComponent,
    LabCreatorComponent,
    // CardComponent,
    // DecimalPipePipe,
    SafeDefPipe,
    FlyerEditorComponent,
    LoginformComponent,
    RegformComponent,
    ImgcropperComponent,
    ImageEditorComponent,
    GroupsComponentComponent,
    CrewAdminComponent,
    StudentsAdminComponent,
    MenuAdminComponent,
    GroupAdminComponent,
    NewGroupComponent,
    RoleAdminComponent,
    PlannerComponent,
    TooltipComponent,
    TooltipDirective,
    PlacementReportComponent,
    PlacementResultsComponent,
    TestConfigComponent,
    ImporterComponent,
    NewOrganizationComponent,
    CandidateInfoComponent,
    PhraseImporterComponent,
    GrammarImporterComponent,
    QuizzesImporterComponent,
    GoalsComponent,
    FileUploaderComponent,
    DndDirective,
    SurveysComponent,
    SpinnerComponent,
    StaxImporterComponent,
    ImagesImporterComponent,
    DictionaryImporterComponent,
    EditorialImporterComponent,
    WisereaderImporterComponent,
    ActivityProfileComponent,
    FilterStaxPipe,
    ReportedIssuesComponent,
    AudioLabComponent,
    GapperImporterComponent,
    ModalGapComponent,
    WordsLabComponent,
    NoClusterModalComponent,
    StaxTransModalComponent,
    AssistantComponent,
    ContactsComponent,
    ModalImportDataComponent,
    ModalSendEmailComponent,
    ModalConversationComponent,
    ModalAddContactComponent,
    ModalCardContactComponent,
    ModalExpandedContactsComponent,
    // CardComponent
    // DisCatComponent,
    // FacultiesComponent,
    // CatalogoComponent,
    // SubjectComponent,
    // CardDestacadosComponent,
    // SearchComponent,
    // SearchFilterComponent
  ],
  imports: [
    EmailEditorModule,
    MatDialogModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    ClickOutsideModule,
    DayPilotModule,
    CKEditorModule,
    DragDropModule,
    ClipboardModule,
    MatSelectModule,
    NgxSliderModule,
    NgxExtendedPdfViewerModule,
    ImageCropperModule,
    MatSliderModule,
    // MatSliderModule,
    APP_ROUTING,
    NgxEditorModule
    ],
  providers: [
    AuthService,
    AuthGuardService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ServerErrorInterceptor,
    //   multi: true
    // },
    {provide: ErrorHandler, useClass: AppGlobalErrorhandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
