import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit {

  constructor(public auth: AuthService, public admin: AdminService,  private activatedRoute: ActivatedRoute,  private router: Router) { }

  importerSelected = 'Stax';
  params;

  ngOnInit(): void {
    this.auth.workingArea = 'importer';

  }



  selectImporter(area: string, event: MouseEvent) {
    let url: string;
    let route: string;

    switch (area) {
        case 'Grammar':
            this.importerSelected = 'Grammar';
            url = 'https://admin.altealabs.com/#/importer/grammar';
            route = '/importer/grammar';
            break;
        case 'Phrases':
            this.importerSelected = 'Phrases';
            url = 'https://admin.altealabs.com/#/importer/phrases';
            route = '/importer/phrases';
            break;
        case 'Stax':
            this.importerSelected = 'Stax';
            url = 'https://admin.altealabs.com/#/importer/stax';
            route = '/importer/stax';
            break;
        case 'Images':
            this.importerSelected = 'Images';
            url = 'https://admin.altealabs.com/#/importer/images';
            route = '/importer/images';
            break;
        case 'Dictionary':
            this.importerSelected = 'Dictionary';
            url = 'https://admin.altealabs.com/#/importer/dictionary';
            route = '/importer/dictionary';
            break;
        case 'Editorial':
            this.importerSelected = 'Editorial';
            url = 'https://admin.altealabs.com/#/importer/editorial';
            route = '/importer/editorial';
            break;
        case 'ActivityProfile':
            this.importerSelected = 'ActivityProfile';
            url = 'https://admin.altealabs.com/#/importer/activity-profile';
            route = '/importer/activity-profile';
            break;
        case 'Issues':
            this.importerSelected = 'Issues';
            url = 'https://admin.altealabs.com/#/importer/issues';
            route = '/importer/issues';
            break;
        case 'AudioLab':
            this.importerSelected = 'AudioLab';
            url = 'https://admin.altealabs.com/#/importer/audiolab';
            route = '/importer/audiolab';
            break;
        case 'Gapper':
            this.importerSelected = 'Gapper';
            url = 'https://admin.altealabs.com/#/importer/gapper';
            route = '/importer/gapper';
            break;
        default:
            return;
    }

    // Verificar si la tecla "Control" está presionada
    if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank'); // Abrir en nueva pestaña
    } else {
        this.router.navigate([route]); // Navegación normal
    }

    console.log(this.importerSelected)

}
}
