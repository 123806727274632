import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar, ToolbarDropdown } from 'ngx-editor';
import { SessionDataService } from 'src/app/services/session-data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { TextSelection, EditorState, Transaction } from 'prosemirror-state';
import { DOMParser } from 'prosemirror-model';
import { keymap } from 'prosemirror-keymap';
import { inputRules, InputRule } from 'prosemirror-inputrules';
import { Plugin } from 'prosemirror-state';
import { splitBlock } from 'prosemirror-commands';
import { customSchema } from '../../../../../custom-schema'
import { EmailEditorComponent } from 'angular-email-editor';

@Component({
  selector: 'app-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ModalSendEmailComponent {
  options: EmailEditorComponent['options'] = {
    version: 'latest',
    appearance: {
      theme: 'modern_dark',
    },

    fonts: { showDefaultFonts: false,
      customFonts: [
        {
          label: "Playfair Display",
          value: "'Playfair Display',serif",
          url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700",
        },
        {
          label: "Montserrat",
          value: "'Montserrat',sans-serif",
          url: "https://fonts.googleapis.com/css?family=Montserrat:400,700",
        },
        {
          label: "Inter",
          value: "'Inter',sans-serif",
          url: "https://fonts.googleapis.com/css?family=Inter:400,700",
        },
      ],
     }


  };

  @ViewChild(EmailEditorComponent)
  private emailEditor!: EmailEditorComponent;

  editorLoaded(event: any): void {
    // Aquí podrías cargar un diseño inicial si lo requieres
    console.log('Editor loaded', event);
    this.emailEditor.editor.setBodyValues({
      contentWidth: '1000px' // or you can use percentage like "50%"
    });
  }

  editorReady(event: any): void {
    console.log('Editor ready', event);

  }

    private get unlayer() {
    return this.emailEditor.editor;
  }





  exportEditorHtml(callback: (html: string, design: any) => void): void {
    this.emailEditor.editor.exportHtml((data: { design: any; html: string; }) => {
      const parser = new window.DOMParser();
      const doc = parser.parseFromString(data.html, 'text/html');
      const bodyContent = doc.body.innerHTML;
      callback(bodyContent, data.design);
    });
  }

toolbar: Toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  ['code', 'blockquote'],
  ['ordered_list', 'bullet_list'],
  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ['link', 'image', 'video' as any], // Forzamos el tipo para "video"
  ['text_color', 'background_color'],
  ['align_left', 'align_center', 'align_right', 'align_justify'],
  [{ fontFamily: ['Arial', 'Verdana', 'Times New Roman', 'Courier New'] } as any],
  [{ fontSize: ['10px', '12px', '14px', '16px', '18px', '20px'] } as any],
  ['insert_button' as any] // Forzamos el tipo para "insert_button"
];
  email = {
    senderMail: '',
    subject: '',
    recipient: '',
    content: '',
    category: '',
    design: ''
  };
  selectedAccount: string;

  namesArray = []

  constructor(private dialogRef: MatDialogRef<ModalSendEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public userData: UserDataService, private renderer: Renderer2) {
      this.data.contacts.forEach((contact) => {
        if(contact.email){
          this.namesArray.push(contact.email);
        }
      })

    this.email.recipient = this.namesArray.join(', ');


  }




  ngOnInit(): void {

    this.getMailsAccounts()
    this.getSavedMails()

  }



  ngOnDestroy(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }
  invalidEmails: string[] = []; // Array para almacenar correos inválidos
  validateEmails(input: string): void {
    // Reemplazar cada espacio con una coma
    let formattedInput = input.replace(/\s+/g, ',');

    // Eliminar comas consecutivas
    formattedInput = formattedInput.replace(/,+/g, ',');

    // Eliminar comas al principio o al final
    formattedInput = formattedInput.replace(/^,|,$/g, '');

    // Actualizar el campo con la estructura corregida
    this.email.recipient = formattedInput;

    console.log('Campo formateado:', this.email.recipient);
  }

  accounts = []
  getMailsAccounts(){

    this.userData.getMailsAccounts().subscribe((data: any) => {
      this.accounts = data
    })

  }


  onAccountSelected(event){
    console.log(event)

    this.selectedAccount = event;
  }


  isAddingNewCategory: boolean = false;
  categories: string[] = [];

  newCategory: string = '';


  onCategoryChange(selected: string) {
    if (selected === 'new') {
      this.isAddingNewCategory = true;
    } else {
      // Asigna la categoría seleccionada directamente al correo
      this.email.category = selected;
    }
  }

  selectExistent(){
    this.isAddingNewCategory = false;
  }

  // Cuando se pierde el foco del input, se guarda la nueva categoría
  onNewCategoryBlur() {
    if (this.newCategory.trim() !== '') {
      // Asigna la nueva categoría al correo, sin agregarla a la lista global
      this.email.category = this.newCategory.trim();
    }
  }

  // Método que podría servir para guardar la categoría actual o realizar alguna acción adicional
  saveEmail() {
    // Por ejemplo, guardar la categoría seleccionada o la nueva categoría en el servidor
    console.log('Categoría guardada');
    console.log(this.email)




    this.exportEditorHtml((editorContent: string,  editorDesign: any) => {
      // Aquí, si el backend ya se encarga de agregar el doctype y el head,
      // puedes enviar solo el contenido central.
      this.email.content = editorContent;
      this.email.senderMail = this.selectedAccount; // Asume que lo seleccionaste previamente
      this.email.design = editorDesign

    this.userData.saveEmail(this.email).subscribe((data: any) => {
      console.log("Enviado")




      const savedEmail = data

      if (!savedEmail.id) {
        savedEmail.id = new Date().getTime();
      }

      const category = savedEmail.category;
      // Actualiza la variable mailsGrouped: si la categoría existe, se agrega el email;
      // si no, se crea un nuevo grupo y se agrega la categoría a la lista.
      if (this.mailsGrouped[category]) {
        this.mailsGrouped[category].push(savedEmail);
      } else {
        this.mailsGrouped[category] = [savedEmail];
        if (!this.categories.includes(category)) {
          this.categories.push(category);
        }
      }

      console.log(this.mailsGrouped)

    })

  })
}

  getSavedMails(){
    this.userData.getSavedMails().subscribe((data: any) => {
      this.adjustCats(data)
    })




  }
  mailsGrouped = {}
  adjustCats(data){
    this.mailsGrouped = data;
    this.categories = Object.keys(data)
  }

  importMail(mail) {
    console.log(mail);
    this.email.subject = mail.title;
    this.email.content = mail.mail;
    if (mail.design) {
      try {
        const designObj = typeof mail.design === 'string'
                            ? JSON.parse(mail.design)
                            : mail.design;
        // Carga el diseño en el editor
        this.emailEditor.editor.loadDesign(designObj);
      } catch (error) {
        console.error('Error al parsear el JSON del diseño:', error);
      }
    }
  }

  sendEmail(): void {
    // Validación de campos
    // if (!this.email.subject || !this.email.recipient || !this.email.senderMail) {
    //   alert('Por favor, completa todos los campos antes de enviar.');
    //   return;
    // }

    // Exportar el contenido del editor
    this.exportEditorHtml((editorContent: string) => {
      // Aquí, si el backend ya se encarga de agregar el doctype y el head,
      // puedes enviar solo el contenido central.
      this.email.content = editorContent;
      this.email.senderMail = this.selectedAccount; // Asume que lo seleccionaste previamente

      // Envía el correo a través del servicio
      this.userData.sendEmail(this.email).subscribe((data: any) => {
        alert('Correo enviado correctamente.');
      });
      this.dialogRef.close(this.email);
    });
  }
}
