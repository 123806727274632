import { Routes, RouterModule } from "@angular/router";
import { LandingComponent } from "./components/landing/landing.component";
import { EditorComponent } from "./components/editor/editor.component";
import { ListCreatorComponent } from './components/list-creator/list-creator.component';
import { LabCreatorComponent } from './components/lab-creator/lab-creator.component';
import { FlyerEditorComponent } from './components/flyer-editor/flyer-editor.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { AuthGuardService } from './services/guard.service';
import { GroupsComponentComponent } from './components/groups-component/groups-component.component';
import { CrewAdminComponent } from './components/crew-admin/crew-admin.component';
import { StudentsAdminComponent } from './components/students-admin/students-admin.component';
import { GroupAdminComponent } from "./components/menu-admin/group-admin/group-admin.component";
import { NewGroupComponent } from "./components/menu-admin/new-group/new-group.component";
import { NewOrganizationComponent } from "./components/menu-admin/new-organization/new-organization.component";
import { RoleAdminComponent } from "./components/menu-admin/role-admin/role-admin.component";
import { PlannerComponent } from "./components/planner/planner.component";
import { MenuAdminComponent } from "./components/menu-admin/menu-admin.component";
import { TestConfigComponent } from "./components/menu-admin/test-config/test-config.component";
import { ImporterComponent } from './components/menu-admin/importer/importer.component';
import { FileUploaderComponent } from "./components/menu-admin/file-uploader/file-uploader.component";
import { SurveysComponent } from "./components/menu-admin/surveys/surveys.component";
import { GrammarImporterComponent } from "./components/menu-admin/grammar-importer/grammar-importer.component";
import { PhraseImporterComponent } from "./components/menu-admin/importer/phrase-importer/phrase-importer.component";
import { StaxImporterComponent } from "./components/menu-admin/importer/stax-importer/stax-importer.component";
import { DictionaryImporterComponent } from "./components/menu-admin/importer/dictionary-importer/dictionary-importer.component";
import { EditorialImporterComponent } from "./components/menu-admin/importer/editorial-importer/editorial-importer.component";
import { WisereaderImporterComponent } from "./components/menu-admin/importer/wisereader-importer/wisereader-importer.component";
import { GapperImporterComponent } from "./components/menu-admin/importer/gapper-importer/gapper-importer.component";
import { ActivityProfileComponent } from "./components/menu-admin/importer/activity-profile/activity-profile.component";
import { ReportedIssuesComponent } from "./components/menu-admin/importer/reported-issues/reported-issues.component";
import { AudioLabComponent } from "./components/menu-admin/importer/audio-lab/audio-lab.component";
import { ImagesImporterComponent } from "./components/menu-admin/importer/images-importer/images-importer.component";
import { AssistantComponent } from "./components/menu-admin/assistant/assistant.component";
import { ContactsComponent } from "./components/menu-admin/contacts/contacts.component";

const APP_ROUTES: Routes = [
  { path: "landing", component: LandingComponent },
  // { path: "listcreator", component: ListCreatorComponent ,canActivate: [AuthGuardService]},
  // { path: "labcreator", component: LabCreatorComponent,canActivate: [AuthGuardService]},
  // { path: "editor", component: EditorComponent,canActivate: [AuthGuardService] },
  // { path: "flyer-editor/:id", component: FlyerEditorComponent ,canActivate: [AuthGuardService]},
  // { path:"image-editor", component: ImageEditorComponent, canActivate: [AuthGuardService]},
  // { path:"groups", component: GroupsComponentComponent, canActivate: [AuthGuardService]},
  // { path:"crew-admin", component: CrewAdminComponent, canActivate: [AuthGuardService]},
  // { path:"students-admin", component: StudentsAdminComponent, canActivate: [AuthGuardService]},
  // { path:"planner", component: PlannerComponent},
  { path: "", component: LandingComponent },
  { path:"",
    component: MenuAdminComponent,
    canActivate: [AuthGuardService],
    children: [
      // { path: "group-admin", component: GroupAdminComponent },
      { path: "group-admin/:type", component: GroupAdminComponent },
      { path: "new-group", component: NewGroupComponent },
      { path: "new-organization", component: NewOrganizationComponent },
      { path: "role-admin", component: RoleAdminComponent },
      { path: "test-config", component: TestConfigComponent },
      { path: "assistant", component: AssistantComponent },
      { path: "contacts/organization", component: ContactsComponent },
      { path: "contacts/user", component: ContactsComponent },
      { path: "importer", component: ImporterComponent,
        children: [
          { path: "grammar", component: GrammarImporterComponent },
          { path: "phrases", component: PhraseImporterComponent },
          { path: "stax", component: StaxImporterComponent },
          { path: "images", component: ImagesImporterComponent },
          { path: "dictionary", component: DictionaryImporterComponent },
          { path: "editorial", component: EditorialImporterComponent },
          { path: "wisereader", component: WisereaderImporterComponent },
          { path: "gapper", component: GapperImporterComponent },
          { path: "activity-profile", component: ActivityProfileComponent },
          { path: "issues", component: ReportedIssuesComponent },
          { path: "audiolab", component: AudioLabComponent },
          // { path: "", redirectTo: "grammar", pathMatch: "full" },
          // { path: "**", redirectTo: "grammar" } // Manejo de rutas no válidas
        ]
      },
      { path: "surveys", component: SurveysComponent },
      { path: "uploader", component: FileUploaderComponent},
      { path: "**", pathMatch: "full", redirectTo: "/group-admin/group" }
    ],
  },

  // {
  //   path: "search",
  //   runGuardsAndResolvers: 'paramsChange',
  //   loadChildren: './modulos/search/search.module#SearchModule'
  // },
  // {
  //   path: "campus/:campus/:cat",
  //   loadChildren: './modulos/navigation/navigation.module#NavigationModule'
  // },
  // {
  //   path: "subject/:disp",
  //   loadChildren: './modulos/discp/discp.module#DiscpModule',
  //   runGuardsAndResolvers: 'paramsChange',
  // },
  // {
  //   path: "search/:query/:level/:rating/:faculty",
  //   loadChildren: './modulos/search/search.module#SearchModule'
  // },
  // {
  //   path: "search/:query",
  //   loadChildren: './modulos/search/search.module#SearchModule'
  // },
  // {
  //   path: "lab-landing/:id",
  //   component: LabComponent,
  //   canActivate: [LabGuardService]
  // },

  // {
  //   path: "lab-landing/:id/lab/stax",
  //   component: StackslandingComponent,
  //   canActivate: [LabGuardService],
  //   loadChildren: './modulos/lab/lab.module#LabModule'
  // },


  //{
  //  path: "wordstax",
  //  component: WordstaxComponent,
  // loadChildren: './modulos/wordstax/wordstax.module#WordstaxModule'
  //},
  { path: "**", pathMatch: "full", redirectTo: "" },
];
export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true, onSameUrlNavigation: 'reload'});
