<div class="chat-panel">
  <div *ngIf="data.contacts.nombre_de_empresa" class="chat-header">{{data.contacts.nombre_de_empresa}}, {{data.contacts.telefono}}</div>
  <div *ngIf="data.contacts.complete_name" class="chat-header">{{data.contacts.complete_name}}, {{data.contacts.phone}}</div>
  <div *ngIf="data.mails.length != 0" #chatMessagesContainer class="chat-messages">
      <!-- <div class="message received">Hi there!</div>
      <div class="message sent">Hello, how can I help you?</div> -->
        <div class="message" [ngClass]="{'sent': msg.from == 'javier.bartus@academiageorgetown.es', 'received': msg.from == 'richard@academiageorgetown.es'}" *ngFor="let msg of data.mails">
        <strong>{{ msg.from }}</strong> <!-- Nombre del usuario -->
        <p>{{ msg.body_plain }}</p> <!-- Mensaje -->
        <small>{{ msg.date | date: 'short' }}</small> <!-- Fecha del mensaje formateada -->
        </div>
  </div>
  <div *ngIf="data.mails.length == 0" #chatMessagesContainer class="chat-messages" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    NO HAY MENSAJES DISPONIBLES
  </div>

  <div class="chat-reply">
    <button (click)="openModalSendMail()"><i class="fa-solid fa-reply"></i>REPLY</button>
  </div>

</div>
