import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as jwt_decode from 'jwt-decode';
import { HttpParams } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})


export class AdminService {


  tooltipCoord = {};
  showTooltip: boolean = false;
  tooltipIndex: number = -1;
  tooltipPos = { x: -1, y: -1 };
  selectedGroupPlanner;
  office_groups = 'Pamplona'
  areaUsers;
  areaGroups;
  areaGroupsUsers;
  groupGoals;
  goalsGroup;
  tests;
  testTakers;
  selectedStudent;
  selectedGroup;
  selectedTest;
  start;
  edit_group: boolean = false;
  group_id;
  logIn;
  placementResults;
  plannerView: boolean = false;
  sidebarShow: boolean = false;
  selectedColor = '#203341';
  letterColor = 'white';
  view_role;
  userId_view;
  organizationId_view;
  campusTests;
  totalInHouseTestTakers = new Array();
  userView: boolean = false;
  lastSelectedMacroData = {};


  constructor(private http: HttpClient) {
  }


  getParamStax(resource, typeCatSub, subcat){
    this.lastSelectedMacroData = {resource: resource, typeCatSub: typeCatSub, subcat: subcat};
  }



    getLabFromCat(cat_id, type) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getLabFromCat`, { params: { 'cat_id': cat_id, 'type': type } });
    }


  changeColor(color:string){
    return {
      'background-color': color,
      'color': this.letterColor

    }
  }

  getTrans(array){
    return this.http.post(`${environment.apiUrl}/api/listadmin/getTrans`, { 'array': array});
  }



  goToLMS(group) {
    const url = 'https://lms.altealabs.com/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + group.group_id + '&placement=' + '0';
    // const url = 'http://localhost:4500/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + group.group_id + '&placement=' + '0';
    window.open(url, '_blank');
  }

  selectGroup(index, id) {
    let group_id;
    let groupUsers = new Array();
    if (this.selectedGroup != undefined) {
      this.areaGroups.forEach(group => {
        group.selected = false;
      });

    }

    if (id != undefined) {
      this.areaGroups.filter((group, i) => {
        if (String(group.group_id) == id) {
          this.selectedGroup = group;
          index = i;
          this.areaUsers.forEach(user => {
            if (String(user.group_id) == id) {
              groupUsers.push(user);
            }
          });
        }
      });
    }
    if (groupUsers.length != 0) {
      this.areaGroupsUsers = groupUsers;
      this.areaGroupsUsers[0].selected = true;
      this.selectedStudent = this.areaGroupsUsers[0];
    }
    this.areaGroupsUsers = groupUsers;
    this.areaGroups[index].selected = true;
    this.group_id = this.areaGroups[index].group_id;
    this.selectedGroup = this.areaGroups[index];

    document.getElementsByClassName('group')[0].scrollTop = 0;
    document.getElementsByClassName('group')[0].scrollTop += index * 15;


  }

  getNoClusterSubcats() {
    return this.http.get(`${environment.apiUrl}/api/catTree/getNoClusterSubcats`, { params: {} });
  }


  addVideo(formData: FormData) {
    return this.http.post(`${environment.apiUrl}/api/editor/addVideo`, formData);
  }

  switchPlannerView() {
    this.plannerView = !this.plannerView;
  }


  text: string = '';
  tooltipsText = new Array();
  indexTooltip;
  toolTipTypes = [ {name: 'Tooltip', checked: false},  {name: 'Toast', checked: false}]


  getTooltipText(){
    this.text = this.tooltipsText[this.indexTooltip];
    return this.text
  }


  getGroups(filters?, view_role?, user_id?, organization_id?) {
    if (filters == undefined || filters == '[]') {
      return this.http.get(`${environment.apiUrl}/api/viewgroups/getGroups`, { params: { 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id} });
    } else {
      filters = JSON.stringify(filters);
      return this.http.get(`${environment.apiUrl}/api/viewgroups/getGroups`, { params: { 'filters': filters, 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
    }
  }




  getCoursesBack() {
    return this.http.get(`${environment.apiUrl}/api/groupsadmin/getCourses`);
  }

  getInHouseTests(view_role?, user_id?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getInHouseTests`, { params: { 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
  }

  getExamId(course_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lms/getExamId`, { params: { 'course_id': course_id } });
  }

  generatePlacementURL(filters?) {
    filters = JSON.stringify(filters);
    return this.http.get(`${environment.apiUrl}/api/listadmin/generatePlacementURL`, { params: { 'filters': filters } });
  }

  getTestsConfig() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getTestsConfig`, { params: {} });
  }

  changeGroupStatus(group_id, active) {
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/activeGroup`, { 'group_id': group_id, 'active': active });
  }

  getStudentPerformance(user_id: string, group_id, degree_id, campus_id) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getStudentPerformance`, { params: { 'user_id': user_id, 'group_id': group_id, 'degree_id': degree_id, 'campus_id': campus_id } });
  }

  getClusters(){
    return this.http.get(`${environment.apiUrl}/api/courses/getClusters`, { params: {} });
  }


  setWritingSpeakingPerformance(user_id, degree_id, writing, speaking) {
    return this.http.post(`${environment.apiUrl}/api/viewgroups/setWritingSpeakingPerformance`, { 'user_id': user_id, 'degree_id': degree_id, 'writing': writing, 'speaking': speaking });
  }

  getGroupUsersActivityProfile(group_id: string, campus_id, degree_id) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getGroupUsersActivityProfile`, { params: { 'group_id': group_id, 'campus_id': campus_id, 'degree_id': degree_id } });
  }

  getStudentProgress(group_user_id: string, campus_id) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getStudentProgress`, { params: { 'group_user_id': group_user_id, 'campus_id': campus_id } });
  }

  getFiltersBack(view_role?, user_id?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getFilters`, { params: { 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
  }

  getInfoPlanner(view_role?, user_id?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getCalendar`, { params: {'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
  }

  setTeacherPlanner(teacher_id, startEvent, endEvent, startHour, endHour,) {
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/setTeacherCalendar`, { 'teacher_id': teacher_id, 'startEvent': startEvent, 'endEvent': endEvent, 'startHour': startHour, 'endHour': endHour, });
  }

  createOrganization(organization_name, office_name, office_id, company_id, company_name, image, area) {
    return this.http.post(`${environment.apiUrl}/api/organization/createOrganization`, { 'organization_name': organization_name, 'office_id': office_id, 'office_name': office_name, 'company_id': company_id, 'company_name': company_name, 'image': image, 'area': area});
  }

  updateOrganization (organization_id, exams, office_id , office_name, company_id, company_name, image, area ) {
    return this.http.post(`${environment.apiUrl}/api/organization/updateOrganization `, { 'organization_id': organization_id, 'exams': exams, 'office_id': office_id, 'office_name': office_name, 'company_id': company_id, 'company_name': company_name, 'image': image, 'area': area });
  }


  createGroupBack(name, description, mode, organization, startDate, endDate, course_id, group_id, max_users, date, duration, daysGroup, office_id?, office_name?) {
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/createGroup`, {
      'name': name,
      'description': description,
      'mode': mode,
      'organization': organization,
      'startDate': startDate,
      'endDate': endDate,
      'course_id': course_id,
      'id': group_id,
      'max_users': max_users,
      'date': date,
      'placement': false,
      'daysgroup': daysGroup,
      'duration': duration,
      'office_id': office_id,
      'office_name': office_name
    });
  }


  getOrganizationInfo(view_role?, user_id?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getOrganizationInfo`, { params: { 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
  }

  getGroupsOptionsBack(group_id) {
    if (group_id == undefined) {
      return this.http.get(`${environment.apiUrl}/api/groupsadmin/getGroupsOptions`, { params: {} });
    } else {
      return this.http.get(`${environment.apiUrl}/api/groupsadmin/getGroupsOptions`, { params: { 'id': group_id } });
    }
  }

  getOrganizationOptionsBack(organization_id?) {
    if (organization_id == undefined) {
      return this.http.get(`${environment.apiUrl}/api/organization/getOrganizationOptions`, { params: {} });
    } else {
      return this.http.get(`${environment.apiUrl}/api/organization/getOrganizationOptions`, { params: { 'organization_id': organization_id } });
    }
  }


  deleteAllWords(cat_id ,subcat_id, type) {
    return this.http.post(`${environment.apiUrl}/api/catTree/deleteAllWords`, {'cat_id': cat_id, 'subcat_id': subcat_id, 'type': type });
  }

  deleteLabWord(arrayWords, cat_id, subcat_id, type) {
    return this.http.post(`${environment.apiUrl}/api/catTree/deleteLabWord`, { 'arrayWords': arrayWords, 'cat_id':cat_id, 'subcat_id': subcat_id,  'type': type });
  }


  getCompanies(search_value) {
    return this.http.get(`${environment.apiUrl}/api/searchCompanies`, { params: { 'name': search_value } });
  }


  getGroupsCandidates(filters, group_id?) {
    filters = JSON.stringify(filters);
    return this.http.get(`${environment.apiUrl}/api/groupsadmin/getGroupsCandidates`, { params: { 'filters': filters, 'group_id': group_id } });
  }

  getTestTakers(organization_id: number, exam_id: number) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getTestTakers`, { params: { 'organization_id': organization_id, 'exam_id': exam_id } });
  }

  getOrganizationBestResults (test_takers, exam_id, view_role?, user_id?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getOrganizationBestResults`, { params: { 'test_takers': test_takers, 'exam_id': exam_id, 'view_role': view_role, 'user_id': user_id, 'organization_id': organization_id } });
  }

  getUserLogInfo(user_id){
    return this.http.get(`${environment.apiUrl}/api/listadmin/getUserLogInfo`, { params: { 'user_id': user_id } });
  }

  getRegisteredUsers() {
    return this.http.get(`${environment.apiUrl}/api/groupsadmin/getRegisteredUsers`, { params: {} });
  }

  getPlacementReport() {
    return this.http.post(`${environment.apiUrl}/api/exam/simulatePlacementResults`, {});
  }

  getStudentExamsReports(user_id, campus_id) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getStudentExamsReports`, { params: { 'user_id': user_id, 'campus_id': campus_id} });
  }

  getCandidateInfo(user_id, exam_user_id, view_role?, userId_view?, organization_id?) {
    return this.http.get(`${environment.apiUrl}/api/viewgroups/getCandidateInfo`, { params: { 'id': user_id, 'exam_user_id': exam_user_id,  'view_role': view_role, 'user_id': userId_view, 'organization_id': organization_id } });
  }

  getProspectInfo(id: number) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getProspectInfo`, { params: { 'id': id } });
  }

  searchProspects(prospect) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/searchProspects`, { params: { 'prospect': prospect  } });
  }

  sendToAnalysis(id, type) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/sendToAnalysis`, { 'id': id, 'type': type});
  }

  removeFromAnalysis(id, type) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/sendToAnalysis`, { 'id': id, 'type': type});
  }

  getProspectsWithAnalysis() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getProspectsWithAnalysis`, { });
  }

  removeProspectFromAnalysis(prospect_id) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/removeProspectFromAnalysis`, {'prospect_id': prospect_id });
  }

  getCompaniesToReview () {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getCompaniesToReview`, { params: { } });
  }

  addCompanies(companies) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/addCompanies`, {'companies': companies});
  }

  createSurvey(file, prospect_id, name, prompt, questions, organization_id?) {
    prompt = JSON.stringify(prompt);
    return this.http.post(`${environment.apiUrl}/api/courses/createSurvey`, {'file': file, 'prospect_id': prospect_id ,'name': name, 'prompt': prompt, 'questions': questions, 'organization_id': organization_id });
  }

  updateSurveyInfo(survey_id, name, prompt, questions) {
    prompt = JSON.stringify(prompt);
    return this.http.post(`${environment.apiUrl}/api/courses/updateSurveyInfo`, {'survey_id': survey_id, 'name': name, 'prompt': prompt, 'questions': questions });
  }

  createSurveyFile(file, organization_id, prospect_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/createSurveyFile`, {'file': file, 'organization_id': organization_id, 'prospect_id': prospect_id });
  }

  cloneSurveys(origin_prospect_id, origin_organization_id, target_prospect_id, target_organization_id, target_organization_name ) {
    return this.http.post(`${environment.apiUrl}/api/courses/cloneSurveys`, {'origin_prospect_id': origin_prospect_id, 'origin_organization_id': origin_organization_id, 'target_prospect_id': target_prospect_id, 'target_organization_id': target_organization_id, 'target_organization_name': target_organization_name });
  }

  closeSurveyFile (file, organization_id, prospect_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/closeSurveyFile`, {'file': file, 'organization_id': organization_id, 'prospect_id': prospect_id });
  }

  getChallenges(prospect_id ) {
    return this.http.get(`${environment.apiUrl}/api/user/getChallenges`, { params: { 'prospect_id': prospect_id   } });
  }

  getOrganizations() {
    return this.http.get(`${environment.apiUrl}/api/groupsadmin/getOrganizations`, { params: { } });
  }


  insertUsersToCourseGroup(users, group_id) {
    users = JSON.stringify(users);
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/insertUsersToCourseGroup`, {
      'users': users,
      'coursegroup_id': group_id
    });
  }

  deleteUserToCourse(user_id, group_id) {
    user_id = JSON.stringify(user_id);
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/deleteUserToCourseGroup`, {
      'users': user_id,
      'group_id': group_id
    });
  }

  getGrammarIndexFilters() {
    return this.http.get(`${environment.apiUrl}/api/importer/getGrammarIndexFilters`, { params: {} });
  }

  getPhrasesFilters() {
    return this.http.get(`${environment.apiUrl}/api/importer/getPhrasesFilters`, { params: {} });
  }

  getStaxFilters() {
    return this.http.get(`${environment.apiUrl}/api/importer/getStaxFilters`, { params: {} });
  }

  getActivityProfiles(campus_id, target){
    return this.http.get(`${environment.apiUrl}/api/lms/getActivityProfiles`, { params: {  'campus_id': campus_id, 'target': target } });
  }

  updateActivityProfile(campus_id, podtype_id, grammar, vocabulary, terminology, idiom, job, academic, reading, listening, writing, speaking, l_reasoning, difficulty) {
    return this.http.post(`${environment.apiUrl}/api/lms/updateActivityProfile`, { 'campus_id': campus_id, 'podtype_id': podtype_id,  'grammar': grammar, 'vocabulary': vocabulary, 'terminology': terminology, 'idiomatic exp': idiom, 'job interview': job, 'academic': academic, 'reading': reading, 'listening': listening, 'writing': writing, 'speaking': speaking, 'l_reasoning': l_reasoning, 'difficulty': difficulty});
  }

  setGrammarIndexExplanation(explanation, levels, subarea_id, options_ids) {
    explanation = JSON.stringify(explanation);
    return this.http.post(`${environment.apiUrl}/api/importer/setGrammarIndexExplanation`, { 'explanation': explanation, 'levels': levels, 'subarea_id': subarea_id , 'options_ids':options_ids});
  }

  updateFiltersOrder(filters_ids) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateFiltersOrder`, { 'filters_ids': filters_ids});
  }

  setPhrasesConfig(phrases, levels, subjects, options) {
    return this.http.post(`${environment.apiUrl}/api/importer/setPhrasesConfig`, { 'phrases': phrases, 'levels': levels, 'subjects': subjects, 'options': options});
  }

  updatePhrases(phrases, levels, subjects, options) {
    return this.http.post(`${environment.apiUrl}/api/importer/updatePhrases`, { 'phrases': phrases, 'levels': levels, 'subjects': subjects, 'options': options});
  }

  getGrammarIndexExplanation(level: number, subarea_id: number, options_ids) {
    options_ids = JSON.stringify(options_ids);
    return this.http.get(`${environment.apiUrl}/api/importer/getGrammarIndexExplanation`, { params: { 'level': level, 'subarea_id': subarea_id, 'options_ids': options_ids}});
  }

  updateGrammarIndexExplanation(explanation, levels, options_ids) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateGrammarIndexExplanation`, { 'explanation': explanation, 'levels': levels,'options_ids':options_ids});
  }

  getPhrases(options) {
    options = JSON.stringify(options);
    return this.http.get(`${environment.apiUrl}/api/importer/getPhrases`, { params: { 'options': options}});
  }

  setStaxLab(content, wordStaxObjectArray, staxType, levels, cat_id, subcat_id, course_id, campus_id, separator? ,target?) {
    if (campus_id == 1){
      return this.http.post(`${environment.apiUrl}/api/importer/setStaxLab`, { 'content': content, 'word_object_array': wordStaxObjectArray, 'stax_type': staxType, 'levels': levels, 'separator': separator});
    } else{
      return this.http.post(`${environment.apiUrl}/api/importer/setStaxLab`, { 'content': content, 'word_object_array': wordStaxObjectArray, 'stax_type': staxType, 'cat_id': cat_id, 'course_id': course_id, 'subcat_id': subcat_id, 'target' : target, 'separator': separator});
    }
  }

  setDictionaryWords(content, wordStaxObjectArray) {
    return this.http.post(`${environment.apiUrl}/api/importer/setStaxLab`, { 'content': content, 'word_object_array': wordStaxObjectArray, 'dictionary': true});
  }

  checkDictionaryWords(content) {
    let params = new HttpParams();
    content.forEach((cont, index) => {
        params = params.append(`content[]`, cont);
    });
    return this.http.get(`${environment.apiUrl}/api/importer/checkDictionaryWords`,{ params });
  }



  checkStax(content, stax_type, separator? ,lab_id?, target?) {
    return this.http.post(`${environment.apiUrl}/api/importer/checkStax`, { 'content': content, 'stax_type': stax_type, 'separator':separator,  'lab_id': lab_id, 'target': target});
  }

  checkTransStax(content, stax_type, lab_id?, target?) {
    return this.http.post(`${environment.apiUrl}/api/importer/checkTransStax`, { 'content': content, 'stax_type': stax_type, 'lab_id': lab_id, 'target': target});
  }

  updateStaxLab(content, levels, subjects) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateStaxLab`, { 'content': content, 'levels': levels, 'subjects': subjects});
  }

  getStaxLab(lab_id, subcat_id, stax_type) {
    return this.http.get(`${environment.apiUrl}/api/importer/getStaxLab`, { params: { 'lab_id': lab_id, 'subcat_id': subcat_id, 'stax_type': stax_type}});
  }

  addFilter(names, type) {
    return this.http.post(`${environment.apiUrl}/api/importer/addFilter`, { 'names': names, 'type': type });
  }

  removeFilter(filter_id, type) {
    return this.http.post(`${environment.apiUrl}/api/importer/removeFilter`, { 'filter_id': filter_id, 'type': type });
  }

  addFiltersOption(filter_id, options, type, levels, subarea_id, options_ids) {
    return this.http.post(`${environment.apiUrl}/api/importer/addFiltersOption`, {  'filter_id': filter_id, 'options': options, 'type': type, 'levels': levels, 'subarea_id': subarea_id, 'options_ids': options_ids });
  }

  addGrammarIndexSubarea(levels: object[], area, subarea) {
    return this.http.post(`${environment.apiUrl}/api/importer/addGrammarIndexSubarea`, { 'levels': levels, 'area': area, 'subarea': subarea });
  }

  updateGrammarIndexSubarea(id, name) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateGrammarIndexSubarea`, { 'id': id, 'name': name});
  }

  updateFilter(filter_id, filter_name) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateFilter`, { 'filter_id': filter_id, 'filter_name': filter_name});
  }

  updateFiltersOptions(option_id, option_name) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateFiltersOption`, { 'option_id': option_id, 'option_name': option_name});
  }

  getAllImportsFiltersNames(importer_name) {
    return this.http.get(`${environment.apiUrl}/api/importer/getAllImportsFiltersNames`, { params: { 'importer_name': importer_name }});
  }

  closeContent(type, id) {
    return this.http.post(`${environment.apiUrl}/api/importer/closeContent`, { 'type': type , 'id': id});
  }

  closeFilterOption(option_id) {
    return this.http.post(`${environment.apiUrl}/api/importer/closeFilterOption`, { 'option_id': option_id});
  }

  getReadingFilters(){
    return this.http.get(`${environment.apiUrl}/api/importer/getReadingFilters`, { params: { }});
  }

  checkReadingImport(content, writingTypes, plan_type) {
    return this.http.post(`${environment.apiUrl}/api/importer/checkReadingImport`, { 'content': content, 'writing_types': writingTypes, 'plan_type': plan_type});
  }

  setReadingCard(course_id, cat_id, subcats_ids, content_parts, plan_type) {
    return this.http.post(`${environment.apiUrl}/api/importer/setReadingCard`, { 'course_id': course_id, 'cat_id': cat_id, 'subcats_ids': subcats_ids, 'content_parts': content_parts, 'plan_type': plan_type});
  }

  cloneReadingContent(reading_cards, target_subcats) {
    return this.http.post(`${environment.apiUrl}/api/importer/cloneReading`, { 'reading_cards': reading_cards, 'target_subcats': target_subcats});
  }

  cloneStax(lab_id, target_subcats, stax_type) {
    return this.http.post(`${environment.apiUrl}/api/importer/cloneStaxLab`, { 'lab_id': lab_id, 'target_subcats': target_subcats, "stax_type" : stax_type});
  }

  getReadingCard(readingcard_id){
    return this.http.post(`${environment.apiUrl}/api/importer/getReadingCard`, {  'readingcard_id': readingcard_id });
  }

  udpateReadingCard(readingcard_id, content_parts) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateReadingCard`, { 'readingcard_id': readingcard_id, 'content_parts': content_parts});
  }

  gapByFreq(texts, selectedPlan, selectedType, gapFreq, cicle){
    return this.http.post(`${environment.apiUrl}/api/importer/gapByFreq`, { 'texts': texts , 'plan_type': selectedPlan, "type": selectedType, "gapFreq": gapFreq, "cicle": cicle });
  }

  createGappedText(title, text, type, gaps, num_features, plan_type, topic, Audiogaps){
    return this.http.post(`${environment.apiUrl}/api/importer/createGappedText`, { 'title': title, 'text': text, 'type': type, 'gaps': gaps, 'num_features': num_features, 'plan_type': plan_type, 'topic': topic, 'Audiogaps': Audiogaps });
  }

  getTypeReading(){
    return this.http.get(`${environment.apiUrl}/api/importer/getTypeReading`, { params: { }});
  }



  getCatTreeInfo() {
    return this.http.get(`${environment.apiUrl}/api/catTree/getCatTreeInfo`, { params: { }});
  }

  getNewCatTree () {
    return this.http.get(`${environment.apiUrl}/api/catTree/getNewCatTree`, { params: { }});
  }

  getWordsByLab(subcat_id, type, origin) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getWordsByLab`, { params: { 'subcat_id': subcat_id, 'type': type, 'origin': origin}});
  }

  getWordsByRed(subcat_id, type) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getWordsRed`, { params: { 'subcat_id': subcat_id, 'type': type}});
  }

  getWordsByCat(cat_id, type) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getWordsByCat`, { params: { 'cat_id': cat_id, 'type': type}});
  }


  getWordsPublisher(course_id, type) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getWordsPublisher`, { params: { 'course_id': course_id, 'type': type}});
  }

  getWordsGlobal(type){
    console.log("type", type)
    return this.http.get(`${environment.apiUrl}/api/catTree/getWordsGlobal`, { params: { 'type': type}});
  }

  setDegreeImage (subcat_id, image) {
    return this.http.post(`${environment.apiUrl}/api/images/setDegreeImage`, { 'subcat_id': subcat_id, 'image': image});
  }

  createCategory(cat_name) {
    return this.http.post(`${environment.apiUrl}/api/courses/createCategory`, { 'cat_name': cat_name});
  }

  udpateCategory(cat_id, new_name) {
    return this.http.post(`${environment.apiUrl}/api/courses/updateCategory`, { 'cat_id': cat_id, 'new_name': new_name});
  }

  deleteCategory(cat_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/deleteCategory`, { 'cat_id': cat_id});
  }

  createSubcategory(subcat_name, cat_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/createSubcategory`, { 'subcat_name': subcat_name, 'cat_id': cat_id});
  }

  updateSubcategory(subcat_id, new_name) {
    return this.http.post(`${environment.apiUrl}/api/courses/updateSubcategory`, { 'subcat_id': subcat_id, 'new_name': new_name});
  }

  deleteSubcategory(subcat_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/deleteSubcategory`, { 'subcat_id': subcat_id});
  }

  createCluster(cluster_name) {
    return this.http.post(`${environment.apiUrl}/api/courses/createCluster`, { 'cluster_name': cluster_name});
  }

  assignClusterToSubcategory(cluster_id, subcats) {
    return this.http.post(`${environment.apiUrl}/api/courses/assignClusterToSubcategory`, { 'cluster_id': cluster_id, 'subcats': subcats});
  }


  assignCourseToSubcategory(course_id, subcat_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/assignCourseToSubcategory`, { 'course_id': course_id, 'subcat_id': subcat_id});
  }

  disableSubcategory(subcat_id) {
    return this.http.post(`${environment.apiUrl}/api/courses/disableSubcategory`, { 'subcat_id': subcat_id});
  }

  setToolTip(origin, number, text, type) {
    return this.http.post(`${environment.apiUrl}/api/editor/setToolTip`, { 'origin': origin, 'number': number, 'text': text, 'type': type});
  }

  getToolTips(origin) {
    return this.http.get(`${environment.apiUrl}/api/metricspod/getToolTips`, { params: { 'origin': origin }});
  }

  getReportedIssues() {
    return this.http.get(`${environment.apiUrl}/api/report/getReportedIssues`);
  }

  getReportedStaxItems(lab_id) {
    return this.http.get(`${environment.apiUrl}/api/importer/getReportedStaxItems`, { params: {'lab_id': lab_id} });
  }

  getGeneralIssues() {
    return this.http.get(`${environment.apiUrl}/api/report/getGeneralIssues`);
  }

  updateReportedStaxItems(fixed_items) {
    return this.http.post(`${environment.apiUrl}/api/importer/updateReportedStaxItems`, { 'fixed_items':  JSON.stringify(fixed_items)});

  }

  setTESLAUserCourse(user_id, subcat_id)
  {
    return this.http.post(`${environment.apiUrl}/api/groupsadmin/setTESLAUserCourse`, { 'user_id' : user_id, 'subcat_id': subcat_id});
  }

  getAudio(items, subtype){
    return this.http.get(`${environment.apiUrl}/api/learningpath/getAudio`, { params: { 'items': items, 'subtype': subtype }});
  }

}
