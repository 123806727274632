import { Component, OnInit } from '@angular/core';
import { ExcelReaderService } from 'src/app/services/excel-reader.service';
import { ModalImportDataComponent } from './modal-import-data/modal-import-data.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalSendEmailComponent } from './modal-send-email/modal-send-email.component';
import { ModalConversationComponent } from './modal-conversation/modal-conversation.component';
import { ModalAddContactComponent } from './modal-add-contact/modal-add-contact.component';
import { ModalCardContactComponent } from './modal-card-contact/modal-card-contact.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  headers: string[] = []; // Aquí se almacenan los nombres de las columnas
  error: string | null = null; // Para manejar errores

  constructor(
    private excelReaderService: ExcelReaderService,
    private dialog: MatDialog,
    private http: HttpClient,
    private userData: UserDataService,
    private auth: AuthService,
    private router: Router,
    public admin: AdminService
  ) {}


  contactsSelected = []
  contacts: any[] = [];
  currentPage: number = 1;
  perPage: number = 30;
  totalPages: number = 0;
  loading: boolean = false;
  appliedFilters: { column: string; value: string }[] = [];
  selectedColumn: string = '';
  filterValue: string = '';
  spinner: boolean = false;
  currentUrl: string = '';
  selectedMode = '';
  headersOficial = [];
  ngOnInit(): void {

    this.currentUrl = this.router.url;
    console.log('Ruta actual:', this.currentUrl);

    if(this.router.url == '/contacts/user'){
      this.headersOficial = [
        'id',
        'role_id',
        'name',
        'last_name',
        'email',
        'password',
        'date_of_birth',
        'locked_at',
        'card_brand',
        'card_last_four',
        'remember_token',
        'created_at',
        'updated_at',
        'picture',
        'confirmation_code',
        'confirmed',
        'last_login',
        'current_login',
        'passwordreset_code',
        'reset',
        'pec_faculty_id',
        'cert_faculty_id',
        'second_last_name',
        'phone',
        'country',
        'promotional_code',
        'passwordToModify',
        'deleted',
        'postal',
        'address',
        'province',
        'organization',
        'company_id',
        'ip',
        'stripe_id',
        'pm_type',
        'pm_last_four',
        'trial_ends_at',
        'uni_id',
        'research_center_id',
        'second_surname',
        'country_residence',
        'yearOfBirth',
        'online',
        'last_activity'
    ];

      this.selectedMode = 'user'
      this.auth.workingArea = 'contacts-users'

    }
    else{
      this.headersOficial = [
        'NOMBRE DE EMPRESA',
        'DIRECCION',
        'CP',
        'SUB LOCALIDAD',
        'LOCALIDAD',
        'PROVINCIA',
        'COMUNIDAD AUTONOMA',
        'LATITUD',
        'LONGITUD',
        'TELEFONO',
        'TEL. ADICIONAL',
        'EMAIL',
        'SITIO WEB',
        'ACTIVIDAD',
        'SUBSECTOR',
        'FORMA SOCIAL',
        'FACEBOOK',
        'INSTAGRAM',
        'TWITTER',
        'YOUTUBE'
      ];
      this.selectedMode = 'company'
      this.auth.workingArea = 'contacts-organizations'

    }


    this.loadContacts();
    this.elasticSearchColeccion('%')
    this.contactsSelected = []
  }


  openModalAddOrganization(){
    this.dialog.open(ModalAddContactComponent, {
      width: '80%',
  })
  }





  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  searchQuery = ''
  localidades = []
  provincias = []
  loadContacts(): void {
    this.loading = true;

    const filters = this.appliedFilters.map((filter) => ({
      column: filter.column,
      value: filter.value
    }));


    if(this.selectedMode == 'company'){
      this.userData.getFilteredContacts(filters, this.currentPage, this.perPage).subscribe((data: any) => {
        this.contacts = data.data;
        this.totalPages = data.last_page;
        this.loading = false;
      });
    }

    else{

      this.userData.getFilteredContactsUsers(filters, this.currentPage, this.perPage).subscribe((data: any) => {
        this.contacts = data.data;
        this.totalPages = data.last_page;
        this.loading = false;
        console.log(data)

      });
    }
  }





  getAllContactFromColection(){

    const filters = this.appliedFilters.map((filter) => ({
      column: filter.column,
      value: filter.value
    }));

    this.userData.getAllContactFromColection(filters).subscribe((data: any) => {

      for (let i = 0; i < data.length; i++) {
        this.contactsSelected.push(data[i]);
      }

      this.loading = false;
    });
  }

  isDropdownOpen: boolean = false; // Estado del dropdown
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  userFilterType: 'all' | 'buyers' | 'nonBuyers' = 'all';

  filterUsers(type: 'all' | 'buyers' | 'nonBuyers'): void {
    // Actualiza el tipo de filtro
    this.userFilterType = type;

    // Elimina cualquier filtro previo sobre subcats_count (para evitar duplicados)
    this.appliedFilters = this.appliedFilters.filter(filter => filter.column !== 'subcats_count');

    // Según el botón seleccionado, añade el filtro correspondiente
    if (type === 'buyers') {
      // Filtrar usuarios con subcats_count mayor a 1 (compradores)
      this.appliedFilters.push({ column: 'subcats_count', value: '>0' });
    } else if (type === 'nonBuyers') {
      // Filtrar usuarios con subcats_count menor a 1 (no compradores)
      this.appliedFilters.push({ column: 'subcats_count', value: '=0' });
    }
    // Si es 'all' no se añade filtro sobre subcats_count

    // Reinicia la página actual y vuelve a cargar los contactos
    this.currentPage = 1;
    this.loadContacts();
  }

  roleFilterType: 'all' | 'alumnos' | 'staff' = 'all'

  filterUsersRole(type: 'all' | 'alumnos' | 'staff'){

    this.roleFilterType = type;

    this.appliedFilters = this.appliedFilters.filter(filter => filter.column !== 'role_name');

    if (type === 'alumnos') {
      // Para alumnos, se espera que el role_name sea "student"
      this.appliedFilters.push({ column: 'role_name', value: 'student' });
    } else if (type === 'staff') {
      // Para staff, se agregan varios filtros de role_name.
      // ¡Importante!: Se usarán todos en conjunto para formar una condición OR.
      this.appliedFilters.push({ column: 'role_name', value: 'admin' });
      this.appliedFilters.push({ column: 'role_name', value: 'teacher' });
      this.appliedFilters.push({ column: 'role_name', value: 'dean' });
      this.appliedFilters.push({ column: 'role_name', value: 'publisherDean' });
      this.appliedFilters.push({ column: 'role_name', value: 'agent' });
      this.appliedFilters.push({ column: 'role_name', value: 'audiovisual' });
      this.appliedFilters.push({ column: 'role_name', value: 'support' });
      this.appliedFilters.push({ column: 'role_name', value: 'superadmin' });
    }
    this.currentPage = 1;
    this.loadContacts();




  }


  addFilter(): void {
    if (!this.filterValue) {
      alert('Por favor, selecciona al menos una columna y proporciona un valor.');
      return;
    }
    this.currentPage = 1;

    // Añade un filtro para cada columna seleccionada
    this.selectedColumns.forEach((column) => {
      this.appliedFilters.push({ column, value: this.filterValue });
    });

    this.filterValue = ''; // Limpia el valor del filtro
    this.options = []; // Limpia las opciones de búsqueda
    this.loadContacts(); // Recarga los contactos con los nuevos filtros
  }

  removeFilter(i: number): void {
    this.appliedFilters.splice(i, 1);
    if (this.tooltipIndex === i) {
      this.tooltipIndex = null;
    }
    this.loadContacts();
  }



  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadContacts();
  }

  options = []
  optionsColeccion = []
  selectedColumns: string[] = []; // Columnas seleccionadas

  elasticSearch(text){
    this.isDropdownOpen = false;
    if(text.length > 2){
      console.log(this.selectedColumns)
      this.userData.elasticSearchContacts(text, this.selectedColumns).subscribe((data: any) => {
        this.options = data;

      })
    }
  }

  elasticSearchColeccion(text){
      this.userData.elasticSearchContacts(text, ['coleccion']).subscribe((data: any) => {
        this.optionsColeccion = data;

      })
  }

  selectOptionColeccion(option){
    this.filterValueColeccion = option;
    this.optionsColeccion = []

    this.userData.selectColeccion(this.filterValueColeccion).subscribe((data: any) => {
      this.contacts = data.data;
      this.totalPages = data.last_page;
      this.loading = false;
    })


  }

  exportarExcelVacio(): void {
    this.excelReaderService.exportEmptyExcel('CAMBIA EL NOMBRE');
  }

  toggleAllContacts(event: Event): void {

    console.log(event)

    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      console.log('El checkbox está marcado.');
      this.getAllContactFromColection();
    } else {
      console.log('El checkbox está desmarcado.');
      this.contactsSelected = [];
    }
  }


  checkChecked(contact){
    return this.contactsSelected.find(c => c.id === contact.id) ? true : false
  }

  openModalSendMail(){
    this.dialog.open(ModalSendEmailComponent, {
      width: '63%',
      height: '80%',
      data: {
        contacts: this.contactsSelected,
      },
    });
  }




  getMails(mail): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userData.getMails(mail).subscribe(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      );
    });
  }



  openModalCardContact(contact: any) {
    this.dialog.open(ModalCardContactComponent, {
      width: '80%',
      data: {
        contact,

      },
    });

  }


  async openModalConversation(contact: any) {
    try {
      this.spinner = true
      const mails = await this.getMails(contact.email);
      this.dialog.open(ModalConversationComponent, {
        width: '600px',
        height: '600px',
        data: {
          contacts: contact,
          mails: mails,
        },
      });
      this.spinner = false

    } catch (error) {
      console.error('Error fetching mails:', error);
    }
  }



  toggleRow(contact: any): void {
    const index = this.contactsSelected.findIndex(c => c.id === contact.id);

    if (index > -1) {
      // Si el contacto ya está en la lista, se elimina
      this.contactsSelected.splice(index, 1);
    } else {
      // Si no está en la lista, se añade
      this.contactsSelected.push(contact);
      console.log(this.contactsSelected)
    }
  }



  toggleColumn(column: string, isChecked: boolean): void {
    if (isChecked) {
      this.selectedColumns.push(column);
    } else {
      this.selectedColumns = this.selectedColumns.filter((col) => col !== column);
    }
  }
  tooltipIndex: number | null = null; // Índice del filtro sobre el que está el mouse

  showTooltip(i: number): void {
    this.tooltipIndex = i;
  }
  hideTooltip(i: number): void {
    if (this.tooltipIndex === i) {
      this.tooltipIndex = null;
    }
  }

  filterValueColeccion = ''


  selectOption(option){
    this.filterValue = option;
    this.options = []
  }








  fileName = '';






  // Método que se ejecuta al cargar un archivo
  onFileChange(event: any): void {
    const file = event.target.files[0];

    if (!file) {
      this.error = 'Por favor, selecciona un archivo.';
      return;
    }

    this.fileName = file.name; // Obtén el nombre del archivo

    this.fileName = this.fileName.split('.').slice(0, -1).join('.'); // Elimina la extensión

    this.excelReaderService.readExcel(file)
      .then((data) => {
        const headers = data[0]; // Asume que la primera fila son los encabezados
        const totalRows = data.length - 1; // Resto de filas como datos

        const matchingHeaders = headers.filter((header: string) =>
          this.headersOficial.includes(header)
        );


        const dialogRef = this.dialog.open(ModalImportDataComponent, {
          width: '400px',
          data: {
            matchingHeaders,
            totalRows,
          },
        });

        // Opcional: manejar la respuesta del modal
        dialogRef.afterClosed().subscribe(result => {
          if (result?.imported) {

            if (typeof Worker !== 'undefined') {
              const worker = new Worker(new URL('../../../webworkers/excel-processor.worker.ts', import.meta.url));
              worker.onmessage = ({ data }) => {
                if (data.success) {
                  console.log("Filename",this.fileName)
                  this.userData.importExcel(data, this.fileName).subscribe(
                    (response) => {
                      console.log('Datos enviados exitosamente al backend:', response);
                    },
                    (error) => {
                      console.error('Error al enviar los datos al backend:', error);
                    }
                  );

                } else {
                  console.error('Error procesando el archivo:', data.error);
                }
              };

              worker.postMessage({ file });
            } else {
              console.error('Web Workers no son soportados en este navegador.');
            }


          }
        });
      })
      .catch((err) => {
        this.error = 'Error al leer el archivo: ' + err.message;
      });
  }
}
