<div  *ngIf="isAuth()" class="w-100 h-100 d-flex color" [ngStyle]="admin.changeColor(admin.selectedColor)">
    <div class="sidebar-slider h-100 zoomResponsiveness" style="display: flex; flex-direction: column; " [ngClass]="{'sidebar-slide-in': admin.sidebarShow}" >
        <!-- <button class="sidebar-close" (click)="admin.sidebarShow = !admin.sidebarShow" (mouseover)="admin.sidebarShow = true" (mouseout)="admin.sidebarShow = false">x</button> -->
        <!-- <button class="sidebar-close" (click)="admin.sidebarShow = !admin.sidebarShow" >x</button> -->
        <div class="w-100 d-flex sidebar-closed " style="padding: 6.9% 9%;  font-size: 2.1vh; height:87px; position: relative; justify-content: center;" (click)="admin.sidebarShow = !admin.sidebarShow"  >
            <img style="width: 18%; cursor: pointer;" src="../../../assets/img/logo-white.png" [ngClass]="{'sidebar-closed': !admin.sidebarShow, 'my-auto': admin.sidebarShow }" [appTooltip]="0">
            <div class="w-100 my-auto text-center" *ngIf="admin.sidebarShow" [appTooltip]="1">
                Georgetown ALTEA
            </div>
        </div>
        <div style="display: flex; flex-direction: column; height: calc(100% - 9%); position: relative; overflow-y: scroll;" >
            <div style="flex-direction: column; font-weight: 400;" class="d-flex">
                <div class="w-100 d-flex  align-items-center" style="font-size: 3vh; margin-top: 2%; padding: 1.2% 9%;justify-content: center;" *ngIf="auth.role == 'clientAdmin' && organization != undefined">
                    <!-- <img [ngStyle]="{'width': admin.sidebarShow ? '12%':'6%'}"  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="../../../assets/img/CICYTEX.png">        -->
                    <img *ngIf="organization.logo != null" [ngStyle]="{'width': admin.sidebarShow ? '15%':'6%'}"  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="{{organization.logo}}" [appTooltip]="3">
                    <div *ngIf="admin.sidebarShow" >
                        <div class="d-flex" [appTooltip]="4">
                            {{organization.organization_name}}
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex  align-items-center" style="font-size: 2.4vh; padding: 1.2% 9%; justify-content: center; margin-bottom: 12%;" *ngIf="auth.role == 'clientAdmin' && organization != undefined">
                    <div *ngIf="admin.sidebarShow" class="d-flex" style="justify-content: center;" [appTooltip]="5">
                        <img *ngIf="organization.image_url != null" [ngStyle]="{'width': admin.sidebarShow ? '':'6%'}" style="background-color: white; justify-content: center; background-color: color;" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="{{organization.image_url}}">
                    </div>
                </div>
                <div class="w-100 d-flex align-items-center" style="margin-top: 2%; padding: 1.2% 9%; font-size: 1.8vh; height: 30px;" [appTooltip]="6">
                    <!-- <i class="fe-icon-home my-auto letterColor" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                    <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}"  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACvElEQVRoge2YP2gUQRTGv0liokgCBiwixkIwWmijRbQxCaQQFNRSIhJBxZQWoqWlUVQQsVFioV1SxUZU8q+IgqZQizQqRrQIGhAEkRD9WWQ3jnOz3l12cnvo/mBhb+bN+763zO2+XSknJydTgD6gL2sfqQDO8ZuLWfspG8AAVyjkKmAS1pwFHkXHsUp79hmqBW55ioi5C6zyrLtjxVwoV7cmjP0lMw2ShiSdsIafRUfMUUmDwOqQ2sEKAdZKGpZ0yBoek9QtqUvSQ2v8oKQHQFMo/SAA64BJZwsNA2usmHpg0Il5DqyP5lNtrRBFtAAvPf+DOk9sLXDbiZ0GWjMtBNgCvHOMXSPhzhStMVGMzQwwkUkhwHbgo2Oo5GcFfz5jXCpTCNAOzFnCP4Ezy8jTB/zIpBBgP/DNEp0HelLk64ly2FwK6dknesQR/Q4cDpDXvTgLwPEQnn1i7jb4CnQHzL8X+JJ2uxYTcf+Yc0B7UJFFnZ3ArKOVvtlk8VZ52Uk8A2wN4DtJczPwxtG8ASyvCyH54bUpsHef9gbglaN9D0+zWSxRAzDkJJoiaicqAdAMPHU83Mdqe4olaARGnATjZNDgAU2Rts0I0FhsYTPwhEJ6K+Td56nX42ep2YypsRa0aLHt3l1ps8tgl6QJoDUesO8ENyXtsH7Pl5MZ6PRcuWJ0llmA7WmbpOu+Qk5Kmo7O+yVNlilSCSYlnY/OX0s6HU8svTMYYz4D+yR1G2MGgNHKeiwNY0w/8EnSY2PMbDxe5wS9lzQQQO+DpI6EuXFJG9MkN8YUeCx4iwvEgjHmrW8CWFgJwaBfUbIkL6TayAupNvJCqo28kGrjnymk1BalA6gvEtNmnTcCpxLi7Le7A0BbQlzMnqLuJP3tY/OopHLfF1aaMWNMl2/iv9haU5JWpFNNwYusDeTk5OSE4ReyUNnYlGzFagAAAABJRU5ErkJggg==">
                    <div *ngIf="admin.sidebarShow && auth.role != 'clientAdmin' && organization == undefined" class="d-flex" style="margin-left: 9%; align-items: center;" >
                        Pamplona
                    </div>
                    <div *ngIf="admin.sidebarShow && auth.role == 'clientAdmin' && organization != undefined" class="d-flex" style="margin-left: 9%; align-items: center;" >
                        {{organization.office_name}}
                    </div>
                </div>
                <div class="w-100 d-flex" style="font-size: 1.8vh; margin-top: 2%; padding: 1.2% 9%; height: 51px;"  [ngStyle]="{'cursor': userView == true ? 'pointer' : '' }" (click)="changeUserView();">
                    <i  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" *ngIf="userView == false" [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}"> {{auth.userData.name[0]}}{{auth.userData.last_name[0]}}</i>
                    <i  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" *ngIf="userView == true"  [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" > {{user_view_name[0]}}</i>
                    <div *ngIf="admin.sidebarShow && userView == false" class="d-flex" style="margin-left: 9%; flex-direction: column;">
                        <div style="font-size: 1.5vh;  align-items: center;" class="d-flex" [appTooltip]="7">
                            {{auth.userData.name}} {{auth.userData.last_name}}
                         </div>
                         <div style="font-size: 1.5vh; align-items: center;" class="d-flex" [appTooltip]="8">
                             {{auth.role | titlecase}}
                         </div>
                    </div>
                    <div *ngIf="admin.sidebarShow && userView == true" class="d-flex" style="margin-left: 9%; flex-direction: column;"  >
                        <div style="font-size: 1.5vh;  align-items: center;" class="d-flex" [appTooltip]="7">
                            {{user_view_name}}
                         </div>
                         <div style="font-size: 1.8vh" [appTooltip]="8">
                            {{auth.role | titlecase}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="filterBox" style="height: 27%;" *ngIf="auth.role == 'clientAdmin'">
                <div *ngIf="organization != undefined" style="width: 78%; height: 100%; background-repeat: no-repeat; background-size: cover; margin: auto;" [ngStyle]="{'background-image': organization.picture != undefined ? organization.picture : null}"></div>
              </div> -->
            <div style="flex-direction: column; font-weight: 400; margin: 1.5% 0;" class="d-flex" *ngIf="auth.role != 'clientAdmin'" >
                <!-- <div *ngIf="auth.role != 'clientAdmin'" > -->
                    <!-- <div style="font-size: 1.8vh;" > -->
                        <div style="font-weight: 500; width: 82%; padding: 0% 9%; font-size: 1.5vh; cursor: pointer; color: grey; margin: 1.5% 0"  (click)="menuShow = !menuShow" [appTooltip]="9">
                            Menu
                        </div>
                        <div *ngIf="!admin.sidebarShow && !menuShow" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" [appTooltip]="9">
                            ...
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('assistant');" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="17">
                          <i  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" style="color: limegreen;" class="fa-solid fa-circle"></i>
                            <div class="w-100" style="margin: 0 9%" >
                              {{this.usersConnected.length}} Connected
                            </div>
                        </div>

                        <div class="d-flex" style="flex-direction: column; " *ngIf="menuShow == true" [ngStyle]="{'transition': !menuShow ? 'top 1.2s ease-out' : ''}">
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" [ngClass]="{'menuSelected': auth.workingArea == 'group admin'}" [appTooltip]="10">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABJUlEQVRYhe2WQU7DMBBFvxG7rom4Q6VKbDkE4hrtAq7AkoN02Wv0AngBR+ACLKvXzUQ11Eltx0mE1C9FVqyZ/3/GM5GlKwoBvALf9rxMLf7MOZ5KuFyHAIn5j8axTxJz7kxvkIGWMDc+xG1qQisS/YoLcX0Gby65Hhu9FYghoz+S8H8qEDv7Gpi9AqMYABpgPYSA3GYD1ibcAN4oNkV8fxMiv13CGGBjrz4Q90AzlYE74CPY/gTuu/iyDSTmtCZ+iU9mwPKatuyzGCjhqzqGwCJchxCV9MCbnf/KeuG9mK9gChacxu9gqw8qMq4Bi1kG4gdgWa0CCfHFFajShM65H0lbSV+SHiR5STvbz0d2yU550SkY/QiG8PVeSGqa6MLsF5IrZscRZRk8lFSnnI4AAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" (click)="changeWorkingArea('group admin');">
                                    Group Planner
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" *ngIf="auth.role == 'superadmin' || auth.role == 'admin' || auth.role == 'publisherDean' || auth.role == 'dean'" [ngClass]="{'menuSelected': auth.workingArea == 'course admin'}" [appTooltip]="11">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABJUlEQVRYhe2WQU7DMBBFvxG7rom4Q6VKbDkE4hrtAq7AkoN02Wv0AngBR+ACLKvXzUQ11Eltx0mE1C9FVqyZ/3/GM5GlKwoBvALf9rxMLf7MOZ5KuFyHAIn5j8axTxJz7kxvkIGWMDc+xG1qQisS/YoLcX0Gby65Hhu9FYghoz+S8H8qEDv7Gpi9AqMYABpgPYSA3GYD1ibcAN4oNkV8fxMiv13CGGBjrz4Q90AzlYE74CPY/gTuu/iyDSTmtCZ+iU9mwPKatuyzGCjhqzqGwCJchxCV9MCbnf/KeuG9mK9gChacxu9gqw8qMq4Bi1kG4gdgWa0CCfHFFajShM65H0lbSV+SHiR5STvbz0d2yU550SkY/QiG8PVeSGqa6MLsF5IrZscRZRk8lFSnnI4AAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" (click)="changeWorkingArea('course admin');">
                                    Course Planner
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" *ngIf="auth.role == 'superadmin'"  [ngClass]="{'menuSelected': auth.workingArea == 'new organization'}" (click)="changeWorkingArea('new organization'); admin.edit_group = false" [appTooltip]="12">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAb0lEQVRYhe2XSwoAIAhEM7r/lW0VlDTQh1Bi3lIJa3qLSok4I7agqvp0oMgwM78ctkJBDbvTW1CycRNo2J23ZHbriPgJoBPs1hHxE6ADdIAO0AE6QAdmd4peN7Pevw70vf7U/zhw8j84WeOeAHGnAvVPUHzPs+BfAAAAAElFTkSuQmCC">
                                <div class="w-100" style="margin: 0 9%" >
                                    Organizations
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('surveys');" [ngClass]="{'menuSelected': auth.workingArea == 'surveys'}" *ngIf="auth.role == 'superadmin'" [appTooltip]="13">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="https://img.icons8.com/external-outline-juicy-fish/60/FFFFFF/external-online-side-hustles-outline-outline-juicy-fish.png">
                                <div class="w-100" style="margin: 0 9%" >
                                    Surveys
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" *ngIf="auth.role == 'superadmin' || auth.role == 'admin' || auth.role == 'publisherDean'" [ngClass]="{'menuSelected': auth.workingArea == 'new group'}" (click)="changeWorkingArea('new group'); admin.edit_group = false" [appTooltip]="14">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACRUlEQVRYhe2WT2sTQRjGZ1M81cQiglURpI1nG1GQag9GC4p69KQfI5ZC66kKfgP10g/Q6kUQEYzEHtWbKCh49NAa/2AqCGp+HvKsmazvTjZLvOWBYXbneZ/3fWZ2dnadG2GEEVIAXATqwDbQ0vWF/6VLJrlBOlaGrbNmAPADqAH71Goaw5pRXp1l4KmCawa3IK4+LJ1loKXgSYObFPdtWLqC4eGn+sjgxhIxPXUy6NpZDLxQf9XgriRifDzPqesFMA+0tXGuAfvVFjTWBuYN3XlvE1o6gHN9DSjZogol0QYWA7r7hibGvUzFlegk8NJI8go4bcSXgWeB4jEawHSo8CHgcYZET4CD0swBXzXeBG4Cx4HdwDhQAZaBTcV8AWat4ieUAAUvATNKsgs4CqwAW4rZAi57xR8BE4HJFYE1xX4Gyj45DXwSuQYUA4n2AA8SK/IQGEvEAZAYi4B1UQ2faHjFrfc4aWIH3Uf1EdhrxPxjwFuJ+HFUHVD1lj115kai29LdSuFNA+KWRa86YFU3S1mLK8kb6Y7lMDAj+q0D3unmyIAG4rN/wi/YD4ot6vZ7wTl3QDnfD2LAw+8cmnif/SpEUTQedbA9YJIP6qeccy5K4G8le3wqzmF9jLJiQ/2lHNpYsxGMCgE4G3p7Aq9hie5hVs1tQMlSzw/LAL0HUf+/owwGDtM9QdeBUiC25BVvEvooDWhizjOxSeeQqQA71SrAdW/Zm8CpoRT3TJTp/pSGUB/azFOMnAHu0jklW2qvgTtpG+4P0yiaVjaX0KIAAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" >
                                    New Group
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" *ngIf="auth.role == 'superadmin' || auth.role == 'admin'" [ngClass]="{'menuSelected': auth.workingArea == 'role admin'}" (click)="changeWorkingArea('role admin');" [appTooltip]="15">
                                <img  [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAhklEQVRYhe2WQRKAIAhFpfH+V6ZNtRBSQGb+Qt4aCR+GtlacDnkXMDNPExK5cl7eArLp0YXjTldm/oAbgBcQbkFU+QjcwBb8sJMDbqAKUMdm1gkXH1PGNNzAdA6sLpbXlDVOA26gCvh6Z31oZMfBDYi/wPrQyIqDG4AXIFpgHcNZcXADRXEDnFhEHm3+elYAAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" >
                                    Roles
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('test config');" [ngClass]="{'menuSelected': auth.workingArea == 'test config'}" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="16">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABJUlEQVRYhe2WQU7DMBBFvxG7rom4Q6VKbDkE4hrtAq7AkoN02Wv0AngBR+ACLKvXzUQ11Eltx0mE1C9FVqyZ/3/GM5GlKwoBvALf9rxMLf7MOZ5KuFyHAIn5j8axTxJz7kxvkIGWMDc+xG1qQisS/YoLcX0Gby65Hhu9FYghoz+S8H8qEDv7Gpi9AqMYABpgPYSA3GYD1ibcAN4oNkV8fxMiv13CGGBjrz4Q90AzlYE74CPY/gTuu/iyDSTmtCZ+iU9mwPKatuyzGCjhqzqGwCJchxCV9MCbnf/KeuG9mK9gChacxu9gqw8qMq4Bi1kG4gdgWa0CCfHFFajShM65H0lbSV+SHiR5STvbz0d2yU550SkY/QiG8PVeSGqa6MLsF5IrZscRZRk8lFSnnI4AAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" >
                                    Test Config
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('importer');" [ngClass]="{'menuSelected': auth.workingArea == 'importer'}" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="17">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABTElEQVRYhe2WTUoDQRBGv5Yk3kBF8Ar+ZuvKWylZ6g08RTSSRcBDiCuJiOA6GKN7XTw3BTZh2kz/jAqmYJiZorvem+qeYaRl/NUgLcbAxm8KADwAm8UEEoXzJTIE7r1z+nJkCKwBd9mdSBWw63yJzD0wH+PQPPddQUlyzgXHVI0PggJ1WnWK14kQYJHYSimB1FgK/B8BoAX0gCc7ekD4JYj9DiyqA/Qrvg8nPykA8Ax0gUO7f2x0CYC2dzuVdOScu5H0UWdyVgeAttf2KbBt+X1gZvnjRgQMfuHBdyrg/UY2ocEHNv0VOLD8LvBi+cu55SkjUAyeImDwK5v2BnST4bECQKcoPEbA4EMbPgP2LO9vuEEU3BeIiNCTj4DVKHiiwGkxeKTotcHOgTO+2j4EOo3CTWBS0Y3a8Kx/QmBL0rqkiaRb7xg5597r1PgEWoiNEuKnhe4AAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" >
                                    Importer
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('contacts-organizations');" [ngClass]="{'menuSelected': auth.workingArea == 'contacts-organizations'}" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="17">
                              <!-- <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTE5MiAwYy00MS44IDAtNzcuNCAyNi43LTkwLjUgNjRMNjQgNjRDMjguNyA2NCAwIDkyLjcgMCAxMjhMMCA0NDhjMCAzNS4zIDI4LjcgNjQgNjQgNjRsMjU2IDBjMzUuMyAwIDY0LTI4LjcgNjQtNjRsMC0zMjBjMC0zNS4zLTI4LjctNjQtNjQtNjRsLTM3LjUgMEMyNjkuNCAyNi43IDIzMy44IDAgMTkyIDB6bTAgNjRhMzIgMzIgMCAxIDEgMCA2NCAzMiAzMiAwIDEgMSAwLTY0ek0xMDUuOCAyMjkuM2M3LjktMjIuMyAyOS4xLTM3LjMgNTIuOC0zNy4zbDU4LjMgMGMzNC45IDAgNjMuMSAyOC4zIDYzLjEgNjMuMWMwIDIyLjYtMTIuMSA0My41LTMxLjcgNTQuOEwyMTYgMzI4LjRjLS4yIDEzLTEwLjkgMjMuNi0yNCAyMy42Yy0xMy4zIDAtMjQtMTAuNy0yNC0yNGwwLTEzLjVjMC04LjYgNC42LTE2LjUgMTIuMS0yMC44bDQ0LjMtMjUuNGM0LjctMi43IDcuNi03LjcgNy42LTEzLjFjMC04LjQtNi44LTE1LjEtMTUuMS0xNS4xbC01OC4zIDBjLTMuNCAwLTYuNCAyLjEtNy41IDUuM2wtLjQgMS4yYy00LjQgMTIuNS0xOC4yIDE5LTMwLjYgMTQuNnMtMTktMTguMi0xNC42LTMwLjZsLjQtMS4yek0xNjAgNDE2YTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6Ii8+PC9zdmc+"> -->
                              <i [ngClass]="{'icons-sidebar': !admin.sidebarShow}" class="fa-solid fa-book"></i>
                              <div class="w-100" style="margin: 0 9%" >
                                Organizations TESLA
                              </div>
                          </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('contacts-users');" [ngClass]="{'menuSelected': auth.workingArea == 'contacts-users'}" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="17">
                              <!-- <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTE5MiAwYy00MS44IDAtNzcuNCAyNi43LTkwLjUgNjRMNjQgNjRDMjguNyA2NCAwIDkyLjcgMCAxMjhMMCA0NDhjMCAzNS4zIDI4LjcgNjQgNjQgNjRsMjU2IDBjMzUuMyAwIDY0LTI4LjcgNjQtNjRsMC0zMjBjMC0zNS4zLTI4LjctNjQtNjQtNjRsLTM3LjUgMEMyNjkuNCAyNi43IDIzMy44IDAgMTkyIDB6bTAgNjRhMzIgMzIgMCAxIDEgMCA2NCAzMiAzMiAwIDEgMSAwLTY0ek0xMDUuOCAyMjkuM2M3LjktMjIuMyAyOS4xLTM3LjMgNTIuOC0zNy4zbDU4LjMgMGMzNC45IDAgNjMuMSAyOC4zIDYzLjEgNjMuMWMwIDIyLjYtMTIuMSA0My41LTMxLjcgNTQuOEwyMTYgMzI4LjRjLS4yIDEzLTEwLjkgMjMuNi0yNCAyMy42Yy0xMy4zIDAtMjQtMTAuNy0yNC0yNGwwLTEzLjVjMC04LjYgNC42LTE2LjUgMTIuMS0yMC44bDQ0LjMtMjUuNGM0LjctMi43IDcuNi03LjcgNy42LTEzLjFjMC04LjQtNi44LTE1LjEtMTUuMS0xNS4xbC01OC4zIDBjLTMuNCAwLTYuNCAyLjEtNy41IDUuM2wtLjQgMS4yYy00LjQgMTIuNS0xOC4yIDE5LTMwLjYgMTQuNnMtMTktMTguMi0xNC42LTMwLjZsLjQtMS4yek0xNjAgNDE2YTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6Ii8+PC9zdmc+"> -->
                              <i [ngClass]="{'icons-sidebar': !admin.sidebarShow}" class="fa-solid fa-address-book"></i>
                              <div class="w-100" style="margin: 0 9%" >
                                Users TESLA
                              </div>
                          </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('assistant');" [ngClass]="{'menuSelected': auth.workingArea == 'assistant'}" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" [appTooltip]="17">
                              <i  [ngClass]="{'icons-sidebar': !admin.sidebarShow}" class="fa-solid fa-headset"></i>
                              <!-- <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTE5MiAwYy00MS44IDAtNzcuNCAyNi43LTkwLjUgNjRMNjQgNjRDMjguNyA2NCAwIDkyLjcgMCAxMjhMMCA0NDhjMCAzNS4zIDI4LjcgNjQgNjQgNjRsMjU2IDBjMzUuMyAwIDY0LTI4LjcgNjQtNjRsMC0zMjBjMC0zNS4zLTI4LjctNjQtNjQtNjRsLTM3LjUgMEMyNjkuNCAyNi43IDIzMy44IDAgMTkyIDB6bTAgNjRhMzIgMzIgMCAxIDEgMCA2NCAzMiAzMiAwIDEgMSAwLTY0ek0xMDUuOCAyMjkuM2M3LjktMjIuMyAyOS4xLTM3LjMgNTIuOC0zNy4zbDU4LjMgMGMzNC45IDAgNjMuMSAyOC4zIDYzLjEgNjMuMWMwIDIyLjYtMTIuMSA0My41LTMxLjcgNTQuOEwyMTYgMzI4LjRjLS4yIDEzLTEwLjkgMjMuNi0yNCAyMy42Yy0xMy4zIDAtMjQtMTAuNy0yNC0yNGwwLTEzLjVjMC04LjYgNC42LTE2LjUgMTIuMS0yMC44bDQ0LjMtMjUuNGM0LjctMi43IDcuNi03LjcgNy42LTEzLjFjMC04LjQtNi44LTE1LjEtMTUuMS0xNS4xbC01OC4zIDBjLTMuNCAwLTYuNCAyLjEtNy41IDUuM2wtLjQgMS4yYy00LjQgMTIuNS0xOC4yIDE5LTMwLjYgMTQuNnMtMTktMTguMi0xNC42LTMwLjZsLjQtMS4yek0xNjAgNDE2YTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6Ii8+PC9zdmc+"> -->
                                <div class="w-100" style="margin: 0 9%" >
                                    Asistencia TESLA
                                </div>
                            </div>

                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="changeWorkingArea('uploader');" [ngClass]="{'menuSelected': auth.workingArea == 'uploader'}" *ngIf="auth.role == 'superadmin' && (auth.userData.id == 1363 || auth.userData.id == 1362) " [appTooltip]="18">
                                <!-- <i class="my-auto fe-icon-type" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABTElEQVRYhe2WTUoDQRBGv5Yk3kBF8Ar+ZuvKWylZ6g08RTSSRcBDiCuJiOA6GKN7XTw3BTZh2kz/jAqmYJiZorvem+qeYaRl/NUgLcbAxm8KADwAm8UEEoXzJTIE7r1z+nJkCKwBd9mdSBWw63yJzD0wH+PQPPddQUlyzgXHVI0PggJ1WnWK14kQYJHYSimB1FgK/B8BoAX0gCc7ekD4JYj9DiyqA/Qrvg8nPykA8Ax0gUO7f2x0CYC2dzuVdOScu5H0UWdyVgeAttf2KbBt+X1gZvnjRgQMfuHBdyrg/UY2ocEHNv0VOLD8LvBi+cu55SkjUAyeImDwK5v2BnST4bECQKcoPEbA4EMbPgP2LO9vuEEU3BeIiNCTj4DVKHiiwGkxeKTotcHOgTO+2j4EOo3CTWBS0Y3a8Kx/QmBL0rqkiaRb7xg5597r1PgEWoiNEuKnhe4AAAAASUVORK5CYII=">
                                <div class="w-100" style="margin: 0 9%" >
                                    PDF
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="goToAltea();"  *ngIf="auth.role == 'superadmin'" [appTooltip]="19">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="https://img.icons8.com/windows/32/FFFFFF/edit--v1.png">
                                <div class="w-100" style="margin: 0 9%" >
                                  Course editor
                                </div>
                            </div>
                            <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" (click)="goToCanvas();"  *ngIf="auth.role == 'superadmin'">
                                <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="https://img.icons8.com/ios/50/FFFFFF/web.png">
                                <div class="w-100" style="margin: 0 9%" >
                                    Learning Path
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
            <div *ngIf="filters != undefined && auth.role != 'clientAdmin' && auth.role != 'teacher'" style="flex-direction: column; font-weight: 400; margin: 3% 0;" >
                <div style="padding: 0% 9%; margin: 1.5% 0; color: grey;" class="w-100 d-flex" >
                    <div style="font-weight: 500; width: 42%; font-size: 1.5vh; cursor: pointer;" (click)="filterByUserShow = !filterByUserShow" [appTooltip]="21">
                        Filter by User
                    </div>
                    <button *ngIf="(auth.role == 'superadmin' || auth.role == 'publisherDean') && user_view_role != undefined" style="margin-left: auto" class="applyButton" (click)="applyRoleView()" [ngClass]="{'alertApplyFilters': alertApplyFilters == true}" [appTooltip]="22"> User view </button>
                    <button class="applyButton" (click)="applyFilters()" *ngIf="admin.sidebarShow" [ngClass]="{'alertApplyFilters': alertApplyFilters == true}" [appTooltip]="23"> Apply </button>
                </div>
                <div *ngIf="!admin.sidebarShow && !filterByUserShow" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" [appTooltip]="20">
                    ...
                </div>
                <div *ngIf="filterByUserShow == true" class="d-flex" style="flex-direction: column;">
                    <div  class="search" style="border: none!important" *ngIf="!admin.sidebarShow">
                        <i class="fa-solid fa-magnifying-glass my-auto" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i>
                    </div>
                    <input id="area_search" class="search" type="text" placeholder="Search" (keyup)="searchFilters($event.target.value, 'user')" *ngIf="admin.sidebarShow" [appTooltip]="24">
                    <div *ngIf="filters != undefined && filters.openUser == true" #filtersArea (clickOutside)="filters.openUser = false;" [appTooltip]="25" style="z-index: 1000; margin: 0 9.3%; margin-top: 12%; width: 81%; font-size: 1.5vh; color: #6e6e6e !important; border: 1px solid grey;  height: max-content; overflow-y: scroll;" class="position-absolute bg-white" >
                        <div *ngFor="let types of filtersTypes; let i=index" >
                            <div *ngFor="let filter of filters[types]; let j=index" class="options" [ngStyle]="{'background-color': filter.selected == true ? '#eaf3f5': '' }" (click)="filtersGroups(types,j);">
                                <div *ngIf="filter.filter == true" style="padding: 3px 6px;">
                                    <span *ngIf="types == 'Dean' || types == 'Teacher'"> {{types}} </span>
                                    {{filter.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="w-100 d-flex" style="flex-direction: column; color: grey;">
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [appTooltip]="261" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACvElEQVRoge2YP2gUQRTGv0liokgCBiwixkIwWmijRbQxCaQQFNRSIhJBxZQWoqWlUVQQsVFioV1SxUZU8q+IgqZQizQqRrQIGhAEkRD9WWQ3jnOz3l12cnvo/mBhb+bN+763zO2+XSknJydTgD6gL2sfqQDO8ZuLWfspG8AAVyjkKmAS1pwFHkXHsUp79hmqBW55ioi5C6zyrLtjxVwoV7cmjP0lMw2ShiSdsIafRUfMUUmDwOqQ2sEKAdZKGpZ0yBoek9QtqUvSQ2v8oKQHQFMo/SAA64BJZwsNA2usmHpg0Il5DqyP5lNtrRBFtAAvPf+DOk9sLXDbiZ0GWjMtBNgCvHOMXSPhzhStMVGMzQwwkUkhwHbgo2Oo5GcFfz5jXCpTCNAOzFnCP4Ezy8jTB/zIpBBgP/DNEp0HelLk64ly2FwK6dknesQR/Q4cDpDXvTgLwPEQnn1i7jb4CnQHzL8X+JJ2uxYTcf+Yc0B7UJFFnZ3ArKOVvtlk8VZ52Uk8A2wN4DtJczPwxtG8ASyvCyH54bUpsHef9gbglaN9D0+zWSxRAzDkJJoiaicqAdAMPHU83Mdqe4olaARGnATjZNDgAU2Rts0I0FhsYTPwhEJ6K+Td56nX42ep2YypsRa0aLHt3l1ps8tgl6QJoDUesO8ENyXtsH7Pl5MZ6PRcuWJ0llmA7WmbpOu+Qk5Kmo7O+yVNlilSCSYlnY/OX0s6HU8svTMYYz4D+yR1G2MGgNHKeiwNY0w/8EnSY2PMbDxe5wS9lzQQQO+DpI6EuXFJG9MkN8YUeCx4iwvEgjHmrW8CWFgJwaBfUbIkL6TayAupNvJCqo28kGrjnymk1BalA6gvEtNmnTcCpxLi7Le7A0BbQlzMnqLuJP3tY/OopHLfF1aaMWNMl2/iv9haU5JWpFNNwYusDeTk5OSE4ReyUNnYlGzFagAAAABJRU5ErkJggg==">
                            <div class="searchBox d-flex">
                                <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Office', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden > Office </option>
                                    <option *ngFor="let office of filters.Office; let i = index"  [ngValue]="office" class="filterMenu color" >
                                            {{office.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="26">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAb0lEQVQ4jc2SQQqAMAwEE+kL+mbpm62X8aJQZGMrKjiXJmy6bErN3gAoQEVTgblnUIEcaBlYegYcZ1RHd6e28R2gmNmqzOVKwdD4SueId1ZKV0bu7qruvkFrqBK0/CiBEkcSSJ58rDBNpKeRoU/ZAHN65o3Sa+W6AAAAAElFTkSuQmCC">
                            <div class="searchBox d-flex" >
                               <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Organization', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Organization </option>
                                    <option *ngFor="let organization of filters.Organization; let i = index"  [ngValue]="organization"  class="filterMenu color">
                                            {{organization.name}}
                                    </option>
                            </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="27">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAB/UlEQVRoge3ZvWoUURjG8VnBj0oENYLBD8TGO1CxEBtbC5PcgWAtomgtQS2D3oBYCDZeQhJZLcQmBiublGohgkr82J+Fs3gy7ibHzTk7szBP9S7s+7zPnz07c85MUbRq1arVxAj38cPW+o6PeI3HuIoTdecvymCjqofnmMOOugBSaRUXagXY4ns7MYXTuIZF/BwA8hB7xpU/GmBI70HM42sF4iUO5Mg7KMTIAIHHNJ4MWFL5IVIABF438KvyS+RdTikBSr/LFYgHKXw3G5gUoPS8WVlO+a5OOQBK3/A/sSrXfSIjwGF8CexnU/qHg7IAlN7zgf1Sav/+kJwAU/7e7Ho4HtNXz55kgDqdzvuiKLr9j0VRXIzpawxAqWdBfS6moWkAL4L6VExD0wDeBfXRmIamAXwK6r0xDU0D+G81DWBfUH+OaWgawMmgXotpaBrAmaB+G9PQNIBLQb2c3H3MW4ljqWfkBrgX2C+m9u8PybWdnq5sp2dS+oeDkgOgg6eB9YpJOtDgto2anCMlZm081C+k8N1sYBKActncqoTvYneqrMMGbxsARyprHt5gf8qsw4aPDODPdf4uvlXCd8cSvgwRBYBdOISzuI5l/z7c7WEh+7KpBEulFZwfW/AAYH0boXtYwowaX3DciYRYxwe8wiNcEfmYpFWrVq0mS78BnMpyMzanu3wAAAAASUVORK5CYII=">
                            <div class="searchBox d-flex" >
                                <select class="filterSelection d-flex filterMenu" (change)="filtersGroups('Dean', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Dean </option>
                                    <option *ngFor="let dean of filters.Dean; let i = index"  [ngValue]="dean" class="filterMenu color">
                                            {{dean.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="28">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAAjUlEQVR4nO3UQQqAIABFwez+d7Z9SxUe0cw+eH3U6wIAAP5orH4455wnQ3aNMZb+5T4d8jUGqANqy2/ArvcbsnqHd/3+BBigDqgZoA6oGaAOqBmgDqgZoA6oGaAOqBmgDqgZoA6oGaAOqBmgDqgZoA6oGaAOqBmgDqgZoA6oGaAOqBmgDqgZoA4AAAAoPN9sCE9Rl8KPAAAAAElFTkSuQmCC">
                            <div class="searchBox d-flex" >
                                <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Teacher', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden>  Teacher </option>
                                    <option *ngFor="let teacher of filters.Teacher; let i = index"  [ngValue]="teacher" class="filterMenu color">
                                            {{teacher.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text" >
                            <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i>
                            <div class="searchBox d-flex" *ngIf="admin.sidebarShow">
                                <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Tutor', $event)">
                                    <option value="" disabled selected hidden> </option>
                                    <option *ngFor="let tutor of filters.Tutor; let i = index"  [ngValue]="tutor" class="filterMenu color" >
                                            {{tutor.name}}
                                    </option>
                                </select>
                            </div>
                        </div> -->
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="29">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAADnUlEQVR4nO2Z24tNURzHf2sMYiaSJoyUSxrlRQa5TnlBlCmXkhfUlEeDB8mDSfg3eCDGSCl5lHJ7cIkXg1xDcqkZJjOmTB8Pe586fmcdzt77d84eWZ86dX77tL+/72+ttddeax2RQCAQCAQCgcD/iatlMmCxiLSLSJuITBeRWfFPb0Xkg4jcEJHLzrkHtfRVVQAHbAOeUDm9wFagph1kDjAHuJ2gcM0tYHbedaQCWAV8zFB8gS/A2rzrSQTQBgx7ivkBnAN2AC1AQ/xpia+dL3PfMLAm77oqgmjYf/YU0U0Fwzm+/4Ln/k+V3J8rRBOefuZ/AvtSaHXG9xZzsxq+zQC2e3oucfFFegc8elssPZsR975+1XUb6PYozccWfs0BWpXRYWCuge5sosmzmEUWnkVE6qyERGSzii85515mFXXOvRaRy+pye1bdApYN0KZibToLWkvnSo1lA8xU8T1D7ft/yZU/wIB6ThsNtRuV9oCVtuUIqDfU0mifY6olnIV+FTcbas9QcZ+VsGUDPFVxq6H2EhU/sRK2bIC7KjZ7VUnpK9ZygrUBWO1ZCM0z0J3jWQgtt/BsClDnWQpfMNC9qJfCjNaTImA3pXRm0Dvo0dtl6dmUeBTcUYZ/AvtTaB2gdDt8C7Cct+whOt3p9/RcDxVsjoC5nmEP0AfMr0UNmQHWUzpxQTQxdgM7gQVEK7zG+PvO+DffkdgQsC7vuhIRN8JXTzFJ+frPFV8AWFGmRytlGFiRdx2JASYDXdiNgC5gUt51VQSwFnhrULjmA7Ah7/rKQvQKPAmMlCmgHzgDdABLgSZgbPxpiq91AGcpP3JGgBOMtoUQMAY4XcZ0L9ECaUICvQnAHsr/n3gKMNsSZyLu+W6PyUGis/3U5wRAPdGCaNCjf47RsCgCjnnMvQeWGeZYBLzx5DlqlSOtsU2UPvMPAMvDkEKu5li7mBFgo3WuSg01xj1dzLtqFF+Uswl45slpdv6YxMxJZWTIctj/Ie9C4LvKfbzaebWJGXHBxaTe9qbIf8TT+NNqlV+Aw8pALxlm+xT5GzyP36FaJa8DXqjku2uS/Hcfe5WH59RigUTpn6D9JFjkGPqYCHxTXhYn1UmzkFil4ivOuaEUOplwzg2KyFV1WXv7KxYNcD2FhhXXVLwyqUCaBlig4ocpNKx4pOKWpAJpGmCKil+n0LDilYqnVj0jpRuTcVVPWt7LeOVlMC8vgUAgEAgEAoF/jV//s3azwMmw4AAAAABJRU5ErkJggg==">
                            <div class="searchBox d-flex">
                               <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Users', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> User </option>
                                    <option *ngFor="let user of filters.Users; let i = index"  [ngValue]="user">
                                            {{user.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="filters != undefined && auth.role != 'clientAdmin'" style="flex-direction: column; font-weight: 400; margin: 3% 0;" >
                <div style="padding: 0% 9%; margin: 1.5% 0; color: grey;" class="w-100 d-flex">
                    <div style="font-weight: 500; width: 82%; font-size: 1.5vh; cursor: pointer;" (click)="filterByContentShow = !filterByContentShow" [appTooltip]="30">
                        Filter by Content
                    </div>
                    <button class="applyButton" (click)="sentURLPlacement()" *ngIf="admin.sidebarShow" [ngClass]="{'alertApplyFilters': alertApplyFilters == true}"  [appTooltip]="31"> URL </button>
                    <button class="applyButton" (click)="applyFilters()" *ngIf="admin.sidebarShow" [ngClass]="{'alertApplyFilters': alertApplyFilters == true}"  [appTooltip]="32"> Apply </button>
                </div>
                <div *ngIf="!admin.sidebarShow && !filterByContentShow" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"  [appTooltip]="30">
                    ...
                </div>
                <div *ngIf="filterByContentShow == true"  class="d-flex" style="flex-direction: column;">
                    <div  class="search" style="border: none!important" *ngIf="!admin.sidebarShow">
                        <i class="fa-solid fa-magnifying-glass my-auto" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i>
                    </div>
                    <input id="area_search" class="search" type="text" placeholder="Search" (keyup)="searchFilters($event.target.value, 'content')" *ngIf="admin.sidebarShow"  [appTooltip]="33">
                    <div *ngIf="filters != undefined && filters.openContent == true" #filtersArea (clickOutside)="filters.openUser = false;" style="z-index: 1000; margin: 0 9.3%; margin-top: 12%; width: 81%; font-size: 1.5vh; color: #6e6e6e !important; border: 1px solid grey;  height: max-content; overflow-y: scroll;" class="position-absolute bg-white"  [appTooltip]="34">
                        <div *ngFor="let types of filtersTypes; let i=index" >
                            <div *ngFor="let filter of filters[types]; let j=index" class="options" [ngStyle]="{'background-color': filter.selected == true ? '#eaf3f5': '' }" (click)="filtersGroups(types,j);">
                                <div *ngIf="filter.filter == true" style="padding: 3px 6px;">
                                    <span *ngIf="types == 'Dean' || types == 'Teacher'"> {{types}} </span>
                                    {{filter.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="w-100 d-flex" style="flex-direction: column; color: grey;">
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="35">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAYElEQVRYhe2VuwoAIQwEjfj/v5xrLA4u4gPDFjfTJxkWVkuBv2MZS93dw2Nmn3s1Q2CHVAHryARWQEAu0E6Gdmo2Q54AAgjIBcIa3qzZDHkCCCCAwNF3vMroPXkjTwDgASiDEDq5eSAKAAAAAElFTkSuQmCC">
                            <div class="searchBox d-flex">
                                <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Level', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Level </option>
                                    <option *ngFor="let level of filters.Level; let i = index"  [ngValue]="level" class="filterMenu color">
                                            {{level.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="36">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAxElEQVRYhe2UQQ7CIBBFP8ZFz9CV99JlD9d7eBkP4P53Q+KIiDMTCbPgJSSEeaTDhxSYTAaTaoskqdqcUvL4kpNmY0/OrWKtY+D7ia0+ECCB4Q00rwDQPzCvHz+B2sNqndLqqxIguZBcNK7V117BDcBV24DDf4cvLiR3ko889rxGGavVVzeQ55v4wFbWPb7k5yME8ARwF/N/+58UJ1pJpjzWsu7xTQ1o6lZfEv9HZIrO4cdPIMSvuCfNBHrfPxAggclkOAfalyrlyWzm1QAAAABJRU5ErkJggg==">
                            <div class="searchBox d-flex" >
                               <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Subject', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Subject </option>
                                    <option *ngFor="let subject of filters.Subject; let i = index"  [ngValue]="subject"  class="filterMenu color">
                                            {{subject.name}}
                                    </option>
                            </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="37">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABAklEQVQ4jb3Svy6DcRTG8V9VJQarLtLFIiaJLmoxcgluQO/D7E8sxExdR0dLL0AlZehYJAiJDh9DT5P29XpFIp7lPO85Od+c33lPSn8tbKKFO7xHbKHxU2MZJ+jjFadGOovvPo5R/g5whDa2cYNKACroRr6Nw7zmut+pngVcohm+M34vRGygE76JiyzgFkvhH1DNAKoYhK+hO+4tRfItpTRfuOVpDUul0lx2glr4QXaCIs1EvE4p7YS/TyktZ554hQNsYfYLpegvRH1vIvWI/TzIYd4dRG0BzwH4wGoeoGx0TFOXOFE/xxNe0MNK7lKwEXfRw3Aiv45drMWki0XL/T99Ard6nXbGaAJ9AAAAAElFTkSuQmCC">
                            <div class="searchBox d-flex" >
                                <select class="filterSelection d-flex filterMenu" (change)="filtersGroups('Mode', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Mode </option>
                                    <option *ngFor="let mode of filters.Mode; let i = index"  [ngValue]="mode" class="filterMenu color">
                                            {{mode.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="menuOptions d-flex w-100 mx-auto set-sidebar-text"  [appTooltip]="38">
                            <!-- <i class="my-auto fe-icon-users" [ngClass]="{'icons-sidebar': !admin.sidebarShow}"></i> -->
                            <img style="width: 9%;" [ngStyle]="{'width': admin.sidebarShow ? '9%':'6%'}" [ngClass]="{'icons-sidebar': !admin.sidebarShow}" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAYElEQVRYhe2WwQoAIAhDM/r/X7Z7SSgiI9k7RjAcczgGIWDEelRVLRETufRmhVCEFflsTeDh5ehfDlRkA+4AYQ+EtsDLOWmfHvASyRDcAcIeSG+B50bo3QPZvMAdIATOBqEHJCKIeWYRAAAAAElFTkSuQmCC">
                            <div class="searchBox d-flex">
                               <select  class="filterSelection d-flex filterMenu" (change)="filtersGroups('Status', $event)" *ngIf="admin.sidebarShow">
                                    <option value="" disabled selected hidden> Status </option>
                                    <option *ngFor="let status of filters.Status; let i = index"  [ngValue]="status" class="filterMenu color">
                                            {{status.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: auto; margin-bottom: 6%;"  [appTooltip]="39">
                <button *ngIf="isAuth()" style="line-height: normal; font-weight: 500; text-transform: capitalize; font-size: 1.5vh;" class="h-100 w-100 btn d-flex btn-style-6 py-2 formBtn color letterColor" type="button" (click)="logOut()">
                    <span class="mx-auto my-auto">Cerrar sesión</span>
                </button>
            </div>
        </div>
        <!-- <div class="sidebar-content" > -->
            <!-- <h2 class="set-align"></h2>
            <div class="w-100">
                <div style="height: 12%; padding: 3% 9%; cursor: pointer; font-size: 1.8vh; color: #676d7d" (click)="menuShow = !menuShow">
                    Menu
                </div>
                <div class="w-100" style="flex-direction: column;" *ngIf="menuShow == true" [ngClass]="{'slideUp': !menuShow }">

                </div>

            </div> -->
        <!-- </div> -->
   </div>
    <div class="h-100" style="width: 82%;">
        <router-outlet></router-outlet>
    </div>
    <!-- <div (click)="themeColor = !themeColor" [ngClass]="{'themeColors': !themeColor, 'themeColorsShow':themeColor}">
        <img *ngIf="!themeColor" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA+UlEQVQ4ja3SsS5EYRAF4I9FUNCJRKnSbmSj9QaS3ZCo8RD7KEoRhSh4Ai0iWrREvySrWAXFnl03dvdugpP8mflnzp07c+bnj5goydWwGf8SN8NIUyUF9rAcf3VUgSJmUcccqnhKF7X41eTq4Q6giWe08BBiJaeeWCucZu+jngYruM9fXtFJfAefOM19Bou4wxpeegXmcRK7hWnc4ir5DazjA+d4x25sHxW0sYADHBVyx9hPrh0umBwmxo/xSjFuhOtwahmhg4viCONE3I4dKWKxm+IaH9HwvcZGYgNrLOJXD6lMqENd1eFNdzMDqAwLBi0sxZ6li//HF0WXPMOOIOtrAAAAAElFTkSuQmCC">
        <div *ngIf="themeColor == true">
            <span style="color: grey; font-size: 1.2vh;">
                BACKGROUND COLOR
            </span>
            <div style="background-color: white; display: flex; flex-flow: wrap;">
                <div *ngFor="let color of colors; let i = index" (click)="admin.selectedColor = color" style="height: 30px; width: 30px; margin: 1.5%;" [ngStyle]="{'background-color': color}">

                </div>
            </div>
        </div>
        <div *ngIf="themeColor == true">
            <span style="color: grey;font-size: 1.2vh;">
                LETTER COLOR
            </span>
            <div style="background-color: white; display: flex; flex-flow: wrap;">
                <div *ngFor="let color of letterColors; let i = index" (click)="admin.letterColor = color" style="height: 30px; width: 30px; margin: 1.5%;" [ngStyle]="{'background-color': color}">

                </div>
            </div>
        </div>
    </div> -->
</div>
<div *ngIf="createTooltip == true"  class="tooltip_panel">
    <div style="height: 12%; justify-content: flex-end;" class="d-flex w-100" (click)="createTooltip = false">
        <i class="fas fa-times my-auto delete" style="font-size: 1.5vh; margin: 1.8%; cursor: pointer;"></i>
    </div>
    <div style="height: 63%; justify-content: center;" class="w-100 d-flex">
        <!-- <div style="width: 21%; flex-direction: column; padding: 3%; font-size: 1.5vh;" class="d-flex" >

            <div class="w-100 d-flex" style="margin: 3% 0;" *ngFor="let type of admin.toolTipTypes">
                <input type="checkbox" style="margin-right: 12%; cursor: pointer;" [(ngModel)]="type.checked"> {{type.name}}
            </div>
        </div> -->
        <div style="width: 78%; align-items: center;  padding: 3% 6%; height: 54%;" class="d-flex">
            <!-- <p style="margin-bottom: 0;"> {{admin.indexTooltip}} : {{admin.tooltipsText[admin.indexTooltip]}}  </p>  -->
            <div class="w-100 d-flex">
                {{admin.indexTooltip}} :  <input type="text" placeholder="Tooltip Message" [(ngModel)]="admin.tooltipsText[admin.indexTooltip]">
            </div>
        </div>
    </div>
    <div class="d-flex w-100" style="justify-content: center;">
        <div class="save_tooltip" (click)="setTooltipText();">
            Guardar Tooltip
        </div>
    </div>
 </div>
<app-tooltip *ngIf="admin.showTooltip && admin.tooltipIndex != -1" class="tooltip_popup" [style.left.px]="admin.tooltipCoord.x" [style.top.px]="admin.tooltipCoord.y" [ngClass]="{'tt': admin.tooltipPos.y == 1}"  [index]="admin.tooltipIndex"></app-tooltip>
<app-spinner></app-spinner>
