<div class="contact-card form-actions" *ngIf="this.modeContact == 'company'">
  <button (click)="toggleEditMode()" [class.editing]="isEditing">
    {{ isEditing ? 'Guardar' : 'Editar' }}
  </button>
</div>
<div class="contact-card" [class.is-editing]="isEditing" *ngIf="this.modeContact == 'company'">
  <!-- Botón para alternar modo de edición -->

  <!-- Información General -->
  <div class="contact-section">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h3>Cliente<h4 style="font-size: 0.8rem; font-weight: 400; margin: 0  !important;">Id: {{editableContact.id}}</h4></h3>
    <div (click)="openModalConversation(editableContact)" class="bubble">
    <i class="fa-solid fa-inbox"></i>
    </div>
    <div *ngIf="!isEditing" class="bubble">
      {{ editableContact.created_at | date:'dd-MM-yyyy' || '-' }}
    </div>
    </div>
    <div style="display: flex; flex-direction: row;">
    <div *ngIf="!isEditing" class="bubble">
    {{ editableContact.nombre_de_empresa || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.nombre_de_empresa" class="input-bubble" placeholder="Nombre de la Empresa" />
    </div>


    <div *ngIf="!isEditing" class="bubble">
     {{ editableContact.localidad || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.localidad" class="input-bubble" placeholder="Localidad" />
    </div>

  </div>

    <div *ngIf="!isEditing" class="bubble">
    {{ editableContact.actividad || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.actividad" class="input-bubble" placeholder="Actividad" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
       {{ editableContact.subsector || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.subsector" class="input-bubble" placeholder="Actividad" />
    </div>
    <div *ngIf="!isEditing" class="bubble">
      {{ editableContact.telefono || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.telefono" class="input-bubble" placeholder="Teléfono" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <a *ngIf="editableContact.email" [href]="'mailto:' + editableContact.email">{{ editableContact.email }}</a>
      <span *ngIf="!editableContact.email">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.email" class="input-bubble" placeholder="Email" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <a *ngIf="editableContact.sitio_web" [href]="editableContact.sitio_web" target="_blank">
        Ir a Web
      </a>
      <span *ngIf="!editableContact.sitio_web">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <input [(ngModel)]="editableContact.sitio_web" class="input-bubble" placeholder="Sitio Web" />
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h3>Contactos Personas <i (click)="openModalPeople()" class="fa-solid fa-pencil"></i></h3>

      <div class="select-container">
        <select [(ngModel)]="value" (change)="updateValue()">
          <option *ngFor="let option of options" [value]="option">{{ option }}%</option>
        </select>

      </div>
    </div>
    <!-- Modo de visualización (scrollable listado) -->
    <div *ngIf="!isEditing" class="bubble scrollable-list">
      <div style="display: flex; flex-direction: row;" *ngFor="let person of editableContact.persons">
        <div style="width: 20%;">
        {{ person.complete_name || '-' }} <br />
        </div>
        <div style="width: 35%;">
        {{ person.position || '-' }} <br />
        </div>
        <div style="width: 10%;">
        {{ person.authority || '-' }} <br />
        </div>
      <div style="width: 30%;">
        <a *ngIf="person.email" [href]="'mailto:' + person.email">{{ person.email }}</a>
        <span *ngIf="!person.email">-</span>
      </div>
      <div style="width: 5%;" (click)="openModalConversation(person)">
        <i class="fa-solid fa-inbox"></i>
      </div>
        <hr />
      </div>
    </div>



  </div>

  <!-- Localización -->
  <!-- <div class="contact-section">
    <h3>Localización</h3>
    <div *ngIf="!isEditing" class="bubble">
      <strong>Provincia:</strong> {{ editableContact.provincia || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Provincia:</strong>
      <input [(ngModel)]="editableContact.provincia" class="input-bubble" placeholder="Provincia" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Dirección:</strong> {{ editableContact.direccion || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Dirección:</strong>
      <input [(ngModel)]="editableContact.direccion" class="input-bubble" placeholder="Dirección" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Código Postal:</strong> {{ editableContact.codigo_postal || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Código Postal:</strong>
      <input [(ngModel)]="editableContact.codigo_postal" class="input-bubble" placeholder="Código Postal" />
    </div>
  </div> -->



  <!-- <div class="contact-section">
    <h3>Redes Sociales</h3>


    <div *ngIf="!isEditing" class="bubble">
      <strong>Facebook:</strong>
      <a *ngIf="editableContact.facebook" [href]="editableContact.facebook" target="_blank">
        {{ editableContact.facebook }}
      </a>
      <span *ngIf="!editableContact.facebook">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Facebook:</strong>
      <input [(ngModel)]="editableContact.facebook" class="input-bubble" placeholder="Facebook" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Instagram:</strong>
      <a *ngIf="editableContact.instagram" [href]="editableContact.instagram" target="_blank">
        {{ editableContact.instagram }}
      </a>
      <span *ngIf="!editableContact.instagram">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Instagram:</strong>
      <input [(ngModel)]="editableContact.instagram" class="input-bubble" placeholder="Instagram" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Twitter:</strong>
      <a *ngIf="editableContact.twitter" [href]="editableContact.twitter" target="_blank">
        {{ editableContact.twitter }}
      </a>
      <span *ngIf="!editableContact.twitter">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Twitter:</strong>
      <input [(ngModel)]="editableContact.twitter" class="input-bubble" placeholder="Twitter" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>YouTube:</strong>
      <a *ngIf="editableContact.youtube" [href]="editableContact.youtube" target="_blank">
        {{ editableContact.youtube }}
      </a>
      <span *ngIf="!editableContact.youtube">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>YouTube:</strong>
      <input [(ngModel)]="editableContact.youtube" class="input-bubble" placeholder="YouTube" />
    </div>
  </div> -->

  <div class="contact-section">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h3>Compras</h3>
      <span>Total</span>
    </div>
    <div class="select-container" style="display: flex; flex-direction: row; justify-content: space-between;">
      <!-- Selección de Subcategoría -->
      <h4 style="width: 30%; font-size: 0.6rem; text-align: center; font-weight: 300;">Subcategory</h4>

      <!-- Selección de Tipo -->
      <h4 style="width: 20%; font-size: 0.6rem; text-align: center; font-weight: 300;">Plan</h4>

      <!-- Fecha -->
      <h4 style="width: 20%; font-size: 0.6rem; text-align: center; font-weight: 300;">Plan Duration</h4>
      <h4 style="width: 10%; font-size: 0.6rem; text-align: center; font-weight: 300;">Nº Acounts</h4>

      <!-- Número -->
      <h4 style="width: 10%; font-size: 0.6rem; text-align: center; font-weight: 300;">Price/u</h4>

      <!-- Botón de Guardar -->
      <h4 style="font-size: 0.6rem; text-align: center; font-weight: 300;">Save</h4>
    </div>
    <div class="select-container" style="display: flex; flex-direction: row; justify-content: space-between;">
      <!-- Selección de Subcategoría -->
      <select style="width: 30%;" [(ngModel)]="formData.subcategory">
        <option *ngFor="let subcat of subcategories" [value]="subcat.id">{{subcat.name}}</option>
      </select>

      <!-- Selección de Tipo -->
      <select style="width: 20%;" [(ngModel)]="formData.type">
        <option value="Professional">Professional</option>
        <option value="Job Interview">Job Interview</option>
        <option value="Management">Management</option>
      </select>

      <!-- Fecha -->
      <select style="width: 20%;" [(ngModel)]="formData.typeDate">
        <option value="mensual">Mensual</option>
        <option value="anual">Anual</option>
      </select>
      <input style="width: 10%;" type="number" [(ngModel)]="formData.numDate">

      <!-- Número -->
      <input style="width: 10%;" type="number" [(ngModel)]="formData.price">

      <!-- Botón de Guardar -->
      <i class="fa-regular fa-floppy-disk" (click)="saveData()" style="cursor: pointer;"></i>
    </div>

    <div *ngFor="let product of this.products" class="select-container" style="display: flex; flex-direction: row; justify-content: space-between;">
      <span style="width: 30%;">{{product.subcategory}}</span>
      <span style="width: 20%;">{{product.type}}</span>
      <span style="width: 20%;">{{product.plan}}</span>
      <div style="width: 10%;"></div>
      <div style="width: 10%;">{{product.price}}€</div>
      <i class="fa-solid fa-xmark" (click)="deleteData(product)" style="cursor: pointer;"></i>



    </div>


  </div>


</div>



<div class="contact-card" [class.is-editing]="isEditing" *ngIf="this.modeContact == 'user'">

  <div class="contact-section">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h3>Usuario<h4 style="font-size: 0.8rem; font-weight: 400; margin: 0  !important;">Id: {{editableContact.id}}</h4></h3>
    <div (click)="openModalConversation(editableContact)" class="bubble">
    <i class="fa-solid fa-inbox"></i>
    </div>
    <div *ngIf="!isEditing" class="bubble">
      {{ editableContact.created_at | date:'dd-MM-yyyy' || '-' }}
    </div>
    </div>
    <div *ngIf="editableContact.picture != null" class="bubble">
    <img style="border-radius: 9px;" width="80px" height="80px" [src]="editableContact.picture">
    </div>
    <div style="display: flex; flex-direction: row;">
    <div *ngIf="!isEditing" class="bubble">
    {{ editableContact.name || '-' }}
    {{ editableContact.last_name || '-' }}

    </div>

    <div *ngIf="!isEditing" class="bubble">
     {{ editableContact.country || '-' }}
    </div>


  </div>

    <div *ngIf="!isEditing" class="bubble">
    {{ editableContact.organization || '-' }}
    </div>


    <div *ngIf="!isEditing" class="bubble">
       {{ editableContact.active_subscriptions_count || '-' }}
    </div>

    <div *ngIf="!isEditing" class="bubble">
      {{ editableContact.phone || '-' }}
    </div>


    <div *ngIf="!isEditing" class="bubble">
      <a *ngIf="editableContact.email" [href]="'mailto:' + editableContact.email">{{ editableContact.email }}</a>
      <span *ngIf="!editableContact.email">-</span>
    </div>



  </div>

  <div class="contact-section">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h3>Subscripciones</h3>
    </div>

    <table class="custom-table">
      <thead>
        <tr>
          <th>Subcat Name</th>
          <th>Plan</th>
          <th>Type</th>
          <th>Periodo Finalización</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sub of editableContact.active_subscriptions">
          <td>{{ sub.subcat.name }}</td>
          <td>{{ sub.plan_p }}</td>
          <td>{{ sub.type }}</td>
          <td>{{ sub.current_period_end * 1000 | date:'medium' }}</td>
        </tr>
      </tbody>
    </table>



  </div>


</div>
