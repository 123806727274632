

<div>

<button *ngIf="!this.modeMicroGap" (click)="checkText()">
Check
</button>

<button *ngIf="this.modeMicroGap" (click)="checkMicroText()">
Check Micro
</button>





<button *ngIf="saveButton" (click)="saveText()">
Save
</button>






<!-- Conectarlo a futuro con los planes -->
<label *ngIf="!this.modeMicroGap" for="plan">Plan:</label>
<select *ngIf="!this.modeMicroGap" id="plan" [(ngModel)]="selectedPlan">
  <option value="free">Default Plan</option>
  <option value="professional">Professional</option>
  <option value="management">Management</option>
</select>

<label *ngIf="!this.modeMicroGap" for="type">Type:</label>
<select *ngIf="!this.modeMicroGap" id="type" [(ngModel)]="selectedType">
  <option *ngFor="let type of readingTypes" [value]="type.type">{{type.type}}</option>
  <option>Chart</option>
  <option>Expertise</option>
</select>

<label *ngIf="!this.modeMicroGap" for="ngap">w/l</label>
<select *ngIf="!this.modeMicroGap" id="ngap" [(ngModel)]="selectedNgap">
  <option value=9>9</option>
  <option value=12>12</option>
  <option value=15>15</option>
</select>


<label style="cursor: pointer;" [ngClass]="{'microActivated': this.modeMicroGap}" (click)="changeMode()">CHANGE TO MICROGAP</label>



<button (click)="openGapModal()" *ngIf="this.wordsToColor">Ver Gapeo</button>



</div>
<ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)" (change)="onChange($event);" [(ngModel)]="EditorText"></ckeditor>
