import { Component, OnInit, ElementRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { roles } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { LmsService } from 'src/app/services/lms.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AdminService } from 'src/app/services/admin.service';
import { PusherService } from 'src/app/services/pusher.service';
// import * as $ from 'jquery';
declare var $: any;


@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('filtersArea') filtersArea: ElementRef;

  organization;
  office;
  dean;
  teacher;
  user;
  tutor;
  level;
  subject;
  mode;
  status;
  filters;
  indexFilters;
  arrayFilters = [];
  filtersTypes;
  arrayPlacementURL = new Array();
  rolesAux;
  alertApplyFilters: boolean = false;
  menuShow: boolean = true;
  filterByUserShow: boolean = true;
  filterByContentShow: boolean = true;
  searchOffice: boolean = false;
  searchOrganization: boolean = false;
  themeColor: boolean = false;
  colors=['#1C4360','blue', 'black', '#161d31', 'rgb(111, 168, 75)', '#f9d422']
  letterColors=['#1C4360','black', '#161d31', 'grey',  'white', '#3C5176'];
  user_view_role;
  user_view_name = '';
  view_role;
  userView: boolean = false;
  usersConnected = [];



  createTooltip: boolean = false;
  indexTooltip: number = -1;

  @HostListener('contextmenu', ['$event']) click() {
    if ((this.auth.userData.role_id == 2 || this.auth.userData.role_id == 6 || this.auth.userData.role_id == 8 || this.auth.userData.role_id == 9) && this.auth.userData.id != 1116 && this.auth.userData.id != 1362 ){
      if (this.createTooltip == false){
        this.createTooltip = true;
        this.admin.indexTooltip = this.admin.tooltipIndex;
        this.admin.getTooltipText();
      }
    }
  }

  constructor(public auth: AuthService, private router: Router, public lms: LmsService, private renderer: Renderer2, private userData: UserDataService, public admin: AdminService, public pusherService: PusherService) {
    this.rolesAux = roles;
  }

  ngOnInit(): void {
    if (this.isAuth() && this.userView == false) {
      this.auth.checkRole();
    }
    this.getToolTips();
    if (this.auth.userData.role_id == 2 || (this.auth.userData.role_id || this.admin.view_role) == 6 || this.auth.userData.role_id == 8 || (this.auth.userData.role_id || this.admin.view_role ) == 9 || (this.auth.userData.role_id || this.admin.view_role) == 3) {
      this.admin.plannerView = true;
      this.getFilters();
    }
    if (this.auth.userData.role_id == 13 || this.admin.view_role == 13) {
      this.admin.plannerView = false;
      this.admin.getOrganizationInfo(this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe(data => {
        this.organization = data;
        this.organization.picture = `url(` + data['image_url'] + `)`;
        this.organization.logo = data['logo_url'];
        this.organization.image_url = data['image_url'];
        this.admin.office_groups = this.organization.organization_name;
      });
    } else{
      this.admin.office_groups = 'Pamplona';
    }
    if (this.auth.userData.role_id == 8){
      this.filterByUserShow = false;
      this.filterByContentShow = false;
    }


    this.userData.getAllUsersConnected().subscribe((data: any) => {
      this.usersConnected = data;

      this.pusherService.getUserTrack$.subscribe((event: any) => {

        try{
          if(event.state == 'connected'){
            if (!this.usersConnected.some(user => user.id === event.user.id)) {

              let newUser = {
                id: event.user.id,
              }

              this.usersConnected.push(newUser);
            }
          }
          if(event.state == 'disconnected'){
            this.usersConnected = this.usersConnected.filter(user => user.id !== event.user.id);
          }



        }

        catch (error) {
        }

        })


    })







  }

  isAuth(): boolean {
    return this.auth.isAuthenticated();
  }

  logOut() {
    this.auth.logout();
    this.auth.isAuthenticated();
  }

  changeWorkingArea(workingArea) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (workingArea == 'group admin') {
      this.router.navigate(['/group-admin', 'group']);
    } if (workingArea == 'course admin') {
      this.router.navigate(['/group-admin', 'course']);
    } else if (workingArea == 'role admin') {
      this.router.navigate(['/role-admin']);
    } else if (workingArea == 'new organization') {
      this.router.navigate(['/new-organization']);
    } else if (workingArea == 'new group') {
      this.router.navigate(['/new-group']);
    } else if (workingArea == 'test config') {
      this.router.navigate(['/test-config']);
    } else if (workingArea == 'importer') {
      this.router.navigate(['/importer']);
    } else if (workingArea == 'surveys') {
      this.router.navigate(['/surveys']);
    } else if (workingArea == 'uploader') {
      this.router.navigate(['/uploader']);
    } else if (workingArea == 'assistant') {
      this.router.navigate(['/assistant']);
    } else if (workingArea == 'contacts-users') {
      this.router.navigate(['/contacts/user']);
    } else if (workingArea == 'contacts-organizations') {
      this.router.navigate(['/contacts/organization']);
    }

  }

  goToAltea(){
    const url = 'https://new.altealabs.com/#/?jwt=' + localStorage.getItem('access_token');
    // const url = 'http://localhost:4200/#/?jwt=' + localStorage.getItem('access_token');
    window.open(url, '_blank');
  }

  goToCanvas(){
    const url = 'https://canvas.altealabs.com/#/?jwt=' + localStorage.getItem('access_token');
    // const url = 'http://localhost:4200/#/?jwt=' + localStorage.getItem('access_token');
    window.open(url, '_blank');
  }

  getToolTips(){
    this.admin.getToolTips('admin').subscribe(data => {
      let tooltipsInfo = JSON.parse(JSON.stringify(data));
      tooltipsInfo.forEach( t => {
        this.admin.tooltipsText[t.number] = t.text;
      });
     });
  }

  setTooltipText(){
    let typesSelected = new Array();
    this.admin.toolTipTypes.forEach( typ => {
      if (typ.checked == true){
        typesSelected.push(typ.name)
      }
    })
    this.admin.setToolTip('admin', this.admin.indexTooltip, this.admin.tooltipsText[this.admin.indexTooltip], 'tooltip' ).subscribe(data => {
      this.createTooltip = false
     });
  }


  getFilters() {
    this.admin.getFiltersBack(this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
      this.indexFilters = Object.keys(data);
      this.filters = data;
    });
  }


  searchFilters(searchValue, filterBy) {

    searchValue = searchValue.toLowerCase();
    this.filtersTypes = Object.keys(this.filters);
    if (filterBy == 'user') {
      let filtersUser = [];
      for (let i = 0; i < 5; i++) {
        filtersUser.push(this.filtersTypes[i]);
      }
      this.filtersTypes = filtersUser;
    } else if ('content') {
      let filtersContent = [];
      for (let i = 5; i < this.filtersTypes.length; i++) {
        filtersContent.push(this.filtersTypes[i]);
      }
      this.filtersTypes = filtersContent;
    }

    if (searchValue.length > 2) {
      if (filterBy == 'user') {
        this.filters.openUser = true;
      } else if ('content') {
        this.filters.openContent = true;
      }
      for (let index = 0; index < this.filtersTypes.length; index++) {
        this.filters[this.filtersTypes[index]].forEach(type => {
          if (type.name.toLowerCase().indexOf(searchValue) != -1) {
            type.filter = true;
          } else {
            type.filter = false;
          }
        });
      }
    } else if (searchValue.length == 0) {
      this.filters.openUser = false;
      this.filters.openContent = false;
    }
  }

  showTestConfig() {
    $("#testConfig").modal('show');
    return;
  }

  filtersGroups(filter, event) {

    let index = event.target.selectedIndex - 1;
    this.filters[filter].map( filter => filter.selected = false);
    if (this.filters[filter][index].selected == false || this.filters[filter][index].selected == undefined) {
      this.filters[filter][index].selected = true;
    this.view_role = filter;
      this.alertApplyFilters = true;
      setTimeout(() => {
        this.alertApplyFilters = false;
      }, 1200);
    }
    // } else {
    //   this.filters[filter][index].selected = false;
    //   this.alertApplyFilters = true;
    //   setTimeout(() => {
    //     this.alertApplyFilters = false;
    //   }, 1200);
    // }
    if (filter == 'Organization' || filter == 'Dean' || filter == 'Teacher'  ){
      this.user_view_role = this.filters[filter][event.target.selectedIndex-1];
    }
    if (filter == 'Users') {
      this.filters[filter].forEach(type => {
        if (type.selected != undefined) type.selected = false;
      });
      if (this.filters[filter][index].name == 'Candidate') {
        this.filters[filter][index].selected = true
      } else {
        this.filters[filter][index].selected = true;
      }
    }
  }

  sentURLPlacement() {
    let filter;
    for (let index = 0; index < this.indexFilters.length; index++) {
      filter = this.indexFilters[index];
      if (filter == 'Level' || filter == 'Subject') {
        this.filters[filter].forEach((type, i) => {
          if (type.selected == true) {
            let arrayFilter = {
              filter: filter,
              name: this.filters[filter][i].name,
              course_id: this.filters[filter][i].course_id
            }
            this.arrayPlacementURL.push(arrayFilter);
          }
        });
      }
    }
    this.admin.generatePlacementURL(this.arrayPlacementURL).subscribe((data: any) => {
    });
  }

  applyFilters() {

    let filter;
    this.arrayFilters = [];
    for (let index = 0; index < this.indexFilters.length; index++) {
      filter = this.indexFilters[index];
      this.filters[filter].forEach((type, i) => {
        if (type.selected == true) {
          let arrayFilter = {
            filter: filter,
            name: this.filters[filter][i].name,
            id: this.filters[filter][i].id
          }
          this.arrayFilters.push(arrayFilter);
        }
      });
    }

    for (let i = 0; i < this.filters['Users'].length; i++) {
      if (this.filters['Users'][i].name == 'Candidate' && this.filters['Users'][i].selected == true) {
        this.lms.placement = true;
        break;
      } else {
        this.lms.placement = false;
        break;
      }
    }

    this.lms.arrayFilters = this.arrayFilters;
    this.userData.notifyExercise({ option: 'apply_filters' });
  }

  deleteFilters(){
    let filtersAux = Object.keys(this.filters);
    for (let i = 0; i < filtersAux.length; i++){
      this.filters[filtersAux[i]].map(filter =>{ filter.selected = false });
    }
    this.applyFilters();
  }


  applyRoleView(){
    this.userView = true;
    this.admin.userView =  this.userView
    if (this.view_role == 'Organization'){
      this.admin.view_role = 13;
      this.auth.role = 'clientAdmin'
    } else if (this.view_role == 'Teacher'){
      this.admin.view_role = 3;
      this.auth.role = 'teacher'
    } else if (this.view_role == 'Dean'){
      this.admin.view_role = 9;
      this.auth.role = 'publisherDean'
    }
    if (this.admin.view_role == 13){
      this.admin.organizationId_view = this.user_view_role.id;
    }else{
      this.admin.userId_view = this.user_view_role.id;
    }
    this.user_view_name = this.user_view_role.name;
    this.user_view_role = '';
    this.ngOnInit();
    this.userData.notifyExercise({ option: 'user_view' });

  }

  changeUserView(){
    if (this.userView == true){
      this.userView = false;
      this.admin.userView = this.userView;
      window.location.reload();
      // this.view_role = undefined;
      // this.admin.view_role = undefined;
      // this.admin.organizationId_view = undefined;
      // this.admin.userId_view = undefined;
      // this.auth.role = 'superadmin'
      // this.userData.notifyExercise({ option: 'user_view' });
    }
  }

}
