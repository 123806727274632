<div class="custom-modal-content" style="height: 100%;">
  <div class="custom-modal-header" style="height: 10%;">
    <div style="display: flex; flex-direction: row; ">
    <button type="submit" class="custom-button custom-submit-button" (click)="sendEmail()"><i class="fa-solid fa-paper-plane"></i></button>
    <select style="width: 300px;" (change)="onAccountSelected($event.target.value)">
      <option *ngFor="let account of accounts" [value]="account">
        {{ account }}
      </option>
    </select>

    <div style="margin-top: 10px;">
      <!-- Si no se está agregando una nueva categoría, mostramos el select -->
      <ng-container>
        <select style="width: 300px;" (change)="onCategoryChange($event.target.value)">
          <option value="" disabled selected>Selecciona una categoría</option>
          <option *ngFor="let category of categories" [value]="category">
            {{ category }}
          </option>
          <option value="new">Nuevo +</option>
        </select>
      </ng-container>
      <!-- Si se seleccionó "Nuevo +", se muestra un input para escribir la nueva categoría -->
      <ng-container *ngIf="isAddingNewCategory">
        <input type="text" [(ngModel)]="newCategory" placeholder="Ingrese nueva categoría"
          style="width: 300px;" (blur)="onNewCategoryBlur()" />
      </ng-container>
      <!-- <button (click)="selectExistent()">Existent</button> -->

      <button (click)="saveEmail()">Save</button>

    </div>

  </div>
    <button type="button" class="custom-close-button" (click)="onClose()">✖</button>
  </div>
  <div class="custom-modal-body" style="height: 90%; display: flex; flex-direction: row;">
    <div style="height: 100%; width: 30%; overflow-y: auto; padding: 5px; border-right: 1px solid #ccc;">
      <div *ngFor="let category of categories" style="margin-bottom: 15px;">
        <h5 style="margin: 0 0 5px 0; font-size: 14px;">{{ category }}</h5>
        <ul style="list-style: none; padding: 0; margin: 0;">
          <li *ngFor="let email of mailsGrouped[category]" style="margin-bottom: 3px;
          padding: 12px;
          font-size: 16px;
          background: #e7e7e7;
          border-radius: 12px;
          border: 1px solid #d0d0d0;
          cursor: pointer;" (click)="importMail(email)">
            {{ email.title }}
          </li>
        </ul>
      </div>
    </div>


    <form style="height: 100%; width: 90%;">

      <div class="custom-form-group" style="display: flex; flex-direction: row; height: 7%;">

        <input
          style="width: 20%;"
          type="text"
          id="uniqueEmailSubject"
          class="custom-input"
          [(ngModel)]="email.subject"
          name="uniqueSubject"
          placeholder="Asunto"
          required
        />
        <input
        type="email"
        id="uniqueEmailRecipient"
        class="custom-input"
        [(ngModel)]="email.recipient"
        (ngModelChange)="validateEmails($event)"
        name="uniqueRecipient"
        placeholder="Para: (separados por comas para múltiples)"
        required
      />
      <p *ngIf="invalidEmails.length > 0" class="error-message">
        Los siguientes correos son inválidos: {{ invalidEmails.join(', ') }}
      </p>

      </div>


      <!-- Editor Ngx -->
      <div class="custom-form-group" id="emailEditorContainer" style="height: 93%;">
        <label for="uniqueEmailContent">Contenido del correo</label>
        <email-editor
          #emailEditor
          [options]="options"
          (loaded)="editorLoaded($event)"
          (ready)="editorReady($event)"
          style="height: 100%;"
        ></email-editor>
      </div>

      <!-- Footer -->

    </form>


  </div>
</div>
