<div *ngIf="spinner" class="spinner-container">
  <div class="spinner"></div>
</div>



<div class="contacts-container" *ngIf="!spinner"
style="position: absolute; transition-duration: 600ms;
transition-timing-function: ease-in-out; width: 85%; padding: 1%;"
 [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">

 <div style="flex-direction: row; margin-bottom: 12px; " class="excel-upload-container" *ngIf="selectedMode == 'user'">

 <div style="display: flex; flex-direction: row; gap: 12px; margin-right: 12px;">


  <button class="excel-upload-btn" (click)="filterUserProspects('all')"
  [ngClass]="{'active-filter': prospectUserFilter === 'all'}">
  All
  </button>
  <button class="excel-upload-btn" (click)="filterUserProspects('users')"
  [ngClass]="{'active-filter': prospectUserFilter === 'users'}">
  Usuarios
  <div class="user" style="height: 10px; width: 10px; border-radius: 50%; margin-left: 6px;"></div>
  </button>
  <button class="excel-upload-btn" (click)="filterUserProspects('prospects')"
  [ngClass]="{'active-filter': prospectUserFilter === 'prospects'}">
  Prospectos
  <div class="prospect" style="height: 10px; width: 10px; border-radius: 50%; margin-left: 6px;"></div>
  </button>

</div>




    <div *ngIf="prospectUserFilter != 'prospects'" style="height: 10px; width: 1px; background-color: white; margin-right: 32px; margin-left: 32px; "></div>


      <div *ngIf="prospectUserFilter != 'prospects'" style="display: flex; flex-direction: row; gap: 12px; margin-right: 12px;">
        <button class="excel-upload-btn" (click)="filterUsers('all')"
          [ngClass]="{'active-filter': userFilterType === 'all'}">
          All
        </button>
        <button class="excel-upload-btn" (click)="filterUsers('buyers')"
          [ngClass]="{'active-filter': userFilterType === 'buyers'}">
          Buyers
        </button>
        <button class="excel-upload-btn" (click)="filterUsers('nonBuyers')"
          [ngClass]="{'active-filter': userFilterType === 'nonBuyers'}">
          Not Buyers
        </button>
      </div>




  <div *ngIf="prospectUserFilter != 'prospects'" style="height: 10px; width: 1px; background-color: white; margin-right: 32px; margin-left: 32px; "></div>



  <div *ngIf="prospectUserFilter != 'prospects'" style="display: flex; flex-direction: row; gap: 12px; margin-right: 12px;">
    <button class="excel-upload-btn" (click)="filterUsersRole('all')"
    [ngClass]="{'active-filter': roleFilterType === 'all'}">
    All
    </button>
    <button class="excel-upload-btn" (click)="filterUsersRole('alumnos')"
    [ngClass]="{'active-filter': roleFilterType === 'alumnos'}">
    Alumnos
    </button>
    <button class="excel-upload-btn" (click)="filterUsersRole('staff')"
    [ngClass]="{'active-filter': roleFilterType === 'staff'}">
    Staff
    </button>


</div>




  </div>

  <div style="flex-direction: row; margin-bottom: 12px;" class="excel-upload-container" *ngIf="this.selectedMode == 'user'">

    <button class="btn-importar-txt" (click)="activarSelectorArchivo()">
      <i class="fa-regular fa-file-excel"></i> Importar TXT
    </button>

    <!-- Input oculto para seleccionar el archivo .txt -->
    <input
      type="file"
      #archivoInput
      accept=".txt"
      style="display: none"
      (change)="archivoElegido($event)"
    />
    <div *ngIf="error" style="color: red; margin-top: 10px;">{{ error }}</div>
  </div>



  <div style="flex-direction: row; margin-bottom: 12px;" class="excel-upload-container" *ngIf="this.selectedMode == 'company'">
    <div style=" display: flex; flex-direction: row; gap: 12px; margin-right: 12px;" >
      <input
        type="text"
        placeholder="Selecciona Coleccion"
        [(ngModel)]="filterValueColeccion"
        (input)="elasticSearchColeccion($event.target.value)"
      />
      <!-- Resultados del Elastic Search -->
      <ul *ngIf="optionsColeccion.length > 0 && this.filterValueColeccion.length > 0" style="top: 60px; left: auto; position: fixed; width: 400px;">
        <li (click)="selectOptionColeccion('')" >Todas</li>
        <li *ngFor="let option of optionsColeccion" (click)="selectOptionColeccion(option)">
          {{ option }}
        </li>
      </ul>

      <!-- <button class="add-colection" (click)="seleccionarColeccion()">Seleccionar Coleccion</button> -->


    </div>
    <button class="excel-upload-btn" (click)="triggerFileInput()">
      <i class="fa-regular fa-file-excel"></i> Importar Excel
    </button>
    <button style="margin-left: 18px;" class="excel-upload-btn" (click)="exportarExcelVacio()">
      Exportar Campos
    </button>
    <input
      type="file"
      id="fileInput"
      (change)="onFileChange($event)"
      accept=".xlsx, .xls"
      hidden
    />
    <div *ngIf="error" style="color: red; margin-top: 10px;">{{ error }}</div>
  </div>





<div class="filter-search" style="display: flex; justify-content: space-between;">
  <div style="display: flex; flex-direction: row;">
    <div style="position: relative;">
      <button
        class="dropdown-btn"
        (click)="toggleDropdown()"
      >
        Campo
      </button>
      <div
        class="dropdown-men"
        *ngIf="isDropdownOpen"
        style="position: absolute; height: 300px;
        overflow: scroll;
        z-index: 10;
        width: 300px;
        background: white;
        border: 1px solid #ddd;
        padding: 10px; border-radius: 5px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); color: #444;"
      >
        <label
          *ngFor="let header of headersOficial"
          style="display: block; margin-bottom: 5px; cursor: pointer;"
        >
          <input
            style="height: 12px; width: 12px;"
            type="checkbox"
            [value]="header"
            (change)="toggleColumn(header, $event.target.checked)"
            [checked]="selectedColumns.includes(header)"
          />
          {{ header }}
        </label>
      </div>
    </div>
    <div style="position: relative; width: 200px;">
      <input
        type="text"
        placeholder="Valor del filtro"
        [(ngModel)]="filterValue"
        (input)="elasticSearch($event.target.value)"
      />
      <!-- Resultados del Elastic Search -->
      <ul *ngIf="options.length > 0">
        <li *ngFor="let option of options" (click)="selectOption(option)">
          {{ option }}
        </li>
      </ul>
    </div>
    <button (click)="addFilter()">Añadir filtro</button>
    <div class="applied-filters">
      <div
        style="background: #007bff; padding: 9px; border-radius: 28px; display: flex; align-items: center;"
        *ngFor="let filter of appliedFilters; let i = index"
        (mouseenter)="showTooltip(i)"
        (mouseleave)="hideTooltip(i)"
      >

      <span
      *ngIf="tooltipIndex === i"
      class="hover-label"
    >
      {{ filter.column }}
    </span>
        <span>{{ filter.value }}</span>
        <i
          (click)="removeFilter(i)"
          class="fa-solid fa-xmark"
          style="margin-left: 10px; cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>

  <div style="display: flex; flex-direction: row;">
    Contatos Seleccionados: {{ contactsSelected.length }}
    <button (click)="openModalSendMail()">Enviar Correo</button>
  </div>
</div>

  <!-- <select (change)="onSort($event.target.value)">
    <option value="">Ordenar por</option>
    <option value="nombre_de_empresa">Nombre</option>
    <option value="codigo_postal">Código Postal</option>
  </select> -->





  <div class="table-container">
    <table>
      <thead>
        <tr *ngIf="this.selectedMode == 'company'">
          <th style="min-width: 50px;">
            <input type="checkbox"
                   [checked]="contactsSelected.length === contacts.length && contacts.length > 0"
                   (change)="toggleAllContacts($event)" />
          </th>
          <th style="min-width: 50px;">Conv</th>
          <th style="min-width: 50px;">Prof</th>
          <th (click)="onSort('id')" style="min-width: 50px;">
            ID
            <i *ngIf="currentSort==='id'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('nombre_de_empresa')" style="min-width: 900px;">
            Nombre de Empresa
            <i *ngIf="currentSort==='nombre_de_empresa'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('provincia')">
            Provincia
            <i *ngIf="currentSort==='provincia'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('telefono')">
            Teléfono
            <i *ngIf="currentSort==='telefono'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('email')">
            Email
            <i *ngIf="currentSort==='email'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('sitio_web')">
            Web
            <i *ngIf="currentSort==='sitio_web'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('codigo_postal')">
            C.Post
            <i *ngIf="currentSort==='codigo_postal'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('localidad')">
            Localidad
            <i *ngIf="currentSort==='localidad'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('comunidad_autonoma')">
            Región
            <i *ngIf="currentSort==='comunidad_autonoma'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('latitud')">
            Latitud
            <i *ngIf="currentSort==='latitud'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('longitud')">
            Longitud
            <i *ngIf="currentSort==='longitud'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('forma_social')">
            Forma Social
            <i *ngIf="currentSort==='forma_social'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('tel_adicional')">
            Teléfono Adicional
            <i *ngIf="currentSort==='tel_adicional'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('actividad')">
            Actividad
            <i *ngIf="currentSort==='actividad'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('subsector')" style="min-width: 900px;">
            Subsector
            <i *ngIf="currentSort==='subsector'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('direccion')" style="min-width: 600px;">
            Dirección
            <i *ngIf="currentSort==='direccion'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('facebook')">
            Facebook
            <i *ngIf="currentSort==='facebook'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('instagram')">
            Instagram
            <i *ngIf="currentSort==='instagram'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('twitter')">
            Twitter
            <i *ngIf="currentSort==='twitter'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('youtube')">
            YouTube
            <i *ngIf="currentSort==='youtube'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
        </tr>
        <tr *ngIf="this.selectedMode == 'user'">
          <th style="min-width: 50px;">
            <input type="checkbox"
                   [checked]="contactsSelected.length === contacts.length && contacts.length > 0"
                   (change)="toggleAllContacts($event)" />
          </th>
          <th style="min-width: 50px;">Conv</th>
          <th style="min-width: 50px;">Prof</th>
          <th (click)="onSort('id')" style="min-width: 50px;">
            ID
            <i *ngIf="currentSort==='id'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('subcats_count')" style="min-width: 50px;">
            Buys
            <i *ngIf="currentSort==='subcats_count'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('origin_type')" style="min-width: 50px;">
            Estado
            <i *ngIf="currentSort==='origin_type'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('name')">
            Nombre
            <i *ngIf="currentSort==='name'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('last_name')">
            Apellido
            <i *ngIf="currentSort==='last_name'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('email')">
            Email
            <i *ngIf="currentSort==='email'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('phone')">
            Teléfono
            <i *ngIf="currentSort==='phone'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('role_name')">
            Rol
            <i *ngIf="currentSort==='role_name'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('address')">
            Dirección
            <i *ngIf="currentSort==='address'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('province')">
            Provincia
            <i *ngIf="currentSort==='province'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('country')">
            País
            <i *ngIf="currentSort==='country'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('postal')">
            Código Postal
            <i *ngIf="currentSort==='postal'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('company_id')">
            Empresa
            <i *ngIf="currentSort==='company_id'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('organization')">
            Organización
            <i *ngIf="currentSort==='organization'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('role_id')">
            Rol ID
            <i *ngIf="currentSort==='role_id'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('confirmed')">
            Estado
            <i *ngIf="currentSort==='confirmed'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('last_login')">
            Último Inicio de Sesión
            <i *ngIf="currentSort==='last_login'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('ip')">
            IP
            <i *ngIf="currentSort==='ip'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('stripe_id')">
            Stripe ID
            <i *ngIf="currentSort==='stripe_id'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('pm_type')">
            Tipo de Pago
            <i *ngIf="currentSort==='pm_type'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('pm_last_four')">
            Últimos 4 Dígitos
            <i *ngIf="currentSort==='pm_last_four'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('created_at')">
            Fecha de Creación
            <i *ngIf="currentSort==='created_at'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
          <th (click)="onSort('last_activity')">
            Última Actividad
            <i *ngIf="currentSort==='last_activity'" class="fa"
               [ngClass]="{'fa-solid fa-caret-up': currentSortOrder==='asc', 'fa-solid fa-caret-down': currentSortOrder==='desc'}"></i>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="this.selectedMode == 'company'">

        <tr *ngIf="loading" class="loader-row">
          <td colspan="20">
            <div class="loader"></div>
          </td>
        </tr>

        <tr *ngFor="let contact of contacts" [hidden]="loading" >
          <td>    <input
            type="checkbox"
            [checked]="checkChecked(contact)"
            (change)="toggleRow(contact)"
          /></td>
          <td (click)="openModalConversation(contact)"><i class="fa-solid fa-inbox"></i></td>
          <td (click)="openModalCardContact(contact)"><i class="fa-solid fa-address-card"></i></td>
          <td>{{ contact.id || '-' }}</td>
          <td>{{ contact.nombre_de_empresa || '-' }}</td>
          <td>{{ contact.provincia || '-' }}</td>
          <td>{{ contact.telefono || '-' }}</td>
          <td>
            <a *ngIf="contact.email" [href]="'mailto:' + contact.email">{{ contact.email }}</a>
            <span *ngIf="!contact.email">-</span>
          </td>
          <td>
            <a *ngIf="contact.sitio_web" [href]="contact.sitio_web" target="_blank">WEB</a>
            <span *ngIf="!contact.sitio_web">-</span>
          </td>
          <td>{{ contact.codigo_postal || '-' }}</td>
          <td>{{ contact.localidad || '-' }}</td>
          <td>{{ contact.comunidad_autonoma || '-' }}</td>
          <td>{{ contact.latitud || '-' }}</td>
          <td>{{ contact.longitud || '-' }}</td>
          <td>{{ contact.forma_social || '-' }}</td>
          <td>{{ contact.tel_adicional || '-' }}</td>
          <td>{{ contact.actividad || '-' }}</td>
          <td>{{ contact.subsector || '-' }}</td>
          <td>{{ contact.direccion || '-' }}</td>

          <td>
            <a *ngIf="contact.facebook" [href]="contact.facebook" target="_blank">Facebook</a>
            <span *ngIf="!contact.facebook">-</span>
          </td>
          <td>
            <a *ngIf="contact.instagram" [href]="contact.instagram" target="_blank">Instagram</a>
            <span *ngIf="!contact.instagram">-</span>
          </td>
          <td>
            <a *ngIf="contact.twitter" [href]="contact.twitter" target="_blank">Twitter</a>
            <span *ngIf="!contact.twitter">-</span>
          </td>
          <td>
            <a *ngIf="contact.youtube" [href]="contact.youtube" target="_blank">YouTube</a>
            <span *ngIf="!contact.youtube">-</span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="this.selectedMode == 'user'">

        <tr *ngIf="loading" class="loader-row">
          <td colspan="20">
            <div class="loader"></div>
          </td>
        </tr>

        <tr *ngFor="let contact of contacts" [hidden]="loading">
          <td>
            <input
              type="checkbox"
              [checked]="checkChecked(contact)"
              (change)="toggleRow(contact)"
            />
          </td>
          <td (click)="openModalConversation(contact)">
            <i class="fa-solid fa-inbox"></i>
          </td>
          <td (click)="openModalCardContact(contact)">
            <i class="fa-solid fa-address-card"></i>
          </td>
          <td>{{ contact.id || '-' }}</td>
          <td>{{ contact.subcats_count || 0 }}</td>
          <td> <div style="height: 10px; width: 10px; border-radius: 50%;" [ngClass]="{'user':contact.origin_type == 'user', 'prospect':contact.origin_type == 'prospect'} "></div></td>
          <td>{{ contact.name || '-' }}</td>
          <td>{{ contact.last_name || '-' }}</td>
          <td>
            <a *ngIf="contact.email" [href]="'mailto:' + contact.email">{{ contact.email }}</a>
            <span *ngIf="!contact.email">-</span>
          </td>
          <td>{{ contact.phone || '-' }}</td>
          <td>{{ contact.role_name || '-' }}</td>
          <td>{{ contact.address || '-' }}</td>
          <td>{{ contact.province || '-' }}</td>
          <td>{{ contact.country || '-' }}</td>
          <td>{{ contact.postal || '-' }}</td>
          <td>{{ contact.company_id || '-' }}</td>
          <td>{{ contact.organization || '-' }}</td>
          <td>{{ contact.role_id || '-' }}</td>
          <td>{{ contact.confirmed ? 'Sí' : 'No' }}</td>
          <td>{{ contact.last_login || '-' }}</td>
          <td>{{ contact.ip || '-' }}</td>
          <td>{{ contact.stripe_id || '-' }}</td>
          <td>{{ contact.pm_type || '-' }}</td>
          <td>{{ contact.pm_last_four || '-' }}</td>
          <td>{{ contact.created_at || '-' }}</td>
          <td>{{ contact.last_activity || '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination">
    <button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
    <button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
  </div>

</div>
