import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { now } from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { PusherService } from 'src/app/services/pusher.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css']
})
export class AssistantComponent {


  @ViewChild('chatMessagesContainer', { static: false }) chatMessagesContainer!: ElementRef;


  constructor(public userData: UserDataService,
    private pusherService: PusherService,
    private auth: AuthService,
    public admin: AdminService
  ) { }
  allChats = [];
  ngOnInit(): void {
    this.auth.workingArea = 'assistant'
    this.getChats();
  }
  newMessage: string = ''; // Variable para almacenar el mensaje ingresado

  chatSelected: any = { id: 0, messages: [] };


  sendMessage(message){

    console.log('Sending message', message);
    this.userData.sendMessage(this.chatSelected.id, message).subscribe((data: any) => {
      console.log(data);
      this.newMessage = '';
    })
  }





  chatMessages: any[] = []; // Siempre inicialízalo como un arreglo vacío
 async getConversation(chat_id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userData.getConversation(chat_id).subscribe(
        (data: any) => {
          console.log("getConversationData", data);
          if (Array.isArray(data)) {
            this.chatMessages = data; // Asignar solo si es un arreglo
          } else {
            console.error('Expected an array, but got:', data);
            this.chatMessages = []; // Asignar un arreglo vacío en caso de error
          }
          resolve(); // Resuelve la promesa cuando los datos están listos
        },
        (error) => {
          console.error('Error fetching conversation:', error);
          this.chatMessages = []; // Manejar errores de la API
          reject(error); // Rechaza la promesa en caso de error
        }
      );
    });
  }


  subscribeToAllChats() {
    const allChats = this.allChats;

    this.pusherService.subscribeToChats(allChats, (data: any) => {
      this.handleNewMessage(data);
    });
  }


  handleNewMessage(data: any) {
    console.log("dataWebSocket", data);
    console.log(this.chatSelected);

    if (data.chat_id == this.chatSelected.id ) {
      console.log("entra en el if");

      let originalDate = new Date(now());
      originalDate.setHours(originalDate.getHours() + 1);
      let updatedDate = originalDate.toISOString();
      data.created_at = updatedDate;
      data.user_name = data.name
      this.chatMessages.push(data);
      console.log("handleNewMensage",this.chatMessages);
      this.scrollToBottom();
    }
    else{
      const chatIndex = this.allChats.findIndex(chat => chat.id === data.chat_id);
      if (chatIndex !== -1) {
        this.allChats[chatIndex].hasNewMessage = true;
      }
    }

  }


  getChats() {
    this.userData.getChats().subscribe((data: any) => {
      this.allChats = data;
      this.subscribeToAllChats();
      console.log(data)

      console.log(data);
    })
  }


  changeTipoAssist(chat_id, type){
    this.userData.changeTipoAssist(chat_id, type).subscribe((data: any) => {
      console.log(data);
    })
  }



  closeTicket(chat_id){
    this.userData.closeTicket(chat_id).subscribe((data: any) => {
      console.log(data);
      this.getChats();
      this.chatSelected = { id: 0, messages: [] };
    })
  }

  expandedChat: boolean = false;


  scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatMessagesContainer) {
        this.chatMessagesContainer.nativeElement.scrollTop = this.chatMessagesContainer.nativeElement.scrollHeight;
      }
    }, 0);
  }


isMobileView: boolean = window.innerWidth < 768;
isChatOpen: boolean = false; // Para manejar el estado del chat en móviles

@HostListener('window:resize', ['$event'])
onResize(event: any) {
  this.isMobileView = window.innerWidth < 768;
  if (this.isMobileView) {
    this.isChatOpen = false; // Asegura que inicie mostrando contactos
  }
}

async selectChat(chat: any): Promise<void> {
  this.chatSelected = chat;
  this.chatMessages = [];

  await this.getConversation(chat.id);
  this.scrollToBottom();

  const chatIndex = this.allChats.findIndex(cha => cha.id == chat.id);
  if (chatIndex !== -1) {
    this.allChats[chatIndex].hasNewMessage = false;
  }
  this.scrollToBottom();

  // En móviles, cambia el estado a mostrar el chat
  if (this.isMobileView) {
    this.isChatOpen = true;
  }
}

goBackToContacts() {
  this.isChatOpen = false;
}



}
